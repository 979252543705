import { CookiesProvider } from 'react-cookie';
import { AssessmentProvider } from './assessments';
import { AuthProvider } from './auth';
import { BeneficiaryProvider } from './beneficiary';
import { BeneficiariesExcludeProvider } from './beneficiariesExclude';
import { CidProvider } from './cids';
import { CompanyProvider } from './company';
import { ContractsProvider } from './contracts';
import { DocumentsProvider } from './documents';
import { GlobalProvider } from './global';
import { HealthDeclarationProvider } from './healthDeclaration';
import { IndicatorsProvider } from './indicators';
import { InvoiceProvider } from './invoice';
import { MovementHistoryProvider } from './movementHistory';
import { NotificationsProvider } from './notifications';
import { NpsProvider } from './nps';
import { ReasonProvider } from './reason';
import { RecipientProvider } from './recipients';
import { SguProvider } from './sgu';
import { ToastProvider } from './toast';
import { UserProvider } from './user';
import { PortabilityProvider } from './portability';
import { GraceInfoProvider } from './grace_info';
import { SelectCompaniesProvider } from './selectCompany';

const AppProvider: React.FC = ({ children }) => {
  return (
    <CookiesProvider>
      <GlobalProvider>
        <ToastProvider>
          <AuthProvider>
            <InvoiceProvider>
              <HealthDeclarationProvider>
                <UserProvider>
                  <NotificationsProvider>
                    <NpsProvider>
                      <ContractsProvider>
                        <IndicatorsProvider>
                          <CompanyProvider>
                            <MovementHistoryProvider>
                              <AssessmentProvider>
                                <BeneficiariesExcludeProvider>
                                  <SguProvider>
                                    <RecipientProvider>
                                      <DocumentsProvider>
                                        <CidProvider>
                                          <ReasonProvider>
                                            <BeneficiaryProvider>
                                              <PortabilityProvider>
                                                <SelectCompaniesProvider>
                                                  <GraceInfoProvider>{children}</GraceInfoProvider>
                                                </SelectCompaniesProvider>
                                              </PortabilityProvider>
                                            </BeneficiaryProvider>
                                          </ReasonProvider>
                                        </CidProvider>
                                      </DocumentsProvider>
                                    </RecipientProvider>
                                  </SguProvider>
                                </BeneficiariesExcludeProvider>
                              </AssessmentProvider>
                            </MovementHistoryProvider>
                          </CompanyProvider>
                        </IndicatorsProvider>
                      </ContractsProvider>
                    </NpsProvider>
                  </NotificationsProvider>
                </UserProvider>
              </HealthDeclarationProvider>
            </InvoiceProvider>
          </AuthProvider>
        </ToastProvider>
      </GlobalProvider>
    </CookiesProvider>
  );
};

export default AppProvider;
