export const uniflex_grace_period = [
  {
    code: '56',
    description: 'Urgência e Emergência',
    days: undefined,
    codes: [4095],
    maxDays: 1,
  },
  {
    code: '6',
    description: 'Consultas',
    days: undefined,
    codes: [6],
    maxDays: 30,
  },
  {
    code: '62',
    description: 'Exames / Raio X / Lab',
    days: undefined,
    codes: [4044, 4046, 4048, 4049, 4050, 4051, 4069],
    maxDays: 180,
  },
  {
    code: '24',
    description: 'Fisioterapia',
    days: undefined,
    codes: [4052],
    maxDays: 90,
  },
  {
    code: '51',
    description: 'Internações',
    days: undefined,
    codes: [4084, 4085, 4086, 4087, 4088, 4089, 4090, 4092, 4093, 4094, 4096],
    maxDays: 180,
  },
  {
    code: '57',
    description: 'Parto',
    days: undefined,
    codes: [4097],
    maxDays: 300,
  },
  {
    code: '8',
    description: 'Procedimentos Especificados',
    days: undefined,
    codes: [8],
    maxDays: 30,
  },
  {
    code: '61',
    description: 'Demais procedimentos',
    days: undefined,
    codes: [
      4047,
      4053,
      4054,
      4055,
      4056,
      4057,
      4058,
      4059,
      4060,
      4061,
      4062,
      4063,
      4064,
      4065,
      4066,
      4067,
      4068,
      4069,
      4070,
      4071,
      4072,
      4073,
      4074,
      4075,
      4076,
      4077,
      4078,
      4079,
      4080,
      4081,
      4082,
      4083,
      4084,
      4085,
      4086,
      4087,
      4088,
      4089,
      4090,
      4091,
      4092,
      4093,
      4094,
      4096,
    ],
    maxDays: 180,
  },
];

export const uniplan_grace_period = [
  {
    code: '60',
    description: 'Quimio/Radio/Acupuntura',
    days: undefined,
    codes: [2076, 2082, 2084],
    maxDays: 180,
  },
  {
    code: '59',
    description: 'Exames Esp.',
    days: undefined,
    codes: [2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096],
    maxDays: 120,
  },
  {
    code: '58',
    description: 'Terapia / Fisio / Ex Comp / Lab',
    days: undefined,
    codes: [
      2052,
      2053,
      2054,
      2055,
      2057,
      2058,
      2059,
      2060,
      2061,
      2062,
      2063,
      2064,
      2065,
      2066,
      2067,
      2068,
      2069,
      2070,
      2071,
      2072,
      2073,
      2074,
      2100,
    ],
    maxDays: 30,
  },
  {
    code: '57',
    description: 'Parto',
    days: undefined,
    codes: [2039, 2075],
    maxDays: 300,
  },
  {
    code: '56',
    description: 'Urgência e Emergência',
    days: undefined,
    codes: [2050, 2051],
    maxDays: 1,
  },
  {
    code: '55',
    description: 'CIR Card/Hemod e Proc Alta Comp',
    days: undefined,
    codes: [2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2097, 2098],
    maxDays: 300,
  },
  {
    code: '51',
    description: 'Internações',
    days: undefined,
    codes: [2001, 2002, 2003, 2004, 2005, 2038, 2077, 2078, 2079, 2080, 2081, 2083, 2099],
    maxDays: 180,
  },
  {
    code: '50',
    description: 'Consultas',
    days: undefined,
    codes: [2000, 2056],
    maxDays: 30,
  },
  {
    code: '8',
    description: 'Procedimentos Especificados',
    days: undefined,
    codes: [8],
    maxDays: 30,
  },
];
