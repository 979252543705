import { useCallback, createContext, useState, useContext, useEffect } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';
import { IOrderAndPagination } from 'hooks/user';
import useDebounce from 'utils/debounce';
import { isEmpty } from 'lodash';
import { ICID } from 'services/cids';

export interface IFilter {
  search?: string;
}

interface IContext {
  cids: ICID[];
  loading: boolean;
  filters: IFilter;
  get(filter?: IFilter, orderPagination?: IOrderAndPagination): Promise<void>;
  applyFilter(filter?: IFilter): void;
  clearFilter(): void;
}

const CidContext = createContext<IContext>({} as IContext);

const CidProvider: React.FC = ({ children }) => {
  const [cids, setCids] = useState<ICID[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilter] = useState<IFilter>({} as IFilter);

  const filtersDebounced = useDebounce(filters, 200);

  const { addToast } = useToast();
  const get = useCallback(
    async (filter?: IFilter) => {
      setLoading(true);
      try {
        const response = await api.cid().get(filter);

        setCids(response.data.content);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os CIDs' });
      }
      setLoading(false);
    },
    [addToast, setLoading],
  );

  const applyFilter = useCallback(
    (applyingFilters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...applyingFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  useEffect(() => {
    if (isEmpty(filtersDebounced) === false) {
      get(filtersDebounced);
    }
  }, [filtersDebounced, get]);

  return (
    <CidContext.Provider
      value={{
        cids,
        loading,
        filters,
        get,
        applyFilter,
        clearFilter,
      }}
    >
      {children}
    </CidContext.Provider>
  );
};

function useCids(): IContext {
  const context = useContext(CidContext);
  if (!context) {
    throw new Error('useCids must be used within an CidsProvider');
  }
  return context;
}

export { CidProvider, useCids };
