import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from 'styles/colors';
import { Content as MPFContent, Header as MPFHeader } from '../../atoms/ModalPendingFields/styles';
import { ProofOfEmploymentContainer } from '../HealthPlanBeneficiaryNew/styles';

export const ProofOfNameChange = styled(ProofOfEmploymentContainer)`
  margin: 0px;
  margin-bottom: 32px;
`;

export const Header = styled(MPFHeader)`
  h2 {
    font-weight: 600;
  }
`;

export const Scrollable = styled(MPFContent)`
  border: none;
  overflow: auto;
  overflow-x: hidden;
  max-height: 533px;
  margin-left: -32px;
  padding-left: 32px;
`;

export const Content = styled(MPFContent)`
  border: none;
  overflow: initial;
  margin-top: 0px;

  [class*='MuiTabs-indicator-'] {
    height: 1px;
  }

  [class*='MuiTabs-root'] {
    padding: 0px 26px;
    border-bottom: 1px solid #c9d3dd;
  }

  [class*='Mui-selected'] {
    color: ${colors.unimedGreenStrong} !important;
  }

  [class*='MuiTabs-indicator'] {
    background-color: ${colors.unimedGreenStrong} !important;
  }

  .content_inside {
    padding: 32px 0 0 32px;

    [class*='MuiFormControl-root'] {
      margin: 8px 0px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 9px 32px 0px;
  border-top: 1px solid rgba(143, 146, 161, 0.1);
`;

export const GridContainer = styled.div`
  .one_third {
    display: flex;

    .splits {
      flex: 1;
    }

    .splits + .splits {
      flex: 2;
    }
  }

  .half {
    display: flex;

    .splits {
      flex: 1;
    }
  }

  hr {
    margin: 32px -32px;
    border: 1px solid rgba(143, 146, 161, 0.1);
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 26px;
  background-color: ${transparentize(0.9, colors.info)};
  color: ${colors.info};
  font-size: 12px;
  line-height: 16px;

  div {
    flex: 1;
  }

  svg {
    width: 19px;
    margin-right: 26px;
  }
`;
