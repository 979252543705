import { forwardRef, RefObject, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Popper, Grow, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import Backdrop from 'components/templates/Backdrop';
import { IFilter, useMovementHistory } from 'hooks/movementHistory';
import { FilterContainer } from './styles';
import { ButtonsFooterFilter } from '../../BeneficiariesList/FilterDate/styles';

interface Props {
  onClose?: () => void;
}

export interface FilterActionHandles {
  openFilterType: () => void;
  handleClearFilter: () => void;
  anchorFilterTypeRef: RefObject<HTMLButtonElement>;
  filterTypeIsOpen: boolean;
}

export const actions = {
  I: 'Inclusão',
  E: 'Exclusão',
  T: 'Transferencia',
};

const FilterAction: React.ForwardRefRenderFunction<FilterActionHandles, Props> = ({ onClose }, ref) => {
  const anchorFilterTypeRef = useRef<HTMLButtonElement>(null);

  const { applyFilter, changePage } = useMovementHistory();

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState<IFilter>({ action: '' });

  const handleClickItemFilter = useCallback((item: 'I' | 'E' | 'T') => {
    setFilter(old => ({ ...old, action: item }));
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilter(old => ({ ...old, action: '' }));
  }, []);

  const handleCloseFilter = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (anchorFilterTypeRef.current && anchorFilterTypeRef.current.contains(event.target as HTMLElement)) return;
      if (onClose) onClose();
      setOpenFilter(false);
    },
    [onClose],
  );

  const handleClickFilter = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      applyFilter(filter);
      changePage(1);
      handleCloseFilter(event);
    },
    [applyFilter, changePage, filter, handleCloseFilter],
  );

  const openFilterType = useCallback(() => {
    setOpenFilter(true);
  }, []);

  const filterTypeIsOpen = useMemo(() => {
    return openFilter;
  }, [openFilter]);

  useImperativeHandle(ref, () => {
    return { openFilterType, anchorFilterTypeRef, filterTypeIsOpen, handleClearFilter };
  });

  return (
    <>
      {openFilter && <Backdrop />}
      <Popper
        open={openFilter}
        anchorEl={anchorFilterTypeRef.current}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 20 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClickFilter}>
              <FilterContainer className={`filter__container`}>
                <MenuList autoFocusItem={openFilter}>
                  <MenuItem className="filter__item">
                    <span>Inclusão</span>
                    <button
                      type="button"
                      className={`${filter.action === 'I' ? 'check__filter checked' : 'check__filter'}`}
                      onClick={() => handleClickItemFilter('I')}
                    >
                      {filter.action === 'I' && <FaCheck size={12} color="#fff" />}
                    </button>
                  </MenuItem>
                  <MenuItem className="filter__item">
                    <span>Exclusão</span>
                    <button
                      type="button"
                      className={`${filter.action === 'E' ? 'check__filter checked' : 'check__filter'}`}
                      onClick={() => handleClickItemFilter('E')}
                    >
                      {filter.action === 'E' && <FaCheck size={12} color="#fff" />}
                    </button>
                  </MenuItem>
                  <MenuItem className="filter__item">
                    <span>Transferencia</span>
                    <button
                      type="button"
                      className={`${filter.action === 'T' ? 'check__filter checked' : 'check__filter'}`}
                      onClick={() => handleClickItemFilter('T')}
                    >
                      {filter.action === 'T' && <FaCheck size={12} color="#fff" />}
                    </button>
                  </MenuItem>
                </MenuList>

                <hr />

                <ButtonsFooterFilter>
                  <button type="button" className="clear" onClick={handleClearFilter}>
                    Limpar
                  </button>
                  <button type="button" className="filter" onClick={handleClickFilter}>
                    Aplicar
                  </button>
                </ButtonsFooterFilter>
              </FilterContainer>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default forwardRef(FilterAction);
