import { AxiosInstance, AxiosResponse } from 'axios';

export class Portability {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async reviewPortability(data: any): Promise<AxiosResponse> {
    return this.instance.post('/api/admin/portability/review', data);
  }
}

const portability = (instance: AxiosInstance): Portability => new Portability(instance);

export default portability;
