/* eslint-disable no-shadow */
enum RoutesEnum {
  // APP
  INDEX_ROUTE = '/',

  // User Authententication
  LOGIN_ROUTE = '/login',
  FINANCE_ROUTE = '/',
  FIRST_ACCESS_ROUTE = '/primeiro-acesso',
  RECOVER_PASSWORD_ROUTE = '/recuperar-senha',
  NEW_PASSWORD_ROUTE = '/recuperar-senha/:token',
  HEALTH_PLAN_BENEFICIARY_FORM = '/novo-beneficiario/:token',
  HEALTH_PLAN_BENEFICIARY_ACTIVES = '/beneficiario-ativos',
  HEALTH_PLAN_BENEFICIARY_EDIT = '/beneficiario/:id',
  HEALTH_PLAN_BENEFICIARY_TRANSFER = '/beneficiario/:id/transferencia',
  HEALTH_PLAN_BENEFICIARY_EXCLUDE = '/beneficiario/:codigoBeneficiario/exclusao',

  // Routes authentication
  HEALTH_PLAN_BENEFICIARY_NEW = '/beneficiario/novo',
  FINANCIAL_BEHAVIOUR = '/financeiro',
  HEALTH_DECLARATION_START = '/declaracao-saude/:token',
  HEALTH_PLAN_BENEFICIARY_FORM_DEPENDENT = '/adicionar-dependente/:token',
  HEALTH_DECLARATION_LETTER_OF_ORIENTATION = '/declaracao-saude/:token/orientacao',
  HEALTH_DECLARATION_INSTRUCTIONS = '/declaracao-saude/:token/instrucoes',
  HEALTH_DECLARATION_STEP_1 = '/declaracao-saude/:token/steps/1',
  HEALTH_DECLARATION_STEP_2 = '/declaracao-saude/:token/steps/2',
  HEALTH_DECLARATION_FINISH = '/declaracao-saude/:token/finish',
  HEALTH_DECLARATION_CORRECTION = '/declaracao-saude-correcao/:type/:token',
  TRACK_MOVEMENTS = '/movimentacoes',
  LIST_MOVEMENTS_CORRECTIONS = '/movimentos/correcoes',
  MOVEMENT_CORRECTION = '/movimentos/exclusao/:exclusion_id/corrigir',
  MOVEMENT_TRANSFER = '/movimentos/transferencia/:transfer_id/corrigir',
  READJUSTMENTS = '/reajustes',
  INCOME_TAX_STATEMENT = '/relatorio-valores-pagos',

  // ADMIN
  ADMIN = '/admin',
  ADMIN_TRACK_MOVEMENTS = '/admin/gestao-movimentacoes',
  ADMIN_DETAILS_MOVEMENTS = '/admin/gestao-movimentacoes/inclusao/:beneficiary_type/:beneficiary_id',
  ADMIN_DETAILS_EXCLUDE_MOVEMENTS = '/admin/gestao-movimentacoes/exclusao/:movement_id',
  ADMIN_DETAILS_TRANSFER_MOVEMENTS = '/admin/gestao-movimentacoes/transferencia/:movement_id',
  ADMIN_DETAILS_SECOND_COPY_MOVEMENTS = '/admin/gestao-movimentacoes/segunda-via/:movement_id',
  ADMIN_DETAILS_UPDATE_BENEFICIARY_MOVEMENTS = '/admin/gestao-movimentacoes/alterar-beneficiario/:movement_id',
  ADMIN_CONTRACTS_PME = '/admin/contratos/pme',
  ADMIN_CONTRACTS_PME_READJUSTMENT = '/admin/contratos/pme/reajuste/:month/:year',
  ADMIN_COMPANY_SETTINGS = '/admin/empresas/:sguCode',
  ADMIN_INTEGER_CONTRACTS = '/admin/empresas/:sguCode/contratos/:contract',
  ADMIN_NPS = '/admin/nps',
  ADMIN_BENEFICIARIES_ASSESSMENT = '/admin/avaliacao-inclusao',
  ADMIN_BENEFICIARIES_ASSESSMENT_VIEW = '/admin/avaliacao-inclusao/:beneficiary_type/:beneficiary_id',
  ADMIN_BENEFICIARIES_EXCLUDE = '/admin/exclusao/:exclusion_id/avaliar',
  ADMIN_BENEFICIARIES_EXCLUDE_VIEW = '/admin/avaliacao-de-exclusao',
  ADMIN_NPS_RATINGS = '/admin/nps/avaliacoes',
  ADMIN_HEALTH_DECLARATION_ASSESSMENT_VIEW = '/admin/avaliacao-declaracao-de-saude',
  ADMIN_HEALTH_DECLARATION_ASSESSMENT = '/admin/avaliacao-declaracao-de-saude/:type/:id',
  ADMIN_ANALYSIS = '/admin/pericia',
  ADMIN_ANALYSIS_VIEWER = '/admin/pericia/:beneficiary_type/:beneficiaryId',
  ADMIN_MEDICAL_EXPERTISE = '/admin/pericia-medica',
  ADMIN_NO_MEDICAL_EXPERTISE = '/admin/medi/sem-pericia-medica',
  ADMIN_HEALTH_DECLARATION_VIEWER = '/admin/declaracao-saude/:beneficiary_type/:beneficiary_id',
  ADMIN_CIDS = '/admin/cids',
  ADMIN_CIDS_ADD = '/admin/cids/:beneficiary_type/:beneficiary_id',
  ADMIN_MEDICAL_EXPERTISE_ANALYSIS = '/admin/analise-pericia/:beneficiary_type/:beneficiary_id',
  ADMIN_ERROR_IMPORT = '/admin/erros-exportacao',
  ADMIN_MEDICAL_OPINION = '/admin/parecer-medico/:beneficiary_type/:beneficiary_id',
  ADMIN_HEALTH_IMPORT = '/admin/vidas/importar',
  ADMIN_TRANSFER_EVALUATION = '/admin/avaliacao/transferencia',
  ADMIN_QUALIFIED_INTERVIEW_LIST = '/admin/entrevista-qualificada',
  ADMIN_QUALIFIED_INTERVIEW_FILLING = '/admin/entrevista-qualificada/:beneficiary_type/:beneficiary_id',
  ADMIN_SALES = '/admin/vendas',
  ADMIN_ASSESSMENT_SECOND_COPY = '/admin/second-copy/assessment',
  ADMIN_ASSESSMENT_UPDATE_BENEFICIARY = '/admin/alterar-beneficiario/avaliacao',
  ADMIN_EXTRACT_IMPOSTO_RENDAS = '/admin/imposto-de-renda',

  // EXTERNAL
  EXTERNAL_SGU_WEB = 'https://sguweb.unimedflorianopolis.com.br',
  EXTERNAL_TREINAMENTO_SGU_WEB = 'https://www.unimed.coop.br/site/web/florianopolis/suporte-unimed-empresas',
  EXTERNAL_DOCUMENTOS_SGU_WEB = 'https://www.unimed.coop.br/site/web/florianopolis/suporte-unimed-empresas',
}

export default RoutesEnum;
