/* eslint-disable no-shadow */
export enum TransferStatusEnum {
  COM_ERRO = 'Erro na exportação, faça contato com a Unimed',
  CORRECAO = 'Correção da solicitação de transferência',
  VALIDACAO_OCR = 'Análise Unimed - Cadastro',
  AGUARDANDO_CONFIRMACAO = 'Aguardando confirmação do beneficiário',
  INATIVO = 'Beneficiário(a) excluído(a)',
  ENVIAR_OCR = 'Análise Unimed - Cadastro',
  EXPORTAR_SGU = 'Exportação para o SGU',
  CANCELADO = 'Cancelado',
  FINALIZADO = 'Processo concluído',
  AVALIACAO = 'Análise Unimed - Cadastro',
  GENERIC = 'Status desconhecido',
}

export enum TransferStatusForAdminEnum {
  COM_ERRO = 'Erro na exportação',
  CORRECAO = 'Correção pela empresa',
  VALIDACAO_OCR = 'Validação OCR',
  AGUARDANDO_CONFIRMACAO = 'Aguardando confirmação do beneficiário',
  ENVIAR_OCR = 'Enviar OCR',
  EXPORTAR_SGU = 'Exportação para o SGU',
  CANCELADO = 'Cancelado',
  FINALIZADO = 'Processo concluído',
  AVALIACAO = 'Avaliação do Cadastro',
  GENERIC = 'Status desconhecido',
}
