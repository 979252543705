import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: flex-end;
  width: 100%;
  top: 0px;
  overflow: hidden;
  font-weight: 500;
  z-index: 10000000;
  & > div:not(:empty) {
    padding: 30px;
  }
`;
