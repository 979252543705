import { AxiosInstance } from 'axios';
import { IResponseBeneficiary, IUseDetailsMovement } from 'hooks/useDetailsMovement/interfaces';

export class DetailsBeneficiary {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  putEditBeneficiary(params: IUseDetailsMovement, data: any): Promise<any> {
    return this.instance.put(
      `/api/admin/movements/inclusion/${params.beneficiary_type}/${params.beneficiary_id}`,
      data,
    );
  }

  getCriticismBeneficiary(params: IUseDetailsMovement): Promise<any> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${params.beneficiary_type}/${params.beneficiary_id}/critics`,
    );
  }

  getDependentsBeneficiary(params: string): Promise<any> {
    return this.instance.get(`/api/admin/movements/inclusion/recipient/${params}/dependents`);
  }

  getAttachmentsBeneficiaryDocuments(params: any): Promise<any> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${params.beneficiary_type}/${params.beneficiary_id}/documents`,
    );
  }

  getBeneficiary(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}`);
  }

  getBeneficiaryExclude(data: string): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/exclusion/${data}`);
  }

  updateExcludeMovement(data: string, params: any): Promise<IResponseBeneficiary> {
    return this.instance.put(`/api/admin/movements/exclusion/${data}`, params);
  }

  beneficiaryExcludeAba(data: string): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/exclusion/${data}/documents`);
  }

  transferDocumentsAdmin(data: string): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/transfer/${data}/documents`);
  }

  getHealthDeclaration(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/health_statement`,
    );
  }

  getHealthDeclarationCritics(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/health_statement/disapprovals`,
    );
  }

  getAuditorium(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/audit`);
  }

  getAuditoriumCPT(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/audit/export/cpt`,
    );
  }

  getAuditoriumLack(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/audit/export/grace_info`,
    );
  }

  exportSGU(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/audit/export/grace_info`,
    );
  }

  getExportSGUReviews(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.get(
      `/api/admin/movements/inclusion/${data.beneficiary_type}/${data.beneficiary_id}/export/disapprovals`,
    );
  }

  exportSGUSendPendents(data: IUseDetailsMovement): Promise<IResponseBeneficiary> {
    return this.instance.post(`/api/admin/movements/${data.beneficiary_type}/${data.beneficiary_id}/export/resolve`);
  }

  getHistory(beneficiary_id: string, beneficiary_type: string): Promise<any> {
    return this.instance.get(`/api/admin/movements/${beneficiary_type}/${beneficiary_id}/history`);
  }

  getTransferMovementAdmin(movement_id: string): Promise<IResponseBeneficiary> {
    return this.instance.get(`/api/admin/movements/transfer/${movement_id}`);
  }
}

const detailsBeneficiary = (instance: AxiosInstance): DetailsBeneficiary => new DetailsBeneficiary(instance);

export default detailsBeneficiary;
