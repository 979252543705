import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('G-1DZ9M6DZ91');

export const GoogleAnalytics = ({ children }: { children: React.ReactChild }) => {
  const location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);
  return <>{children}</>;
};
