/* eslint-disable no-shadow */
export enum UpdateBeneficiaryStatusEnum {
  CORRECAO = 'Correção da solicitação pelo beneficiário',
  EXPORTAR_SGU = 'Exportação para o SGU',
  FINALIZADO = 'Processo concluído',
  AVALIACAO = 'Análise Unimed - Cadastro',
  GENERIC = 'Status desconhecido',
  CANCELADO = 'Cancelado',
  REPROVADO = 'Reprovado',
  AGUARDANDO_BENEFICIARIO = 'Aguardando Beneficiário',
}

export enum UpdateBeneficiaryStatusForAdminEnum {
  CORRECAO = 'Correção pela empresa',
  EXPORTAR_SGU = 'Exportação para o SGU',
  FINALIZADO = 'Processo concluído',
  AVALIACAO = 'Avaliação do Cadastro',
  REPROVADO = 'Reprovado',
  AGUARDANDO_BENEFICIARIO = 'Aguardando Beneficiário',
  CANCELADO = 'Cancelado',
  GENERIC = 'Status desconhecido',
}
