import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import colors from 'styles/colors';

type ButtonProps = {
  buttonText: string | ReactElement;
  tooltipTitle?: string;
  validate?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'button';
  to?: string;
  href?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'link'
    | 'danger'
    | 'link-secondary'
    | 'link-danger'
    | 'link-dark'
    | 'icon'
    | 'icon-danger'
    | 'icon-secondary'
    | 'outline';
  size?: 'sm' | 'md' | 'lg';
  resizable?: boolean;
  loading?: boolean;
  disabled?: boolean;
  target?: '_blank';
  style?: any;
};

type ButtonStyle = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'link'
    | 'danger'
    | 'link-secondary'
    | 'link-danger'
    | 'link-dark'
    | 'icon-secondary'
    | 'icon'
    | 'icon-danger'
    | 'outline';
  size?: 'sm' | 'md' | 'lg';
  resizable?: boolean;
};

const ButtonDefault = withStyles({
  root: (props: ButtonStyle) => ({
    borderRadius: 6,
    overflow: 'hidden',
    background: colors.unimedGreenStrong,
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 600,
    ...(props.size === 'lg' && {
      padding: '18px 20px',
      width: '100%',
      margin: '24px 10px',
    }),
    ...(props.size === 'md' && {
      width: 198,
      height: 40,
      lineHeight: '16px',
      fontSize: 14,
    }),
    ...(props.size === 'sm' && {
      padding: '0px 8px',
      height: 28,
      lineHeight: '12px',
      fontSize: 12,
      width: 'auto',
    }),
    ...(props.resizable && {
      padding: '0px 21px',
      width: 'auto',
    }),
    ...(props.variant === 'secondary' && {
      background: colors.unimedOrange,
    }),
    ...(props.variant === 'muted' && {
      background: colors.placeholder,
    }),
    ...(props.variant === 'danger' && {
      background: colors.error,
    }),
    ...(props.variant === 'link' && {
      background: 'transparent',
      color: colors.unimedGreenStrong,
    }),
    ...(props.variant === 'link-dark' && {
      background: 'transparent',
      color: colors.black,
    }),
    ...(props.variant === 'link-secondary' && {
      background: 'transparent',
      color: colors.unimedOrange,
    }),
    ...(props.variant === 'link-danger' && {
      background: 'transparent',
      color: colors.error,
    }),
    ...(props.variant === 'outline' && {
      background: 'transparent',
      color: colors.unimedGreenStrong,
      border: `1px solid ${colors.unimedGreenStrong}`,
    }),
    ...(props.variant === 'icon' && {
      background: colors.unimedGreenStrong,
      padding: 7,
      height: 32,
      width: 32,
      boxShadow: '0px 5px 15px rgba(112, 144, 176, 0.25)',
    }),
    ...(props.variant === 'icon-danger' && {
      background: colors.error,
      padding: 7,
      height: 32,
      width: 32,
      boxShadow: '0px 5px 15px rgba(112, 144, 176, 0.25)',
    }),
    ...(props.variant === 'icon-secondary' && {
      background: colors.unimedOrange,
      padding: 7,
      height: 32,
      width: 32,
      boxShadow: '0px 5px 15px rgba(112, 144, 176, 0.25)',
    }),
  }),
  disabled: {
    opacity: 0.3,
  },
})(ButtonBase);

const Button: React.FC<ButtonProps> = ({
  target,
  buttonText,
  validate = true,
  variant,
  loading = false,
  href,
  to,
  style,
  tooltipTitle = '',
  ...props
}) => {
  const linkSpread = to ? { component: Link, to, target } : {};
  const hrefSpread = href ? { component: 'a', href, target } : {};

  return (
    <div className="button_container">
      {tooltipTitle ? (
        <Tooltip title={tooltipTitle}>
          <ButtonDefault
            {...linkSpread}
            {...hrefSpread}
            disabled={!!(loading || !validate)}
            variant={variant}
            {...props}
            style={style}
          >
            {loading && (
              <CircularProgress
                style={{
                  position: 'relative',
                  left: -10,
                  fontSize: 10,
                  color: String(variant).includes('link') ? colors.unimedOrange : '#fff',
                }}
                size={15}
              />
            )}
            {buttonText}
          </ButtonDefault>
        </Tooltip>
      ) : (
        <ButtonDefault
          {...linkSpread}
          {...hrefSpread}
          disabled={!!(loading || !validate)}
          variant={variant}
          {...props}
          style={style}
        >
          {loading && (
            <CircularProgress
              style={{
                position: 'relative',
                left: -10,
                fontSize: 10,
                color: String(variant).includes('link') ? colors.unimedOrange : '#fff',
              }}
              size={15}
            />
          )}
          {buttonText}
        </ButtonDefault>
      )}
    </div>
  );
};

Button.defaultProps = {
  size: 'lg',
  disabled: false,
};

export default Button;
