/* eslint-disable no-shadow */
export enum StatusEnum {
  INATIVO = 'Beneficiário(a) excluído(a)',
  AGUARDANDO_DOCUMENTACAO = 'Aguardando documentação do(a) beneficiário(a)',
  AVALIACAO_DS = 'Análise Unimed - Auditoria médica',
  DS_REPROVADA = 'Correções solicitadas ao beneficiário',
  ANALISE_PERICIA = 'Análise Unimed - Auditoria médica',
  SEM_PERICIA = 'Análise Unimed - Auditoria médica',
  CONVOCACAO_PERICIA = 'Convocado para a pericia',
  AGUARDANDO_ASSINATURA = 'Aguardando assinatura das partes',
  VALIDACAO_CADASTRO = 'Análise Unimed - Cadastro',
  VALIDACAO_CADASTRO_OCR = 'Análise Unimed - Cadastro',
  ENVIAR_OCR = 'Análise Unimed - Cadastro',
  AGUARDANDO_DS = 'Aguardando preenchimento da declaração de saúde',
  EXPORTAR_SGU = 'Exportação para o SGU',
  ATIVO = 'Ativo',
  EM_EXCLUSAO = 'Em Exclusão',
  AVALIACAO_DS_CID = 'Análise Unimed - Auditoria médica',
  EXPIRADO = 'Processo expirado',
  ELIMINADO = 'Processo eliminado',
  AVALIACAO_EXCLUSAO = 'Avaliação da solicitação de exclusão',
  CORRECAO_EXCLUSAO = 'Correção da solicitação de exclusão',
  ERRO_EXCLUSAO = 'Erro na exportação, faça contato com a Unimed',
  CANCELADO = 'Cancelado',
  GENERIC = 'Status desconhecido',

  // Exclusão
  COM_ERRO = 'Erro na exportação, faça contato com a Unimed',
  FINALIZADO = 'Processo concluído',
  AVALIACAO = 'Análise Unimed - Cadastro',
}

export enum StatusColorsMap {
  INATIVO = '#F64E60',
  AGUARDANDO_DOCUMENTACAO = '#158DF7',
  AVALIACAO_DS = '#158DF7',
  AGUARDANDO_DS = '#158DF7',
  VALIDACAO_CADASTRO = '#158DF7',
  DS_REPROVADA = '#158DF7',
  ANALISE_PERICIA = '#EFBC14',
  CONVOCACAO_PERICIA = '#EFBC14',
  AGUARDANDO_ASSINATURA = '#EFBC14',
  SEM_PERICIA = '#EFBC14',
  EXPORTAR_SGU = '#876CFE',
  ATIVO = '#00BA88',
  EM_EXCLUSAO = '#F64E60',
  GENERIC = '#4E4B66',
}

export enum InclusionStatusForAdmin {
  EXPORTAR_SGU = 'Exportar SGU',
  AVALIACAO_DS = 'Avaliação DS',
  EXPIRADO = 'Expirado',
  VALIDACAO_CADASTRO = 'Validação Cadastro',
  INATIVO = 'Inativo',
  ATIVO = 'Ativo',
  AGUARDANDO_DOCUMENTACAO = 'Aguardando documentação',
  DS_REPROVADA = 'DS Reprovada',
  ANALISE_PERICIA = 'Análise de Perícia',
  SEM_PERICIA = 'Sem Perícia',
  CONVOCACAO_PERICIA = 'Convocado para pericia',
  AGUARDANDO_ASSINATURA = 'Aguardando assinatura',
  VALIDACAO_CADASTRO_OCR = 'Validação OCR',
  ENVIAR_OCR = 'Envio para OCR',
  AGUARDANDO_DS = 'Aguardando DS',
  EM_EXCLUSAO = 'Em Exclusão',
  AVALIACAO_DS_CID = 'Lançamento de CID',
  AVALIACAO_EXCLUSAO = 'Avaliação da exclusão',
  CORRECAO_EXCLUSAO = 'Correção da exclusão',
  ERRO_EXCLUSAO = 'Erro na exportação',
  CANCELADO = 'Cancelado',
  GENERIC = 'Status desconhecido',

  // Exclusão
  COM_ERRO = 'Com Erro',
  FINALIZADO = 'Finalizado',
  AVALIACAO = 'Avaliação de Exclusão',
}
