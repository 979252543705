import { Suspense, useEffect } from 'react';

import SplashScreen from 'components/layout/SplashScreen';
import { createBrowserHistory } from 'history';
import AppProvider from 'hooks';
import Routes from 'routes/index';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import './config/stylesGlobal.css';

import colors from 'styles/colors';

import moment from 'moment';
import 'moment/locale/pt-br';
import ModalCompanyProfile from 'components/pages/ModalCompanyProfile';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { styledTheme } from 'lib/styled-theme';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK || 'sdk-KqbhBxlx50ZKa1VB',
  enableDevMode: true,
});

moment.locale('pt-br');

export const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(0,153,92, 0.1)',
    },
    secondary: {
      main: colors.unimedOrange,
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({
      skipCache: true,
    });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AppProvider>
        <Suspense fallback={<SplashScreen />}>
          <ThemeProvider theme={theme}>
            <StyledThemeProvider theme={styledTheme}>
              <Routes />
              <ModalCompanyProfile />
            </StyledThemeProvider>
          </ThemeProvider>
        </Suspense>
      </AppProvider>
    </GrowthBookProvider>
  );
};

export default App;
