import { rgba, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';

import CalendarRC, { CalendarProps } from 'react-calendar';

export const FilterContainer = styled.div`
  margin-top: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 16px 40px ${rgba('#7090b0', 0.2)};
  padding: 26px 24px 18px;
  width: calc(360px - 48px);

  ul {
    padding: 0;
  }

  .filter__item {
    display: flex;
    align-items: center;
    padding: 0;

    & + .filter__item {
      margin-top: 14px;
    }

    &:hover {
      background-color: transparent;
    }

    > span {
      flex: 1;
    }
    > button {
      background-color: #ebeef6;
      border: none;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s ease;
      width: 24px;
      height: 24px;

      &.checked {
        background-color: ${colors.unimedGreenStrong};

        &:hover {
          background-color: #047e4e;
        }
      }
    }
  }

  hr {
    background-color: #f7f9fe;
    border: none;
    margin: 24px 0 18px;
    width: 100%;
    height: 1px;
  }
`;

export const ButtonsFooterFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    transition: 0.2s ease;
    border-radius: 4px;

    &.clear {
      color: #707070;
      font-weight: 500;
    }
    &.filter {
      color: ${colors.white};
      background-color: ${colors.unimedGreenStrong};
      padding: 9px 16px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface CalendarProps2 extends CalendarProps {
  $activeWeek?: string;
}

export const Calendar = styled(CalendarRC)<CalendarProps2>`
  .react-calendar__month-view__days {
    border-left: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
  }

  ${props =>
    props.$activeWeek &&
    css`
      .${props.$activeWeek} {
        background: ${transparentize(0.9, colors.unimedGreenStrong)} !important;
      }
    `}

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    height: 40px;
    line-height: 40px;

    abbr[title] {
      text-decoration: none;
      text-transform: capitalize;
    }
  }

  .react-calendar__tile {
    width: 40px;
    height: 40px;
    font-size: 12px;
    background: #fff;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .react-calendar__tile--active {
    background: ${colors.unimedGreenStrong};
    color: #fff;
    font-weight: 600;
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
    text-align: left;
    background: transparent;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #181c32;
    text-transform: capitalize;
  }

  .react-calendar__navigation {
    .react-calendar__navigation__arrow {
      background: transparent;
      font-size: 40px;
      line-height: 0px;
      height: 40px;
      width: 20px;
      margin-top: -10px;
    }

    .react-calendar__navigation__prev-button {
      order: 2;
    }

    .react-calendar__navigation__next-button {
      order: 3;
    }
  }
`;
