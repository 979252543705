import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IFilter } from 'hooks/beneficiary';

export class Beneficiary {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getAll(filter?: IFilter): Promise<AxiosResponse> {
    const params = {
      name: filter?.name || undefined,
      page: filter?.page || undefined,
    };
    return this.instance.get('/api/admin/beneficiaries', {
      params,
    } as AxiosRequestConfig);
  }

  async getBeneficiaryFromSGU(codigo_beneficiario: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/sgu/beneficiaries/${codigo_beneficiario}`);
  }

  async getBeneficiaryKinshipDegree(beneficiaryContract: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/kinship-degrees/${beneficiaryContract}`);
  }

  async uploadFileMassImport(formData: any): Promise<AxiosResponse> {
    return this.instance.post('/api/admin/beneficiaries/mass-import', formData);
  }

  async checkIfCanInclude(cpf: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/beneficiaries/${cpf}/can-include`);
  }

  async updateStatus(beneficiary_type: string, beneficiary_id: string, status: string): Promise<AxiosResponse> {
    return this.instance.patch(`/api/admin/${beneficiary_type}/${beneficiary_id}`, { status });
  }
}

const beneficiary = (instance: AxiosInstance): Beneficiary => new Beneficiary(instance);

export default beneficiary;
