import { useCallback, createContext, useState, useContext, useEffect } from 'react';

import { useToast } from 'hooks/toast';

import { IOrderAndPagination } from 'hooks/user';
import { isEmpty } from 'lodash';
import useDebounce from 'utils/debounce';
import api from 'services';

export interface IReadjustment {
  adjustment: number;
  id: number;
}
export interface IIndicators {
  adjustment_contracts: number;
  next_month_contracts: number;
  sent_contracts: number;
}
export interface IContract {
  adjustment: number;
  collective: string;
  company_fantasy: string;
  company_name: string;
  company_number: string;
  contract_ans: string;
  contract_number: string;
  foundation: boolean;
  inflation_index: string;
  lives: number;
  max_co_part: string;
  month: number;
  nr309: boolean;
  participation: string;
  plan_name: string;
  price_criterion: string;
  regulated: boolean;
  segmentation: string;
  validity: string;
  consultant?: string;
  id?: number;
  created_on?: string;
  status?: null | 'concluido' | 'erro' | 'pendente';
}

interface IContractFilter {
  search?: string;
  year?: number;
  month?: number;
  nr309?: boolean;
}

// eslint-disable-next-line no-shadow
export enum ContractOptions {
  DEFAULT = 'default',
  NEXT = 'next',
  READJUSTED = 'readjusted',
}
interface ContractRequirements {
  orderAndPagination: IOrderAndPagination;
  contracts: IContract[];
}

type IContracts = Record<ContractOptions, ContractRequirements>;
interface IContext {
  loading: boolean;
  importing: boolean;
  contracts: IContracts;
  contract: IContract;
  indicators: IIndicators;
  get(filter?: IContractFilter, orderAndPagination?: IOrderAndPagination, type?: ContractOptions): Promise<void>;
  getOne(id: number): Promise<void>;
  getIndicators(filter?: IContractFilter): Promise<void>;
  importFile(file: File): Promise<void>;
  filters: IContractFilter;
  clearFilter(): void;
  clear(): void;
  applyFilter(filter: IContractFilter, type?: ContractOptions): void;
  saveReadjustment(readjustments: IReadjustment[]): Promise<boolean>;
  setOrderAndPagination(data: IOrderAndPagination, type?: ContractOptions): void;
}

interface ContractParams {
  year?: number;
  month?: number;
  sent: boolean | undefined;
}

const getContractParams = (type: ContractOptions, filter?: IContractFilter): ContractParams => {
  const date = new Date();
  if (filter?.year) date.setFullYear(filter.year);
  if (filter?.month) date.setMonth(filter.month - 1);

  if (type === ContractOptions.NEXT) {
    date.setMonth(date.getMonth() + 1);

    return {
      sent: undefined,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  return {
    sent: type === ContractOptions.READJUSTED,
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};
const ContractsContext = createContext<IContext>({} as IContext);

const ContractsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [contracts, setContracts] = useState<IContracts>({} as IContracts);
  const [contract, setContract] = useState<IContract>({} as IContract);
  const [indicators, setIndicators] = useState<IIndicators>({} as IIndicators);
  const [filters, setFilter] = useState<Partial<IContract>>({} as IContract);

  const { addToast } = useToast();

  const filtersDebounced = useDebounce(filters, 200) as IContractFilter;

  const clear = useCallback(() => {
    setContracts({} as IContracts);
  }, [setContracts]);

  const setOrderAndPagination = useCallback(
    (orderAndPaginationContent: IOrderAndPagination, type: ContractOptions = ContractOptions.DEFAULT) => {
      setContracts(contractsState => {
        return {
          ...contractsState,
          [type]: {
            ...contractsState[type],
            orderAndPagination: {
              ...contractsState[type]?.orderAndPagination,
              ...orderAndPaginationContent,
            },
          },
        };
      });
    },
    [setContracts],
  );

  const getIndicators = useCallback(
    async (filter?: IContractFilter) => {
      setLoading(true);
      try {
        const response = await api.contracts().getIndicators({
          nr309: filter?.nr309,
          month: filter?.month || new Date().getMonth() + 1,
          year: filter?.year || new Date().getFullYear(),
        });
        setIndicators(response.data.content);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os indicadores' });
      }
      setLoading(false);
    },
    [addToast, setIndicators, setLoading],
  );

  const saveReadjustment = useCallback(
    async (readjustments: IReadjustment[]) => {
      setLoading(true);
      try {
        await api.contracts().readjust(readjustments);
        addToast({ type: 'success', title: 'Reajuste enviado com sucesso!' });
        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel processar o reajuste' });
      }
      setLoading(false);
      return false;
    },
    [addToast, setLoading],
  );

  const get = useCallback(
    async (
      filter?: IContractFilter,
      orderPagination?: IOrderAndPagination,
      type: ContractOptions = ContractOptions.DEFAULT,
    ) => {
      setLoading(true);
      try {
        const response = await api.contracts().get({
          ...filter,
          ...orderPagination,
          ...getContractParams(type, filter),
        });

        if (orderPagination?.page && orderPagination?.page > 1) {
          setContracts(contractsState => {
            return {
              ...contractsState,
              [type]: {
                ...contractsState[type],
                contracts: [...contractsState[type]?.contracts, ...response.data.content],
                orderAndPagination: { ...orderPagination, ...response.data.paginate },
              },
            };
          });
        } else {
          setContracts(contractsState => {
            return {
              ...contractsState,
              [type]: {
                ...contractsState[type],
                contracts: response.data.content,
                orderAndPagination: { ...orderPagination, ...response.data.paginate },
              },
            };
          });
        }
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os contratos' });
      }
      setLoading(false);
    },
    [addToast, setContracts, setLoading],
  );

  const getOne = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        const response = await api.contracts().getOne(id);

        setContract(response.data.content);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os contratos' });
      }
      setLoading(false);
    },
    [addToast, setContract, setLoading],
  );

  const importFile = useCallback(
    async (file: File) => {
      setImporting(true);
      try {
        await api.contracts().importFile(file);

        addToast({ type: 'success', title: 'CSV importado com sucesso!' });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Houve um erro ao importar CSV',
          description: error.response.data.error_message || 'Erro desconhecido',
        });
      }
      setImporting(false);
    },
    [addToast, setImporting],
  );

  const clearFilter = useCallback(() => {
    setFilter({} as IContract);
  }, [setFilter]);

  const applyFilter = useCallback(
    (applyingFilters: IContractFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...applyingFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (isEmpty(filtersDebounced) === false) {
      get(filtersDebounced);
      get(filtersDebounced, undefined, ContractOptions.NEXT);
      get(filtersDebounced, undefined, ContractOptions.READJUSTED);
      getIndicators(filtersDebounced);
    }
  }, [filtersDebounced, getIndicators, get]);

  return (
    <ContractsContext.Provider
      value={{
        indicators,
        loading,
        contracts,
        filters,
        contract,
        importing,
        setOrderAndPagination,
        getIndicators,
        get,
        getOne,
        clearFilter,
        applyFilter,
        clear,
        saveReadjustment,
        importFile,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

function useContracts(): IContext {
  const context = useContext(ContractsContext);
  if (!context) {
    throw new Error('useContracts must be used within an ContractsProvider');
  }
  return context;
}

export { ContractsProvider, useContracts };
