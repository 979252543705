import { useEffect, useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { InputBase, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import _ from 'lodash';
import colors from 'styles/colors';
import { InputAdorment2, InputLabel } from './styled';

type InputStyle = {
  size?: 'sm' | 'md' | 'lg';
  readOnly?: boolean;
};

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    width: 'calc(100% - 20px)',
  },
  label: (props: InputStyle) => {
    return {
      color: '#14142B',
      fontSize: 18,
      fontWeight: 500,
      ...(props.size === 'sm' && {
        fontSize: 15,
      }),
    };
  },
  helperLink: {
    marginLeft: 'auto',
    color: '#F47920',
    fontSize: 14,
    textDecoration: 'none',
  },
  icon: {
    marginRight: 10,
  },
  helper: {
    marginLeft: 'auto',
    fontSize: 13,
    letterSpacing: '0.75px',
    color: '#6E7191',
  },
  showPass: {
    color: '#F47920',
    fontSize: '14px',
    lineHeight: '24px',
  },
  container: {
    // minHeight: 86,
  },
}));

const DefaultInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
    height: 38,
    background: (props: InputStyle) => (props.readOnly ? '#fff' : '#F7F9FE'),
    border: (props: InputStyle) => (props.readOnly ? '1px solid #fff !important' : '1px solid #F7F9FE !important'),
    borderRadius: '6px !important',
  },
  focused: {
    border: `1px solid ${colors.unimedGreenStrong} !important`,
    backgroundColor: '#FFFFFF',
  },
  error: {
    border: `1px solid ${colors.unimedOrange} !important`,
    backgroundColor: '#FFEFE3',
  },
  input: {
    color: '#14142B',
    fontSize: 16,
    width: '100%',
    height: '100%',
    padding: (props: InputStyle) => (props.readOnly ? '14px 0px !important' : '14px 24px'),
    borderRadius: '6px !important',
    '&:focus': {
      background: '#fff',
    },
  },
}))(InputBase);

type InputProps = {
  label?: string;
  placeholder: string;
  hasForgetPassword?: boolean;
  helperLink?: string;
  helperText?: string;
  helperPosition?: 'bottom' | 'top-right';
  field?: any;
  form?: any;
  maxLength?: number;
  enableErrors?: boolean;
  type?: string;
  disabled?: boolean;
  endAdornment?: ReactElement | string;
  readOnly?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  ['data-testid']?: string;
};

const Input: React.FC<InputProps> = ({
  label,
  type,
  placeholder,
  hasForgetPassword = false,
  helperLink,
  helperText,
  helperPosition = 'top-right',
  field = {},
  maxLength,
  size,
  form: { errors },
  disabled,
  readOnly,
  enableErrors = true,
  endAdornment,
  className,
  ...restProps
}) => {
  const classes = useStyles({ size, readOnly });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (enableErrors) {
      const tmpError = _.get(errors, field.name, null);
      setError(tmpError);
    }
  }, [errors, field, enableErrors]);

  return (
    <FormControl
      className={`${classes.margin} ${classes.container} input__container ${className}`}
      style={{
        display: 'block',
      }}
    >
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}

      {helperLink && helperText && (
        <Link to={helperLink} className={classes.helperLink}>
          {helperText}
        </Link>
      )}

      {!helperLink && helperPosition === 'top-right' && helperText && (
        <span className={classes.helper}>{helperText}</span>
      )}

      <DefaultInput
        {...field}
        {...restProps}
        placeholder={placeholder}
        type={hasForgetPassword && !showPassword ? 'password' : type}
        error={!!error}
        inputProps={{ maxLength }}
        disabled={disabled}
        readOnly={readOnly}
        endAdornment={
          hasForgetPassword ? (
            <InputAdornment position="end" className={classes.showPass}>
              <Button onClick={() => setShowPassword(old => !old)}>
                <span className={classes.showPass}>{showPassword ? 'Ocultar' : 'Mostrar'}</span>
              </Button>
            </InputAdornment>
          ) : (
            <InputAdorment2>{endAdornment}</InputAdorment2>
          )
        }
      />

      {!helperLink && helperPosition === 'bottom' && helperText && <FormHelperText>{helperText}</FormHelperText>}

      {enableErrors ? (
        <FormHelperText error>{error}</FormHelperText>
      ) : (
        <ErrorMessage name={field.name}>{msg => <FormHelperText error>{msg}</FormHelperText>}</ErrorMessage>
      )}
    </FormControl>
  );
};

Input.defaultProps = {
  type: 'text',
};

export default Input;

export { DefaultInput, InputLabel };
