import { createContext, useCallback, useContext } from 'react';
import api from 'services';

interface IContext {
  downloadDocument: (fileId: string) => Promise<void>;
  deleteDocument: (fileId: string) => Promise<void>;
}

const DocumentsContext = createContext<IContext>({} as IContext);

const DocumentsProvider: React.FC = ({ children }) => {
  const downloadDocument = useCallback(async (fileId: string) => api.documents().download(fileId), []);

  const deleteDocument = useCallback(async (fileId: string) => api.documents().delete(fileId), []);

  return <DocumentsContext.Provider value={{ downloadDocument, deleteDocument }}>{children}</DocumentsContext.Provider>;
};

function useDocuments(): IContext {
  const context = useContext(DocumentsContext);

  if (context === undefined) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }

  return context;
}

export { DocumentsProvider, useDocuments };
