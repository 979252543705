import { AxiosInstance } from 'axios';

export class Imports {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getErros(params: any): Promise<any> {
    return this.instance.get('/api/admin/sgu/errors', {
      params,
      handlerEnabled: false,
    });
  }

  sendError(errorId: string): Promise<any> {
    return this.instance.put(`/api/admin/sgu/errors/${errorId}/resolve`, {
      handlerEnabled: false,
    });
  }
}

const imports = (instance: AxiosInstance): any => new Imports(instance);

export default imports;
