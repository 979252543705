export default {
  title: '#14142B',
  body: '#4E4B66',
  placeholder: '#A0A3BD',
  background: '#F7F9FE',
  white: '#FFFFFF',
  black: '#000',

  gray: '#F7F9FE',
  gray2: '#c9d3dd',
  grayLight: '#D9DBE9',
  subtitle: '#6E7191',

  unimedGreenStrong: '#00995C',
  unimedGreen: '#B1D34B',
  unimedOrange: '#F47920',

  error: '#F64E60',
  errorWhiter: '#FFEFE3',
  success: '#00BA88',
  info: '#158DF7',
  inactive: '#CCCCCC',
};
