import { AxiosInstance } from 'axios';

export class Documents {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  download(fileId: string): Promise<void> {
    return this.instance.get(`/api/documents/download/${fileId}`, {
      handlerEnabled: false,
      responseType: 'blob',
    });
  }

  delete(fileId: string): Promise<void> {
    return this.instance.delete(`/api/documents/${fileId}`);
  }
}

const documents = (instance: AxiosInstance): Documents => new Documents(instance);

export default documents;
