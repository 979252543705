import { AxiosInstance, AxiosResponse } from 'axios';
import { IForgotProps, ISignInProps, IResetProps } from 'hooks/auth';

interface IResponseSignIn {
  content: {
    access_token: string;
    name: string;
    cnpj: string;
    permissions?: string[];
  };
  success: boolean;
}

interface Permission {
  name: string;
}
interface IResponsePermissions {
  content: Permission[];
  success: boolean;
}

export class Auth {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  signIn({ password, cnpj, email }: ISignInProps): Promise<AxiosResponse> {
    return this.instance.post<IResponseSignIn>(`/api/auth/login`, { password, cnpj, email });
  }

  permissions(): Promise<AxiosResponse<IResponsePermissions>> {
    return this.instance.get<IResponsePermissions>(`/api/permissions/list/user`);
  }

  validateToken(): Promise<AxiosResponse> {
    return this.instance.post<IResponseSignIn>(`/api/auth/token`);
  }

  forgot(values: IForgotProps): Promise<AxiosResponse> {
    return this.instance.post('/api/auth/password/forgot', values);
  }

  reset(values: IResetProps): Promise<AxiosResponse> {
    return this.instance.post('/api/auth/password/reset', values);
  }

  forgotVerify(token: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/auth/password/confirm/${token}`);
  }
}

const auth = (instance: AxiosInstance): Auth => new Auth(instance);

export default auth;
