import { AxiosInstance } from 'axios';

export class FinancialBehaviour {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async listFinalBehaviour(filter?: any): Promise<any> {
    return this.instance.get(`/api/finance/loss-ratio/overall`, { params: filter, handlerEnabled: false });
  }

  async getAverageLives(filter?: any): Promise<any> {
    return this.instance.get(`/api/finance/loss-ratio/average-lives`, {
      params: filter,
      handlerEnabled: false,
    });
  }

  async getLivesLastCompetence(filter?: any): Promise<AxiosInstance> {
    return this.instance.get(`/api/finance/loss-ratio/lives-last-competence`, {
      params: filter,
      handlerEnabled: false,
    });
  }

  async getAttendancesLastCompetence(): Promise<AxiosInstance> {
    return this.instance.get(`/api/finance/loss-ratio/attendances-last-competence`, {
      handlerEnabled: false,
    });
  }

  async getRakingUtils(filter?: any): Promise<AxiosInstance> {
    return this.instance.get(`/api/finance/usage`, {
      params: filter,
      handlerEnabled: false,
    });
  }

  async getUsagePlan(filter?: any): Promise<AxiosInstance> {
    return this.instance.get(`/api/finance/usage-per-type/chart`, {
      params: filter,
      handlerEnabled: false,
    });
  }
}

const finalcialBehaviour = (instance: AxiosInstance): FinancialBehaviour => new FinancialBehaviour(instance);

export default finalcialBehaviour;
