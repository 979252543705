import styled from 'styled-components';

export const Container = styled.div`
  label + [class*='MuiInput-formControl'] {
    margin-top: 14px;
  }

  [class*='WithStyles\(ForwardRef\(Select\)\)-root'] {
    height: 38px;
  }

  [class*='MuiSelect-icon'] {
    top: calc(50% - 7px);
  }

  .Mui-error {
    [class*='WithStyles\(ForwardRef\(Select\)\)-root'] {
      border: 1px solid #f47920 !important;
      background-color: #ffefe3;
    }
  }
`;
