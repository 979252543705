import ReactLoading from 'react-loading';

import colors from 'styles/colors';
import { Container } from './styles';

const LoadingBox: React.FC = () => {
  return (
    <Container className="loading">
      <ReactLoading type="spin" color={colors.unimedGreenStrong} height={20} width={20} />
    </Container>
  );
};

export default LoadingBox;
