import { createContext, useState, useContext, useCallback } from 'react';
import { useToast } from 'hooks/toast';
import api from 'services';

export interface Notification {
  id: number;
  content: string;
  ref: string;
  relationship_id: number;
  relationship_type: string;
  type: 'plano' | 'fatura';
  url?: string;
  user_id?: number;
  created_on: string;
  viewed: boolean;
}

interface IContext {
  loading: boolean;
  notifications: Notification[];
  get(): Promise<void>;
  markAsViewed(id: number): Promise<boolean>;
}

const NotificationContext = createContext<IContext>({} as IContext);

const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const get = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.notifications().get();
      setNotifications(response.data.content);
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possivel recuperar as notificações' });
    }
    setLoading(false);
  }, [addToast, setLoading]);

  const markAsViewed = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await api.notifications().markAsViewed(id);
        get();
        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel marcar como arquivado.' });
        setLoading(false);
        return false;
      }
    },
    [addToast, get, setLoading],
  );

  return (
    <NotificationContext.Provider
      value={{
        loading,
        notifications,
        markAsViewed,
        get,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotifications(): IContext {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within an UserNotifications');
  }
  return context;
}

export { NotificationsProvider, useNotifications };
