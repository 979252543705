import { AxiosInstance } from 'axios';

interface INps {
  message: string;
  nps: number;
}

export class Nps {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(nps: INps): Promise<any> {
    return this.instance.post('/api/nps', nps);
  }

  progression(endDate: string): Promise<any> {
    return this.instance.get('/api/nps/week/progression', {
      params: { request_date: endDate },
      handlerEnabled: false,
    });
  }

  answers(params?: any): Promise<any> {
    return this.instance.get('/api/nps/answers', {
      params,
      handlerEnabled: false,
    });
  }

  amount(endDate: string): Promise<any> {
    return this.instance.get('/api/nps/week/amount', {
      params: { request_date: endDate },
      handlerEnabled: false,
    });
  }

  indicators(endDate: string): Promise<any> {
    return this.instance.get('/api/indicators/nps', {
      params: { request_date: endDate },
      handlerEnabled: false,
    });
  }

  score(endDate: string): Promise<any> {
    return this.instance.get('/api/nps', {
      params: { request_date: endDate },
      handlerEnabled: false,
    });
  }

  check(): Promise<any> {
    return this.instance.get('/api/nps/answered');
  }
}

const nps = (instance: AxiosInstance): Nps => new Nps(instance);

export default nps;
