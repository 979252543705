import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.gray};

  padding: 20px;

  img {
    max-width: 100%;
  }

  .center {
    max-width: 499px;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${colors.body};
      margin-top: 48px;
    }

    p {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.body};
    }

    button {
      margin-top: 24px;
    }
  }
`;
