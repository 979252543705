import { AxiosInstance, AxiosResponse } from 'axios';

export class GraceInfo {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async reviewGraceInfo(data: any): Promise<AxiosResponse> {
    return this.instance.post('/api/admin/grace_info/review', data);
  }
}

const grace_info = (instance: AxiosInstance): GraceInfo => new GraceInfo(instance);

export default grace_info;
