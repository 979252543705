import { useCallback, createContext, useState, useContext } from 'react';

import { format, parseISO } from 'date-fns';

import { useToast } from 'hooks/toast';

import api from 'services';

// eslint-disable-next-line no-shadow
export enum AssessmentsType {
  AVALIACAO_DS = 'AVALIACAO_DS',
  CONVOCACAO_PERICIA = 'CONVOCACAO_PERICIA',
  ANALISE_PERICIA = 'ANALISE_PERICIA',
  SEM_PERICIA = 'SEM_PERICIA',
}

export interface IAssessment {
  id: number;
  name: string;
  new: boolean;
  reply_date: string;
  replyDateFormated?: string;
  status: string;
  token: string;
  company_cnpj: string;
  proof_path: string;
  updated_on?: string;
  created_on: string;
  token_cadastro?: string;
  relationship_type: 'dependent' | 'recipient';
  relationship_id?: any;
  assessment_responsible_name?: string;
}

export interface Paginate {
  has_next: boolean;
  has_prev: boolean;
  next_page?: any;
  page: number;
  per_page: number;
  prev_page?: any;
  total: number;
  total_pages: number;
}

export interface AssessmentList {
  content: IAssessment[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface IFilter {
  type?: 'AVALIACAO_DS' | 'CONVOCACAO_PERICIA' | 'ANALISE_PERICIA' | 'SEM_PERICIA' | 'VALIDACAO_CADASTRO';
  order_by?: 'reply_date' | 'name';
  order?: 'ASC' | 'DESC';
  search?: string;
  new?: boolean;
}

interface IContext {
  loading: boolean;
  filters: IFilter;
  assessments: AssessmentList;
  get: (filter?: IFilter, paginate?: Partial<Paginate>, type?: string) => Promise<boolean>;
  getMedicalExpertiseAnalysis: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  getCIDAssessments: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  getRegistrationAssessments: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  getHealthStatementAssessments: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  applyFilter: (newFilters: Partial<IFilter>) => void;
  changePage: (page: number) => void;
  clearFilter: () => void;
  clear: () => void;
}

const AssessmentContext = createContext<IContext>({} as IContext);

const emptyAssessments = {
  content: [] as IAssessment[],
  paginate: {
    page: 0,
  },
} as AssessmentList;

const AssessmentProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilter] = useState<IFilter>({ new: true } as IFilter);
  const [assessments, setAssessments] = useState<AssessmentList>({ ...emptyAssessments });

  const { addToast } = useToast();

  const applyFilter = useCallback(
    (newFilters: Partial<IFilter>) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...newFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({} as IFilter);
  }, []);

  const changePage = useCallback(
    (page: number) => {
      setAssessments(oldAnswers => ({
        ...oldAnswers,
        paginate: { ...oldAnswers.paginate, page },
      }));
    },
    [setAssessments],
  );

  const clear = useCallback(() => {
    setFilter({ new: true } as IFilter);
    setAssessments({ ...emptyAssessments });
  }, [setAssessments]);

  const get = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.healthDeclaration().getAssessments(
            {
              order_by: filtering?.order_by || 'updated_on',
              order: filtering?.order,
              search: filtering?.search,
              page: paginate?.page,
              per_page: paginate?.per_page,
              new: filtering?.new,
            },
            filtering?.type,
          )
        ).data as AssessmentList;

        const formattedResponse = {
          ...response,
          content: response.content.map(item => ({
            ...item,
            replyDateFormated: item?.reply_date ? format(parseISO(item?.reply_date), 'dd/MM/yyyy') : '',
            updatedDateFormated: item?.updated_on ? format(parseISO(item?.updated_on), 'dd/MM/yyyy') : '',
          })),
          paginate: {
            ...response.paginate,
            total_pages: Math.ceil(response.paginate.total / response.paginate.per_page),
          },
        };

        if (response.paginate.page === 1) {
          setAssessments(formattedResponse);
        } else {
          setAssessments(oldAssesments => ({
            ...formattedResponse,
            content: [...oldAssesments.content, ...formattedResponse.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAssessments],
  );

  const getMedicalExpertiseAnalysis = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.healthDeclaration().getMedicalExpertiseAnalysis({
            order_by: filtering?.order_by || 'updated_on',
            order: filtering?.order,
            search: filtering?.search,
            page: paginate?.page,
            per_page: paginate?.per_page,
            status: filtering?.type,
            new: filtering?.new,
          })
        ).data as AssessmentList;

        if (response.paginate.page === 1) {
          setAssessments(response);
        } else {
          setAssessments(oldAssessments => ({
            ...response,
            content: [...oldAssessments.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAssessments],
  );

  const getCIDAssessments = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.healthDeclaration().getCIDAssessments({
            order_by: filtering?.order_by || 'updated_on',
            order: filtering?.order,
            search: filtering?.search,
            page: paginate?.page,
            per_page: paginate?.per_page,
            status: filtering?.type,
            new: filtering?.new,
          })
        ).data as AssessmentList;

        if (response.paginate.page === 1) {
          setAssessments(response);
        } else {
          setAssessments({
            ...response,
            content: [...response.content],
          });
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAssessments],
  );

  const getHealthStatementAssessments = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.assessment().getHealthStatementAssessments({
            order_by: filtering?.order_by || 'updated_on',
            order: filtering?.order,
            search: filtering?.search,
            page: paginate?.page,
            per_page: paginate?.per_page,
            new: filtering?.new,
          })
        ).data as AssessmentList;

        if (response.paginate.page === 1) {
          setAssessments(response);
        } else {
          setAssessments(oldAssessments => ({
            ...response,
            content: [...oldAssessments.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter as Declarações de Saúde.' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAssessments],
  );

  const getRegistrationAssessments = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.assessment().getRegistrationAssessments({
            order_by: filtering?.order_by || 'updated_on',
            order: filtering?.order,
            search: filtering?.search,
            page: paginate?.page,
            per_page: paginate?.per_page,
            new: filtering?.new,
          })
        ).data as AssessmentList;

        if (response.paginate.page === 1) {
          setAssessments(response);
        } else {
          setAssessments(oldAssessments => ({
            ...response,
            content: [...oldAssessments.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter os cadastros.' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAssessments],
  );

  return (
    <AssessmentContext.Provider
      value={{
        loading,
        filters,
        assessments,
        applyFilter,
        clearFilter,
        get,
        getMedicalExpertiseAnalysis,
        getCIDAssessments,
        getRegistrationAssessments,
        getHealthStatementAssessments,
        changePage,
        clear,
      }}
    >
      {children}
    </AssessmentContext.Provider>
  );
};

function useAssessments(): IContext {
  const context = useContext(AssessmentContext);
  if (!context) {
    throw new Error('useAssessments must be used within an AssessmentProvider');
  }
  return context;
}

export { AssessmentProvider, useAssessments };
