import { useCallback, createContext, useState, useEffect, useContext } from 'react';

import { isEmpty, uniqBy } from 'lodash';
import { useToast } from 'hooks/toast';

import api from 'services';

export interface IBeneficiariesExclude {
  id: number;
  name: string;
  date: string;
  sgu_code: string;
  cnpj: string;
  created_on: string;
}

export interface Paginate {
  has_next: boolean;
  has_prev: boolean;
  next_page?: any;
  page: number;
  per_page: number;
  prev_page?: any;
  total: number;
}

export interface BeneficiariesExcludeList {
  content: IBeneficiariesExclude[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface IFilter {
  page?: string;
  order_by?: 'name';
  order?: 'ASC' | 'DESC';
  search?: string;
}

export interface IEvaluation {
  description?: string;
  type: 'approve' | 'disapprove';
  field: string;
}

export interface ApprovalOrDisapproval {
  field: string;
  type: 'approve' | 'disapprove';
  description?: string;
}

export interface IExclusion {
  id: number;
  beneficiary_id: string;
  beneficiary_type: string;
  cnpj: string;
  code_beneficiary: string;
  company_reason: string;
  company_name: string;
  plan_description: string;
  contract: string;
  cpf: string;
  date: Date;
  death_date: Date;
  death_reason: string;
  death_reason_details: string;
  disapprovals: Array<{
    description: string;
    exclusion_id?: number;
    field: string;
    id?: number;
    resolved?: boolean;
  }>;
  email: string;
  exclusion_type: string;
  has_attachment: boolean;
  informed: string;
  informed_date: Date;
  keep_plan: string;
  name: string;
  phone: string;
  reason: string;
  retired_working: string;
  send_communication_type: string;
  sgu_code: string;
  status: string;
  taxpayer: string;
  taxpayer_month: number;
  validated: boolean;
  contributed: boolean;
}
interface IExclusionCorrectionBody {
  phone: string;
  email: string;
  death_date?: string;
  date?: string | Date;
  informed_date?: string | Date;
}

interface IContext {
  loading: boolean;
  filters: IFilter;
  exclusion: IExclusion;
  beneficiariesExclude: IBeneficiariesExclude[];
  evaluations: IEvaluation[];
  fieldsToEvaluate: string[];
  get: (filter?: IFilter) => Promise<boolean>;
  getOne(exclusion_id: number): Promise<IExclusion>;
  disapprove(exclusion_id: number): Promise<boolean>;
  approve(exclusion_id: number): Promise<boolean>;
  setEvaluation(params: ApprovalOrDisapproval): void;
  totalBeneficiariesExclude: number;
  qtyBeneficiariesExcludePages: number;
  applyFilter: (filter: IFilter) => boolean;
  clearFilter: () => void;
  clearExcludeAndDisapprovals: () => void;
  addToEvaluation: (fieldName: string) => void;
  removeFromEvaluation: (fieldName: string) => void;
  correctExclusion: (exclusion_id: number, data: IExclusionCorrectionBody) => Promise<boolean>;
}

const BeneficiariesExcludeContext = createContext<IContext>({} as IContext);

const BeneficiariesExcludeProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [exclusion, setExclusion] = useState<IExclusion>({} as IExclusion);
  const [evaluations, setEvaluationsInternal] = useState<IEvaluation[]>([]);
  const [beneficiariesExclude, setBeneficiariesExclude] = useState<IBeneficiariesExclude[]>([]);
  const [totalBeneficiariesExclude, setTotalBeneficiariesExclude] = useState<number>(0);
  const [qtyBeneficiariesExcludePages, setQtyBeneficiariesExcludePages] = useState<number>(1);
  const [fieldsToEvaluate, setFieldsToEvaluate] = useState<string[]>([]);

  const { addToast } = useToast();

  const applyFilter = useCallback(
    (filters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...filters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({} as IFilter);
  }, []);

  const clearExcludeAndDisapprovals = useCallback(() => {
    setExclusion({} as IExclusion);
    setEvaluationsInternal([]);
  }, []);

  const calculateTotalPages = (total: number, perPage: number): number => {
    const totalPages = Math.floor(total / perPage);
    if (total % perPage === 0) return totalPages;
    return totalPages + 1;
  };

  const get = useCallback(
    async filters => {
      try {
        setLoading(true);
        const response = (
          await api.beneficiariesExclude().get({
            pending: 'true',
            order_by: filters?.order_by,
            order: filters?.order,
            search: filters?.search,
            page: filters?.page,
            per_page: filters?.per_page,
          })
        ).data as BeneficiariesExcludeList;

        setBeneficiariesExclude(response.content);
        const { total } = response.paginate;
        setTotalBeneficiariesExclude(total);
        setQtyBeneficiariesExcludePages(calculateTotalPages(total, response.paginate.per_page));

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter as Avaliações de Exclusão.' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setBeneficiariesExclude],
  );

  const getOne = useCallback(
    async (exclusion_id: number) => {
      try {
        setLoading(true);

        const { data } = await api.beneficiariesExclude().getOne(exclusion_id);
        const { disapprovals: loadedDisapprovals, ...rest } = data.content;

        if (loadedDisapprovals.length) {
          const formatedDisapprovals = uniqBy(
            [
              ...loadedDisapprovals.map((disapproval: IEvaluation) => ({
                field: disapproval.field,
                type: 'disapprove' as 'disapprove' | 'approve',
                description: disapproval.description,
              })),
              ...fieldsToEvaluate.map(field => ({
                field,
                type: 'approve' as 'disapprove' | 'approve',
                description: null,
              })),
              ...Object.entries(rest).map(item => ({
                field: item[0],
                type: 'approve' as 'disapprove' | 'approve',
                description: null,
              })),
            ],
            d => d.field,
          );
          const filteredDisapprovals = formatedDisapprovals.filter(item => fieldsToEvaluate.includes(item.field));
          setEvaluationsInternal(filteredDisapprovals);
        }

        setExclusion(rest);
        setLoading(false);

        return rest as IExclusion;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter a Avaliação de Exclusão.' });
        setLoading(false);
        return {} as IExclusion;
      }
    },
    [addToast, fieldsToEvaluate],
  );

  const parseDateString: any = (dateString: string) => {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const correctExclusion = useCallback(
    async (exclusion_id: number, data: IExclusionCorrectionBody) => {
      try {
        setLoading(true);

        const informed_date = data.informed_date ? parseDateString(data.informed_date) : null;
        const date = data.date ? parseDateString(data.date) : null;

        Object.assign(data, { informed_date, date });

        await api.beneficiariesExclude().correct(exclusion_id, data);

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível corrigir a exclusão.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const setEvaluation = useCallback((params: ApprovalOrDisapproval) => {
    setEvaluationsInternal(p => {
      const data = p.filter(d => d.field !== params.field);

      data.push(params);

      return data;
    });
  }, []);

  const disapprove = useCallback(
    async (exclusion_id: number) => {
      try {
        setLoading(true);

        await api.beneficiariesExclude().disapprove(exclusion_id, {
          fields: evaluations
            .filter(item => item.type === 'disapprove')
            .map(item => ({
              field: item.field,
              description: item.description,
            })),
        });

        setLoading(false);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível enviar a avaliação.' });
        setLoading(false);
        return false;
      }
    },
    [addToast, evaluations],
  );

  const approve = useCallback(
    async (exclusion_id: number) => {
      try {
        setLoading(true);

        await api.beneficiariesExclude().approve(exclusion_id);

        setLoading(false);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível enviar a avaliação.' });
        setLoading(false);
        return false;
      }
    },
    [addToast],
  );

  const addToEvaluation = useCallback(
    (fieldName: string) => {
      if (fieldsToEvaluate.includes(fieldName)) return;

      setFieldsToEvaluate(p => [...p, fieldName]);
    },
    [fieldsToEvaluate],
  );

  const removeFromEvaluation = useCallback((fieldName: string) => {
    setFieldsToEvaluate(p => p.filter(f => f !== fieldName));
  }, []);

  useEffect(() => {
    if (!isEmpty(filter)) {
      get(filter);
    }
  }, [filter, get]);

  return (
    <BeneficiariesExcludeContext.Provider
      value={{
        loading,
        filters: filter,
        exclusion,
        beneficiariesExclude,
        totalBeneficiariesExclude,
        qtyBeneficiariesExcludePages,
        evaluations,
        fieldsToEvaluate,
        applyFilter,
        clearFilter,
        get,
        getOne,
        disapprove,
        approve,
        clearExcludeAndDisapprovals,
        setEvaluation,
        addToEvaluation,
        removeFromEvaluation,
        correctExclusion,
      }}
    >
      {children}
    </BeneficiariesExcludeContext.Provider>
  );
};

function useBeneficiariesExclude(): IContext {
  const context = useContext(BeneficiariesExcludeContext);
  if (!context) {
    throw new Error('useBeneficiariesExclude must be used within an BeneficiariesExcludeProvider');
  }
  return context;
}

export { BeneficiariesExcludeProvider, useBeneficiariesExclude };
