import { createContext, useCallback, useContext, useState } from 'react';

import { uniqBy } from 'lodash';

import { useToast } from 'hooks/toast';

import api from 'services';

export type Disease = {
  key?: string;
  id?: number | string | null;
  year?: number | string;
  disease?: string;
  place?: string;
};
export interface AnswersEntity {
  answer: boolean | null;
  disease?: string;
  place?: string;
  year?: number;
  obsolete?: boolean;
  id?: number;
  id_question: number;
  id_dependent?: number;
  id_recipient?: number;
  diseases: Disease[];
}

export interface Recipient {
  answers: AnswersEntity[];
  height: number;
  id?: number;
  id_recipient?: number;
  id_dependent?: number;
  name: string;
  weight: number;
  accepted?: boolean;
  crm?: string;
  doctor?: string;
  doctor_email?: string;
  fill_type?: string;
  token: string;
  reply_date?: Date;
  date_birth?: Date;
  date_birth_format?: string;
  status?: string;
  validated?: boolean;
  portability_approved?: boolean;
  age?: number;
}

export interface DependentsEntity {
  answers: AnswersEntity[];
  height: number;
  id?: number;
  id_dependent?: number;
  id_recipient?: number;
  crm?: string;
  doctor?: string;
  doctor_email?: string;
  name: string;
  weight: number;
  reply_date?: Date;
  token: string;
  token_cadastro?: string;
  accepted?: boolean;
  date_birth?: Date;
  date_birth_format?: string;
  fill_type?: string;
  status?: string;
  age?: number;
}
export interface QuestionsEntity {
  active: boolean;
  id: number;
  ordination: number;
  question: string;
  year: string;
}

export interface MedicalOrientationEntity {
  crm: string;
  name: string;
  email: string;
}

export interface HealthDeclarationForm {
  dependents?: DependentsEntity[];
  questions?: QuestionsEntity[];
  recipient?: Recipient;
  type?: string;
  token?: string;
  medicalOrientation?: MedicalOrientationEntity;
}

export interface HealthStatementAnswers {
  beneficiary: Recipient | DependentsEntity;
  questions: QuestionsEntity[];
}

export interface HealthDeclarationCidsForm {
  cid: string;
  description: string;
  observation: string;
  relationship_id: number;
  relationship_type: string;
}

export interface ApprovalOrDisapproval {
  id: number | string;
  type: 'approve' | 'disapprove';
  relationship?: 'dependent' | 'recipient';
  userId?: number | string;
  reason?: string;
}

interface AssessmentAnswer {
  answer_id: number;
  description: string;
  relationship_id: number;
  relationship_type: 'dependent' | 'recipient';
  resolved: boolean;
  user_id: number;
}
export interface BeneficiaryAssessmentAnswer {
  id?: number;
  field: string;
  description: string;
  relationship_id: number;
  relationship_type: 'dependent' | 'recipient';
  resolved: boolean;
  user_id: number;
}

export interface HealthDeclarationInfo {
  id?: number;
  id_recipient?: number;
  accepted?: boolean;
  reply_date?: Date;
  token?: string;
}

export interface HealthDeclarationCid {
  cid: string;
  id: string;
  description: string;
  doctor_id: number;
  relationship_id: number;
  relationship_type: string;
  user_id: number;
}

export interface HealthDeclarationSaveReturn {
  saved: boolean;
  content?: HealthDeclarationForm;
}
interface IContext {
  loading: boolean;
  form?: HealthDeclarationForm;
  cids?: HealthDeclarationCid[];
  declarationInfo?: HealthDeclarationInfo;
  approvalOrDisapproval: ApprovalOrDisapproval[];
  healthStatementAnswers: HealthStatementAnswers;
  setForm: (data: HealthDeclarationForm) => void;
  get: (token: string) => Promise<boolean>;
  getHealthDeclaration: (id: string, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getAdminHealthStatementById: (id: string, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getHealthDeclarationCids: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getHealthStatementAnswersByBeneficiary: (token: string, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getAdmin: (id: string) => Promise<boolean>;
  save: (data: HealthDeclarationForm) => Promise<HealthDeclarationSaveReturn>;
  assignCid: (data: HealthDeclarationCidsForm, shouldUpdateState?: boolean) => Promise<boolean>;
  deactivate: (token: string, type?: string) => Promise<boolean>;
  setApprovalOrDisaproval: (params: ApprovalOrDisapproval) => void;
  clearForm: () => void;
  clearAssessment: () => void;
  approve: (id: string, type?: string) => Promise<boolean>;
  approveBeneficiary: (id: string, type?: string, has_guardianship?: boolean, need_ds?: boolean) => Promise<boolean>;
  disapprove: (data: any) => Promise<boolean>;
  disapproveExamination: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  disapproveBeneficiary: (data: any) => Promise<boolean>;
  approveExamination: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  finishCidAssignment: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getAssessmentAnswers: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  getBeneficiaryAssessmentAnswers: (id: number, relationship: 'dependent' | 'recipient') => Promise<boolean>;
  removeCid: (cid_id: string) => Promise<boolean>;
}

const HealthDeclarationContext = createContext<IContext>({} as IContext);

const HealthDeclarationProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [declarationForm, setDeclarationForm] = useState<HealthDeclarationForm>();
  const [cids, setCids] = useState<HealthDeclarationCid[]>([]);
  const [declarationInfo, setDeclarationInfo] = useState<HealthDeclarationInfo>();
  const [approvalOrDisapproval, setApprovalOrDisaprovalInternal] = useState<ApprovalOrDisapproval[]>([]);
  const [healthStatementAnswers, setHealthStatementAnswers] = useState<HealthStatementAnswers>(
    {} as HealthStatementAnswers,
  );

  const { addToast } = useToast();

  const clearAssessment = useCallback(() => {
    setApprovalOrDisaprovalInternal([]);
  }, []);

  const setForm = useCallback(
    (data: HealthDeclarationForm): void => {
      setDeclarationForm({ ...declarationForm, ...data });
    },
    [declarationForm, setDeclarationForm],
  );

  const setApprovalOrDisaproval = useCallback(
    (params: ApprovalOrDisapproval): void => {
      setApprovalOrDisaprovalInternal(p => {
        const data = p.filter(
          d =>
            !(
              String(d.userId) === String(params.userId) &&
              d.relationship === params.relationship &&
              d.id === params.id
            ),
        );

        data.push(params);

        return data;
      });
    },
    [setApprovalOrDisaprovalInternal],
  );

  const get = useCallback(
    async (token: string) => {
      try {
        setLoading(true);

        const response = await api.healthDeclaration().get(token);

        setDeclarationForm({ ...response.data.content, token });

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getHealthDeclaration = useCallback(async (id: string, relationship: 'dependent' | 'recipient') => {
    try {
      setLoading(true);

      const { data } = await api.healthDeclaration().getHealthDeclarationInfo(id, relationship);

      setDeclarationInfo({ ...data.content });

      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const getAdminHealthStatementById = useCallback(
    async (id: string, type = 'recipient') => {
      try {
        setLoading(true);

        const { data } = await api.healthDeclaration().getHealthStatementAnswersById(id, type);

        setHealthStatementAnswers(data.content);

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível carregar a declaração de saúde.' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getAssessmentAnswers = useCallback(
    async (id: number, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);

        const { data } = await api.healthDeclaration().getAssessmentsAnswers(id, relationship);

        const assessments = data.content as AssessmentAnswer[];

        if (assessments.length) {
          setApprovalOrDisaprovalInternal(a =>
            uniqBy(
              [
                ...assessments.map(asses => ({
                  id: asses.answer_id,
                  type: 'disapprove' as 'approve' | 'disapprove',
                  relationship: asses.relationship_type,
                  userId: asses.relationship_id,
                  reason: asses.description,
                })),
                ...(declarationForm?.questions?.map(q => ({
                  id: q.id,
                  type: 'approve' as 'approve' | 'disapprove',
                  relationship,
                  userId: id,
                })) || []),
                ...(healthStatementAnswers?.questions?.map(q => ({
                  id: q.id,
                  type: 'approve' as 'approve' | 'disapprove',
                  relationship,
                  userId: id,
                })) || []),
                {
                  id: 0,
                  type: 'approve' as 'approve' | 'disapprove',
                  relationship,
                  userId: id,
                },
                ...a,
              ],
              d => `${d.id}-${d.relationship}-${d.userId}`,
            ),
          );
        }

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast, declarationForm?.questions, healthStatementAnswers?.questions],
  );

  const getBeneficiaryAssessmentAnswers = useCallback(
    async (id: number, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);
        const { data } = await api.healthDeclaration().getBeneficiaryAssessmentAnswers(id, relationship);

        const assessments = data.content as BeneficiaryAssessmentAnswer[];

        if (assessments.length) {
          setApprovalOrDisaprovalInternal(a =>
            uniqBy(
              [
                ...assessments.map(asses => ({
                  id: asses.field,
                  type: 'disapprove' as 'approve' | 'disapprove',
                  relationship: asses.relationship_type,
                  userId: String(asses.relationship_id),
                  reason: asses.description,
                })),
                ...a,
              ],
              d => String(d.id) + d.relationship + String(d.userId),
            ),
          );
        }

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getAdmin = useCallback(
    async (token: string) => {
      try {
        setLoading(true);

        const response = await api.healthDeclaration().getAdmin(token);

        setDeclarationForm({ ...response.data.content, token });

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getHealthDeclarationCids = useCallback(async (id: number, relationship: 'dependent' | 'recipient') => {
    try {
      setLoading(true);

      const { data } = await api.healthDeclaration().getCids(id, relationship);

      setCids(data.content);

      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const getHealthStatementAnswersByBeneficiary = useCallback(
    async (token: string, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);

        const { data } = await api.healthDeclaration().getHealthStatementAnswers(token, relationship);

        setHealthStatementAnswers(data.content);

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível carregar as informações da Declaração de Saúde' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const save = useCallback(
    async (data: HealthDeclarationForm) => {
      try {
        setLoading(true);

        const response = await api.healthDeclaration().save(data);

        return {
          saved: true,
          content: response.data.content,
        };
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return {
          saved: false,
          content: undefined,
        };
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const assignCid = useCallback(
    async (data: HealthDeclarationCidsForm, shouldUpdateState?: boolean) => {
      try {
        setLoading(true);

        const response = await api.healthDeclaration().assignCid(data);

        if (shouldUpdateState) {
          setCids(prevState => [...prevState, response.data.content]);
        }

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atribuir Cid à Declaração de Saúde' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const approve = useCallback(
    async (id: string, type = 'recipient') => {
      try {
        setLoading(true);

        await api.healthDeclaration().approve(id, type);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const approveBeneficiary = useCallback(
    async (id: string, type = 'recipient', has_guardianship: boolean, need_ds: boolean) => {
      try {
        setLoading(true);

        await api.healthDeclaration().approveBeneficiary(id, type, has_guardianship, need_ds);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const approveExamination = useCallback(
    async (id: number, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);

        await api.healthDeclaration().approveExamination(id, relationship);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const finishCidAssignment = useCallback(
    async (id: number, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);

        await api.healthDeclaration().finishCidAssignment(id, relationship);
        addToast({ type: 'success', title: 'Enviado para perícia!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível enviar para perícia.' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const disapproveExamination = useCallback(
    async (id: number, relationship: 'dependent' | 'recipient') => {
      try {
        setLoading(true);

        await api.healthDeclaration().disapproveExamination(id, relationship);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const disapprove = useCallback(
    async (values: any) => {
      try {
        setLoading(true);

        await api.healthDeclaration().disapprove(values);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const disapproveBeneficiary = useCallback(
    async (values: any) => {
      try {
        setLoading(true);

        await api.healthDeclaration().disapproveBeneficiary(values);
        addToast({ type: 'success', title: 'Salvo com sucesso!' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o formulário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const deactivate = useCallback(
    async (token: string, type = 'beneficiary') => {
      try {
        setLoading(true);
        if (type === 'dependent') {
          const response = await api.healthDeclaration().deactivateDependent(token);
          return response.data.content.id;
        }

        const response = await api.healthDeclaration().deactivateRecipient(token);

        return response.data.content.id;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível desativar o token.' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearForm = useCallback(() => {
    setDeclarationForm(undefined);
  }, [setDeclarationForm]);

  const removeCid = useCallback(
    async (cid_id: string) => {
      try {
        setLoading(true);

        await api.healthDeclaration().removeCid(cid_id);

        setCids(prevState => prevState.filter(cid => cid.id !== cid_id));

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível remover o CID da Declaração de Saúde' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <HealthDeclarationContext.Provider
      value={{
        approvalOrDisapproval,
        loading,
        form: declarationForm,
        cids,
        declarationInfo,
        healthStatementAnswers,
        deactivate,
        setForm,
        clearForm,
        get,
        getAdmin,
        getHealthDeclaration,
        getAdminHealthStatementById,
        getHealthDeclarationCids,
        getHealthStatementAnswersByBeneficiary,
        save,
        assignCid,
        setApprovalOrDisaproval,
        clearAssessment,
        approve,
        disapprove,
        getAssessmentAnswers,
        getBeneficiaryAssessmentAnswers,
        approveExamination,
        disapproveExamination,
        approveBeneficiary,
        finishCidAssignment,
        disapproveBeneficiary,
        removeCid,
      }}
    >
      {children}
    </HealthDeclarationContext.Provider>
  );
};

function useHealthDeclaration(): IContext {
  const context = useContext(HealthDeclarationContext);
  if (!context) {
    throw new Error('useRecipient must be used within an RecipientProvider');
  }
  return context;
}

export { HealthDeclarationProvider, useHealthDeclaration };
