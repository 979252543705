import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(20, 20, 43, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 12;
`;
