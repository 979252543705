import { Redirect, Route, Switch } from 'react-router-dom';

import RoutesEnum from 'enum/Routes';
import { useAuth } from 'hooks/auth';
import { IRoute } from 'routes';
import DeniedAccess from 'components/pages/DeniedAccess';

const AuthGuard: React.FC<{ routes?: IRoute[] }> = ({ children, routes }) => {
  const { authData } = useAuth();

  if (!authData.access_token) {
    return <Redirect to={RoutesEnum.LOGIN_ROUTE} />;
  }

  if (authData.access_token === 'initial') return null;

  return (
    <Switch>
      {routes?.map(route => (
        <Route path={route.path} exact={route.exact}>
          {!route.permission && children}
          {route.permission && authData.permissions?.includes(route.permission) ? children : <DeniedAccess />}
        </Route>
      ))}
    </Switch>
  );
};

export default AuthGuard;
