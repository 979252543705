import styled, { css } from 'styled-components';

interface ArrowProps {
  position?: 'center' | 'bottom' | 'bottomRight';
}

export const Arrow = styled.div<ArrowProps>`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  top: -10px;

  border-bottom: 10px solid #4e4b66;

  ${props =>
    props.position === 'center' &&
    css`
      top: 20px;
      left: -15px;
      transform: rotate(270deg);
    `}

  ${props =>
    props.position === 'bottomRight' &&
    css`
      right: 30px;
    `}
`;
