import { AxiosInstance, AxiosResponse } from 'axios';

export class HealthStatement {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  save(body: any): Promise<AxiosResponse> {
    return this.instance.post(`/api/health-statement`, body);
  }
}

const healthStatement = (instance: AxiosInstance): HealthStatement => new HealthStatement(instance);

export default healthStatement;
