import { AxiosInstance } from 'axios';
import { Beneficiary } from 'hooks/beneficiary';

export class SguV2Api {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  transformBeneficiaryCode = (beneficiaryCode: string | null | undefined) => {
    let result = (beneficiaryCode && beneficiaryCode.substring(4)) || 'invalid-code-beneficiary';
    result = result.replace(/[.-]/g, '');
    return result;
  };

  async getOperators(): Promise<any> {
    try {
      const response = await this.instance.get('/api/sgu/v2/plans-operators');
      return response.data;
    } catch (error) {
      console.error('Error fetching', error);
      throw error;
    }
  }

  async inclusionPortability(beneficiary: Beneficiary): Promise<any> {
    const body = {
      BNF_ID: this.transformBeneficiaryCode(beneficiary?.previous_beneficiary_code),
      BNF_COD_PESSOA: '',
      BNF_IND_MOTIVO_INCL: '41',
      BNF_FLG_OUTRO_PLANO: 'S',
      OPLS_COD: beneficiary?.previous_plan_operator?.split('-')[0],
      BNF_COD_OUTRO_PLANO: beneficiary?.previous_plan_code,
    };
    try {
      const response = await this.instance.post('/api/sgu/v2/portability', body);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async previousPlan(beneficiary: Beneficiary): Promise<any> {
    const body = {
      BNF_ID: this.transformBeneficiaryCode(beneficiary?.previous_beneficiary_code),
      BNF_COD_UNIMED_ORIG: '0025',
      BNF_COD_CNTRAT_ORIG: beneficiary?.contract,
      BNF_COD_BNFRIO_ORIG: beneficiary.previous_beneficiary_code,
      BNF_COD_DEPNTE_ORIG: '',
    };
    try {
      const response = await this.instance.post('/api/sgu/v2/previous-plan', body);
      return response.data;
    } catch (error) {
      console.error('Error fetching access token:', error);
      throw error;
    }
  }
}

const sguV2Api = (instance: AxiosInstance): SguV2Api => new SguV2Api(instance);

export default sguV2Api;
