import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IFilter } from 'hooks/invoice';

export class Billing {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getInvoiceHighlighted(): Promise<any> {
    return this.instance.get('/api/invoices/highlighted');
  }

  getInvoices(filter: IFilter): Promise<any> {
    return this.instance.get('/api/invoices', {
      params: filter,
    } as AxiosRequestConfig);
  }

  sendEmail(fatura: string | number): Promise<any> {
    return this.instance.get(`/api/invoices/paymentslip/${fatura}/duplicate`);
  }

  getBarCode(fatura: string | number): Promise<any> {
    return this.instance.get(`/api/invoices/barcode/${fatura}`);
  }

  getInvoiceFile(fatura: string | number): Promise<any> {
    return this.instance.get(`/api/invoice/${fatura}`, { responseType: 'blob' } as AxiosRequestConfig);
  }

  getInvoiceCoparticipation(fatura: string | number, tipo: string, family = false): Promise<any> {
    return this.instance.post(
      `/api/invoices/analytics/coparticipation`,
      {
        numero_fatura: fatura,
        tipo_relatorio: tipo,
        family,
      },
      {
        responseType: 'blob',
      } as AxiosRequestConfig,
    );
  }

  getInvoiceMonthlyPayment(fatura: string | number, tipo: string, family = false): Promise<any> {
    return this.instance.post(
      `/api/invoices/analytics/monthlypayment`,
      {
        numero_fatura: fatura,
        tipo_relatorio: tipo,
        family,
      },
      {
        responseType: 'blob',
      } as AxiosRequestConfig,
    );
  }
}

const billing = (instance: AxiosInstance): Billing => new Billing(instance);

export default billing;
