import { useEffect, useState, ReactElement } from 'react';
import ReactInputMask from 'react-input-mask';

import { InputBase, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import _ from 'lodash';
import colors from 'styles/colors';
import { InputLabel } from '../Input/styled';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    width: 'calc(100% - 20px)',
  },
  label: {
    color: '#14142B',
    fontSize: 18,
    fontWeight: 500,
  },
  container: {
    minHeight: 86,
  },
}));

const DefaultInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
    height: 38,
    background: '#F7F9FE',
    border: '1px solid #F7F9FE !important',
    borderRadius: '6px !important',
  },
  focused: {
    border: '1px solid #00995C !important',
    backgroundColor: '#FFFFFF',
  },
  error: {
    border: `1px solid ${colors.unimedOrange} !important`,
    backgroundColor: '#FFEFE3',
  },
  input: {
    color: '#14142B',
    fontSize: 16,
    height: '100%',
    width: '100%',
    padding: '14px 24px',
    borderRadius: '6px !important',
    '&:focus': {
      background: '#fff',
    },
  },
}))(InputBase);

type InputMaskProps = {
  label: string;
  placeholder: string;
  mask: string;
  field?: any;
  form?: any;
  enableErrors?: boolean;
  msgError?: string;
  disabled?: boolean;
  appendRight?: ReactElement;
  className?: string;
  helperText?: string;
};

const InputMask: React.FC<InputMaskProps> = ({
  label,
  placeholder,
  mask,
  field = {},
  form: { errors },
  enableErrors = true,
  msgError,
  disabled,
  appendRight,
  className = '',
  helperText = '',
}) => {
  const classes = useStyles();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (enableErrors) {
      const tmpError = _.get(errors, field.name, null);
      setError(tmpError);
    }
  }, [errors, field, enableErrors]);

  return (
    <FormControl className={`${classes.margin} ${classes.container} input_mask__container ${className}`}>
      <InputLabel className={classes.label}>{label}</InputLabel>

      <ReactInputMask mask={mask} {...field} disabled={disabled}>
        {() => (
          <DefaultInput
            name={field.name}
            data-test={`field-${field.name}`}
            disabled={disabled}
            placeholder={placeholder}
            error={!!error}
          />
        )}
      </ReactInputMask>

      <FormHelperText>{helperText}</FormHelperText>

      {appendRight}

      {enableErrors ? (
        <FormHelperText error>{error}</FormHelperText>
      ) : (
        <ErrorMessage name={field.name}>{msg => <FormHelperText error>{msgError ?? msg}</FormHelperText>}</ErrorMessage>
      )}
    </FormControl>
  );
};

export default InputMask;
