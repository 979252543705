import { ExcludeStatusEnum, ExcludeStatusForAdminEnum } from 'enum/exclude_status';
import { SecondCopyStatusEnum, SecondCopyStatusForAdminEnum } from 'enum/second_copy_status';
import { StatusEnum, InclusionStatusForAdmin } from 'enum/status';
import { TransferStatusEnum, TransferStatusForAdminEnum } from 'enum/transfer_status';
import { UpdateBeneficiaryStatusEnum, UpdateBeneficiaryStatusForAdminEnum } from 'enum/update_beneficiary_status';

export const getStatusDescription = (
  statusKey: string,
  movementType: 'inclusion' | 'exclusion' | 'transfer' | 'second_copy' | 'update_beneficiary' = 'inclusion',
): string => {
  if (!statusKey) return StatusEnum.GENERIC;
  const enums = {
    inclusion: StatusEnum,
    exclusion: ExcludeStatusEnum,
    transfer: TransferStatusEnum,
    second_copy: SecondCopyStatusEnum,
    update_beneficiary: UpdateBeneficiaryStatusEnum,
  };

  const Enum = enums[movementType];
  return Enum[statusKey.toUpperCase() as keyof typeof Enum] || Enum.GENERIC;
};

export const getStatusForAdminDescription = (
  statusKey: string,
  movementType: 'inclusion' | 'exclusion' | 'transfer' | 'second_copy' | 'update_beneficiary' = 'inclusion',
): string => {
  if (!statusKey) return InclusionStatusForAdmin.GENERIC;
  const enums = {
    inclusion: InclusionStatusForAdmin,
    exclusion: ExcludeStatusForAdminEnum,
    transfer: TransferStatusForAdminEnum,
    second_copy: SecondCopyStatusForAdminEnum,
    update_beneficiary: UpdateBeneficiaryStatusForAdminEnum,
  };
  const Enum = enums[movementType];
  return Enum[statusKey.toUpperCase() as keyof typeof Enum] || Enum.GENERIC;
};
