import { AxiosInstance, AxiosResponse } from 'axios';
import { IUser } from 'hooks/user';

interface IPaginateResponse {
  has_next: boolean;
  has_prev: boolean;
  next_page: number | null;
  page: number | null;
  per_page: number;
  prev_page: number | null;
  total: number;
}

interface IUsersResponse {
  content: IUser[];
  paginate: IPaginateResponse;
}
interface IUserResponse {
  content: IUser;
}
export class Users {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  register(values: any): Promise<AxiosResponse> {
    return this.instance.post(`/users`, values);
  }

  get(params?: any): Promise<AxiosResponse<IUsersResponse>> {
    return this.instance.get('/api/users', { params, handlerEnabled: false });
  }

  update(params?: any): Promise<AxiosResponse<IUserResponse>> {
    const { id, ...data } = params;
    return this.instance.put(`/api/users/${id}`, data);
  }

  create(params?: any): Promise<AxiosResponse<IUserResponse>> {
    return this.instance.post(`/api/users`, params);
  }

  getOne(userId: number): Promise<AxiosResponse<IUserResponse>> {
    return this.instance.get(`/api/users/${userId}`);
  }
}

const user = (instance: AxiosInstance): Users => new Users(instance);

export default user;
