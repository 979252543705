export const styledTheme = {
  fontFamily: {
    sans: ['Poppins', 'sans-serif'],
  },
  colors: {
    white: '#ffffff',
    slate: {
      '50': '#f8fafc',
      '100': '#f1f5f9',
      '200': '#e2e8f0',
      '300': '#cbd5e1',
      '400': '#94a3b8',
      '500': '#64748b',
      '600': '#475569',
      '700': '#334155',
      '800': '#1e293b',
      '900': '#0f172a',
      '950': '#070a16',
    },
    'unimed-green': {
      '50': '#ebfef3',
      '100': '#d0fbe1',
      '200': '#a4f6c9',
      '300': '#6aebad',
      '400': '#2fd88c',
      '500': '#0abf74',
      '600': '#00995d', // main
      '700': '#007c4f',
      '800': '#03623f',
      '900': '#045036',
      '950': '#012d1f',
    },
    'unimed-light-green': {
      '50': '#f8fbea',
      '100': '#eff7d0',
      '200': '#dfefa7',
      '300': '#c8e274',
      '400': '#b1d34b',
      '500': '#92b82a',
      '600': '#71921e',
      '700': '#56701b',
      '800': '#46591b',
      '900': '#3b4c1b',
      '950': '#1e290a',
    },
    'unimed-orange': {
      '50': '#fff7ed',
      '100': '#feedd6',
      '200': '#fcd8ac',
      '300': '#fabb77',
      '400': '#f79440',
      '500': '#f47920',
      '600': '#e55c11',
      '700': '#be4510',
      '800': '#973715',
      '900': '#7a2f14',
      '950': '#421608',
    },
    carnation: {
      '50': '#fff1f1',
      '100': '#ffe4e4',
      '200': '#fecdd0',
      '300': '#fea3a9',
      '400': '#fc707b',
      '500': '#f64e60',
      '600': '#e21c3a',
      '700': '#bf1130',
      '800': '#a01130',
      '900': '#89122f',
      '950': '#4c0514',
    },
    'dodger-blue': {
      '50': '#eefaff',
      '100': '#d9f2ff',
      '200': '#bbeaff',
      '300': '#8ddeff',
      '400': '#57c9ff',
      '500': '#30acff',
      '600': '#158df7',
      '700': '#1277e3',
      '800': '#155fb8',
      '900': '#175291',
      '950': '#133258',
    },
    'meadow-green': {
      '50': '#eafff6',
      '100': '#cdfee8',
      '200': '#a0fad7',
      '300': '#63f2c2',
      '400': '#25e2a9',
      '500': '#00ba88',
      '600': '#00a478',
      '700': '#008364',
      '800': '#006750',
      '900': '#005543',
      '950': '#003027',
    },
    grey: {
      '50': '#f2f2f2',
      '100': '#e5e5e5',
      '200': '#d9d9d9',
      '300': '#cccccc',
      '400': '#999999',
      '500': '#666666',
      '600': '#222222',
      '700': '#111111',
      '800': '#000000',
    },
    neutrals: {
      white: '#FFFFFF',
      black: '#000000',
    },
    customs: {
      title: '#14142B',
      text: '#14142B',
      body: '#4E4B66',
      placeholder: '#A0A3BD',
      background: '#F7F9FE',
      border: '#00995C',
      feedback: '#FFF3F8',
    },
  },
};

export type StyledTheme = typeof styledTheme;
