import { AxiosInstance, AxiosResponse } from 'axios';

export class BeneficiariesExclude {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(params: any): Promise<AxiosResponse> {
    return this.instance.get('/api/sgu/beneficiary/exclude', {
      params,
      handlerEnabled: false,
    });
  }

  getOne(exclusion_id: number): Promise<any> {
    return this.instance.get(`/api/sgu/beneficiary/exclude/${exclusion_id}`);
  }

  disapprove(exclusion_id: number, data: any): Promise<any> {
    return this.instance.post(`/api/beneficiary/exclude/${exclusion_id}/disapprove`, data, {
      headers: { 'Content-Type': 'application/json' },
      handlerEnabled: false,
    });
  }

  approve(exclusion_id: number): Promise<any> {
    return this.instance.post(`/api/beneficiary/exclude/${exclusion_id}/approve`);
  }

  correct(exclusion_id: number, data: any): Promise<any> {
    return this.instance.post(`/api/movement/exclude/${exclusion_id}/correct`, data);
  }

  exclude(movementId: number): Promise<any> {
    return this.instance.delete(`/api/admin/movements/exclusion/${movementId}`);
  }
}

const beneficiariesExclude = (instance: AxiosInstance): BeneficiariesExclude => new BeneficiariesExclude(instance);

export default beneficiariesExclude;
