import { useCallback, createContext, useState, useContext } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';

export interface IIndicator {
  [key: string]: string | null;
}

export interface IIndicatorCreate {
  content: string;
  description: string;
}

interface IContext {
  loading: boolean;
  indicators: IIndicator;
  create: (indicator: IIndicatorCreate) => Promise<boolean>;
  get: (description: string) => Promise<boolean>;
}

const InvoiceContext = createContext<IContext>({} as IContext);

const IndicatorsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [indicators, setIndicators] = useState<IIndicator>({} as IIndicator);

  const { addToast } = useToast();

  const create = useCallback(
    async (indicator: IIndicatorCreate) => {
      try {
        setLoading(true);

        await api.indicators().create(indicator);

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar indicador!' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const get = useCallback(
    async (description: string) => {
      try {
        setLoading(true);

        const indicator = await api.indicators().get(description);

        if (indicator.data.content) {
          setIndicators(ind => ({
            ...ind,
            [description]: indicator.data.content,
          }));
        }

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível recuperar indicador!' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return <InvoiceContext.Provider value={{ loading, create, get, indicators }}>{children}</InvoiceContext.Provider>;
};

function useIndicators(): IContext {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error('useNps must be used within an NpsProvider');
  }
  return context;
}

export { IndicatorsProvider, useIndicators };
