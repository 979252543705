import { FaTimes } from 'react-icons/fa';
import { useGlobal } from 'hooks/global';

import ModalDefault from 'components/templates/ModalDefault';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, createStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import colors from 'styles/colors';
import { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from 'components/atoms/Button';
import { useCompany } from 'hooks/company';
import { differenceWith, isEqual } from 'lodash';
import { Container } from '../../atoms/ModalPendingFields/styles';
import { Content, Header, Info, Footer } from './style';

import { ReactComponent as InfoIcon } from '../../../assets/imgs/info.svg';
import ModalCompanyProfileIdentification from './identification';
import ModalCompanyProfileAddress from './address';
import ModalCompanyProfileAccountables from './accountables';

export interface MessagePendingFields {
  title: string;
  errors: (string | undefined)[];
}

export interface ModalPendingFieldsHandles {
  openModal: (messages: MessagePendingFields[]) => void;
}

interface StyledTabProps {
  label: string;
}

const schema = Yup.object().shape({
  company_name: Yup.string().required('O Nome é obrigatório'),
  accountables: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('É obrigatório selecionar'),
      name: Yup.string().required('O Nome é obrigatório'),
      email: Yup.string().email('O email deve ser válido').required('O Email é obrigatório'),
      whatsapp: Yup.string().min(11, 'Whatsapp inválido').nullable(),
      phone: Yup.string().min(11, 'Telefone inválido').required('O Telefone é obrigatório'),
      ramal: Yup.string().nullable(),
    }),
  ),
  address: Yup.object().shape({
    zipcode: Yup.string().min(8, 'CEP Inválido').required('O CEP é obrigatório'),
    address: Yup.string().required('O Endereço é obrigatório'),
    number: Yup.string().min(1).required('O Número é obrigatório'),
    complement: Yup.string(),
    district: Yup.string().min(1).required('O Bairro é obrigatório'),
    city: Yup.string().required('A Cidade é obrigatória'),
  }),
});

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: '100px',
      marginRight: 32,
      color: colors.body,
      fontWeight: 400,
      '&$selected': {
        color: colors.unimedGreenStrong,
      },
      '&:focus': {
        opacity: 1,
      },
    },
  }),
)((props: StyledTabProps) => <Tab {...props} />);

const ModalCompanyProfile: React.FC = () => {
  const {
    updateUserCompany,
    updateContacts,
    updateUserCompanyAddress,
    getUserCompany,
    deleteContact,
    company,
    loading,
  } = useCompany();

  const { companyProfileOpen: visible, hideCompanyProfile } = useGlobal();
  const [identificationFileAttachment, setIdentificationFile] = useState<File | null>(null);
  const [addressFileAttachment, setAddressFile] = useState<File | null>(null);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  const initialValuesForm = useMemo(() => {
    const lastAddress = company?.adresses?.[company?.adresses?.length - 1];

    return {
      company_name: company.company_name || '',
      image: { url: company.image },
      address: {
        id: lastAddress?.id,
        zipcode: lastAddress?.zipcode || '',
        address: lastAddress?.address || '',
        number: lastAddress?.number || '',
        complement: lastAddress?.complement || '',
        district: lastAddress?.district || '',
        city: lastAddress?.city || '',
      },
      accountables:
        company?.contacts?.length > 0
          ? company.contacts
          : [
              {
                type: '',
                name: '',
                email: '',
                whatsapp: '',
                phone: '',
                branch: '',
              },
            ],
    };
  }, [company]);

  const handleSubmit = async (fields: typeof initialValuesForm): Promise<void> => {
    let success = true;

    if (success && fields.company_name !== initialValuesForm.company_name) {
      success = await updateUserCompany({
        company_name: fields.company_name,
        proof: identificationFileAttachment as File,
      });
    }

    if (success && differenceWith([fields.address], [initialValuesForm.address], isEqual).length > 0) {
      success = await updateUserCompanyAddress({
        ...fields.address,
        proof: addressFileAttachment as File,
      });
    }

    if (success) {
      const idsAccountables = company?.contacts.map(c => c.id);
      const newIdAccountables = fields.accountables.map(c => c.id).filter(d => d);

      const toRemoveIds = idsAccountables.filter(id => newIdAccountables.includes(id) === false) as number[];

      success = await updateContacts(
        fields.accountables
          .filter((accountable, i) => initialValuesForm.accountables[i] !== accountable)
          .map(acc => ({
            ...acc,
            whatsapp: acc.whatsapp?.replace(/[^0-9]/gi, ''),
            phone: acc.phone?.replace(/[^0-9]/gi, ''),
            main: acc.type === 'tudo',
          })),
      );

      success = await deleteContact(toRemoveIds);
    }

    if (success) {
      getUserCompany();
      hideCompanyProfile();
    }
  };

  return (
    <ModalDefault styleContent={{ maxWidth: 720, width: '100%' }} visible={visible}>
      <Formik enableReinitialize validationSchema={schema} initialValues={initialValuesForm} onSubmit={handleSubmit}>
        {({ isValid, initialValues, values, touched, dirty }) => {
          const isChangeableAddress =
            dirty &&
            Object.values(touched).find(d => d) &&
            differenceWith([values.address], [initialValues.address], isEqual).length > 0;

          return (
            <Form>
              <Container>
                <Header>
                  <h2>Perfil da empresa</h2>
                  <FaTimes size={16} color="#4E4B66" onClick={hideCompanyProfile} />
                </Header>
                <Content>
                  <Tabs value={value} onChange={handleChange}>
                    <StyledTab label="Identificação" />
                    <StyledTab label="Endereço" />
                    <StyledTab label="Responsável" />
                  </Tabs>
                  {[0, 1].includes(value) && (
                    <Info>
                      <InfoIcon />
                      <div>
                        Caso altere seu perfil, sua solicitação será analisada pela nossa equipe. Em caso de aprovação
                        seu cadastro será atualizado em até 5 dias úteis
                      </div>
                    </Info>
                  )}
                  <div className="content_inside">
                    {value === 0 && (
                      <ModalCompanyProfileIdentification
                        values={values}
                        initialValues={initialValues}
                        fileAttachment={identificationFileAttachment}
                        setFile={setIdentificationFile}
                      />
                    )}
                    {value === 1 && (
                      <ModalCompanyProfileAddress
                        isChangeable={isChangeableAddress}
                        fileAttachment={addressFileAttachment}
                        setFile={setAddressFile}
                        values={values}
                        dirty={dirty}
                        touched={touched}
                        initialValues={initialValues}
                      />
                    )}
                    {value === 2 && <ModalCompanyProfileAccountables values={values} initialValues={initialValues} />}
                  </div>
                </Content>
                <Footer>
                  <Button
                    disabled={
                      dirty &&
                      (!isValid ||
                        (initialValues.company_name !== values.company_name && !identificationFileAttachment) ||
                        (isChangeableAddress && !addressFileAttachment) ||
                        loading)
                    }
                    loading={loading}
                    resizable
                    type="submit"
                    size="md"
                    buttonText="Salvar"
                  />
                </Footer>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </ModalDefault>
  );
};

export default ModalCompanyProfile;
