import { AxiosInstance, AxiosResponse } from 'axios';

import { HealthDeclarationCid, HealthDeclarationForm, HealthDeclarationInfo } from 'hooks/healthDeclaration';

interface ResponseDefaultHealthDeclaration {
  content: HealthDeclarationForm;
}

interface ResponseHealthDeclarationInfo {
  content: HealthDeclarationInfo;
}

interface ResponseHealthDeclarationCids {
  content: HealthDeclarationCid[];
}

export class HealthDeclaration {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(token: string): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.get(`/api/healthstatement/form/${token}`);
  }

  approve(id: string, type = 'recipient'): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.post(`/api/admin/healthstatement/${type}/approval/${id}`);
  }

  approveBeneficiary(
    id: string,
    type = 'recipient',
    has_guardianship: boolean,
    need_ds?: boolean,
  ): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    const body = {
      has_guardianship,
      need_ds,
    };
    return this.instance.post(`/api/validated/approval/${type}/${id}`, body);
  }

  approveExamination(id: number, relationship: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/healthstatement/examination/approval/${relationship}/${id}`);
  }

  disapproveExamination(id: number, relationship: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/healthstatement/examination/disapproval/${relationship}/${id}`);
  }

  disapprove(data: any): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.post(`/api/healthstatement/disapproval`, data);
  }

  disapproveBeneficiary(data: any): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.post(`/api/validated/disapproval`, data);
  }

  getAdmin(token: string): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.get(`/api/healthstatement/admin/${token}`);
  }

  getAssessments(params: any, type: any): Promise<AxiosResponse> {
    switch (type) {
      case 'ANALISE_PERICIA':
        return this.instance.get(`/api/admin/healthstatement/medical_expertise/analysis`, {
          params,
          handlerEnabled: false,
        });
      case 'CONVOCACAO_PERICIA':
        return this.instance.get(`/api/admin/healthstatement/medical_expertise/eligible`, {
          params,
          handlerEnabled: false,
        });
      case 'SEM_PERICIA':
        return this.instance.get(`/api/admin/healthstatement/medical_expertise/ineligible`, {
          params,
          handlerEnabled: false,
        });
      default:
        return this.instance.get(`/api/healthstatement/medi`, {
          params,
          handlerEnabled: false,
        });
    }
  }

  getMedicalExpertiseAnalysis(params: any): Promise<AxiosResponse> {
    return this.instance.get('/api/admin/healthstatement/medical_expertise/analysis', {
      params,
      handlerEnabled: false,
    });
  }

  getCIDAssessments(params: any): Promise<AxiosResponse> {
    return this.instance.get('/api/healthstatement/cid', {
      params,
      handlerEnabled: false,
    });
  }

  getHealthDeclarationInfo(id: string, type = 'recipient'): Promise<AxiosResponse<ResponseHealthDeclarationInfo>> {
    return this.instance.get(`/api/healthstatement/${type}/${id}`);
  }

  getAssessmentsAnswers(id: number, relationship: 'dependent' | 'recipient'): Promise<AxiosResponse> {
    return this.instance.get(`/api/healthstatement/disapproval/${relationship}/${id}`);
  }

  getBeneficiaryAssessmentAnswers(id: number, relationship: 'dependent' | 'recipient'): Promise<AxiosResponse> {
    return this.instance.get(`/api/validated/disapproval/${relationship}/${id}`);
  }

  save({ token, ...params }: HealthDeclarationForm): Promise<AxiosResponse<ResponseDefaultHealthDeclaration>> {
    return this.instance.post(`/api/healthstatement/form/${token}`, params);
  }

  deactivateRecipient(token: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/healthstatement/recipient/deactivate/${token}`);
  }

  deactivateDependent(token: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/healthstatement/dependent/deactivate/${token}`);
  }

  getCids(id: number, relationship: 'dependent' | 'recipient'): Promise<AxiosResponse<ResponseHealthDeclarationCids>> {
    return this.instance.get(`/api/healthstatement/cid/${relationship}/${id}`);
  }

  getHealthStatementAnswers(token: string, relationship: 'dependent' | 'recipient'): Promise<AxiosResponse> {
    return this.instance.get(`/api/healthstatement/list/${relationship}/${token}`);
  }

  getHealthStatementAnswersById(id: string, type = 'recipient'): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/healthstatement/${type}/${id}`);
  }

  assignCid(params: any): Promise<AxiosResponse> {
    return this.instance.post('/api/healthstatement/cid', params);
  }

  removeCid(cid_id: string): Promise<AxiosResponse> {
    return this.instance.delete(`/api/healthstatement/cid/${cid_id}`);
  }

  finishCidAssignment(id: number, relationship: 'dependent' | 'recipient'): Promise<AxiosResponse> {
    return this.instance.post(`/api/healthstatement/cid/${relationship}/${id}/finish`);
  }

  createMedicalOpinion(params: any): Promise<AxiosResponse> {
    return this.instance.post('/api/health-statement/medical-opinion', params);
  }

  getMedicalOpinionByToken(token: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/health-statement/medical-opinion/${token}`);
  }

  getMedicalOpinionById(id: string, type: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/health-statement/medical-opinion/${type}/${id}`);
  }

  getQualifiedInterviewList(): Promise<AxiosResponse> {
    return this.instance.get('/api/health-statements/qualified-interview');
  }

  getHealthStatementToFillByBeneficiaryId(
    beneficiary_id: string,
    beneficiary_type: 'recipient' | 'dependent',
  ): Promise<AxiosResponse> {
    return this.instance.get(`/api/health-statements/${beneficiary_type}/${beneficiary_id}`);
  }
}

const healthDeclaration = (instance: AxiosInstance): HealthDeclaration => new HealthDeclaration(instance);

export default healthDeclaration;
