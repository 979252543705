/* eslint-disable no-shadow */
export enum ExcludeStatusEnum {
  RASCUNHO = 'Rascunho',
  PENDENTE = 'Aguardando exportação',
  FINALIZADO = 'Processo concluído',
  COM_ERRO = 'Erro na exportação, faça contato com a Unimed',
  AVALIACAO = 'Análise Unimed - Cadastro',
  IMPORTACAO = 'Importação',
  CANCELADO = 'Cancelado',
  CORRECAO = 'Disponível para correção da empresa',
  ELIMINADO = 'ELIMINADO',
  GENERIC = 'Status desconhecido',
}

export enum ExcludeStatusColorsMap {
  CORRECAO = '#EFBC14',
  CANCELADO = '#4E4B66',
  IMPORTACAO = '#158DF7',
  AVALIACAO = '#158DF7',
  FINALIZADO = '#00BA88',
  COM_ERRO = '#F64E60',
  PENDENTE = '#EFBC14',
  RASCUNHO = '#4E4B66',
  ELIMINADO = '#4E4B66',
}

export enum ExcludeStatusForAdminEnum {
  PENDENTE = 'Aguardando exportação',
  FINALIZADO = 'Processo concluído',
  COM_ERRO = 'Erro na exportação',
  AVALIACAO = 'Avaliação do Cadastro',
  IMPORTACAO = 'Importação',
  CANCELADO = 'Cancelado',
  CORRECAO = 'Disponível para correção da empresa',
  ELIMINADO = 'ELIMINADO',
  GENERIC = 'Status desconhecido',
}
