import { rgba } from 'polished';
import styled from 'styled-components';
import colors from 'styles/colors';

export const FilterContainer = styled.div`
  margin-top: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 16px 40px ${rgba('#7090b0', 0.2)};
  padding: 26px 24px 18px;
  width: calc(300px - 48px);

  &.active {
    background-color: ${rgba('#00995D', 0.05)};
    border-color: rgba(0, 153, 93, 0.2);
  }

  [class*='MuiMenuItem-root'] {
    white-space: normal !important;
  }

  ul {
    padding: 0;
    overflow: auto;
    max-height: 300px;
  }

  .filter__item {
    display: flex;
    align-items: center;
    padding: 0;

    & + .filter__item {
      margin-top: 14px;
    }

    &:hover {
      background-color: transparent;
    }

    > span {
      flex: 1;
    }
    > button {
      background-color: #ebeef6;
      border: none;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s ease;
      width: 24px;
      height: 24px;

      &.checked {
        background-color: ${colors.unimedGreenStrong};

        &:hover {
          background-color: #047e4e;
        }
      }
    }
  }

  hr {
    background-color: #f7f9fe;
    border: none;
    margin: 24px 0 18px;
    width: 100%;
    height: 1px;
  }
`;

export const ButtonsFooterFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    transition: 0.2s ease;

    &.clear {
      color: #14142b;
    }
    &.filter {
      color: ${colors.unimedOrange};
    }
    &:hover {
      /* text-decoration: underline; */
    }
  }
`;
