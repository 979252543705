import styled from 'styled-components';
import colors from 'styles/colors';

export const InputAdorment2 = styled.div`
  position: absolute;
  right: -20px;
`;

export const InputLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.75px;
  color: ${colors.title};
  // margin-bottom: -24px;
  white-space: nowrap;
  display: flex;

  span {
    opacity: 0.5;
  }
`;
