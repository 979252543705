/* eslint-disable no-shadow */
export enum SecondCopyStatusEnum {
  CORRECAO = 'Correção da solicitação de segunda via',
  EXPORTAR_SGU = 'Exportação para o SGU',
  FINALIZADO = 'Processo concluído',
  VALIDACAO_CADASTRO = 'Análise Unimed - Cadastro',
  GENERIC = 'Status desconhecido',
  CANCELADO = 'Cancelado',
  REPROVADO = 'Reprovado',
}

export enum SecondCopyStatusForAdminEnum {
  CORRECAO = 'Correção pela empresa',
  EXPORTAR_SGU = 'Exportação para o SGU',
  FINALIZADO = 'Processo concluído',
  VALIDACAO_CADASTRO = 'Avaliação do Cadastro',
  REPROVADO = 'Reprovado',
  CANCELADO = 'Cancelado',
  GENERIC = 'Status desconhecido',
}
