import { useCallback, useEffect, useMemo } from 'react';
import { useTransition } from 'react-spring';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { useToast, IToast } from 'hooks/toast';

import { Container } from './styles';

interface ToastProps {
  message: IToast;
  className: string;
  style: any;
}

const useStylesLab = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

const Toast: React.FC<ToastProps> = ({ message }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  const exceptionTypeMessages = useMemo(() => ['Beneficiário enviado para exclusão com sucesso!'], []);

  const getType = useCallback(() => {
    if (message.type === 'error' && !exceptionTypeMessages.includes(message.title)) return 'Erro! ';
    if (message.type === 'success' && !exceptionTypeMessages.includes(message.title)) return 'Sucesso! ';
    return '';
  }, [exceptionTypeMessages, message.title, message.type]);

  const Action = useCallback(
    () => (
      <IconButton aria-label="close" color="inherit" size="small" onClick={() => removeToast(message.id)}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    ),
    [message.id, removeToast],
  );

  return (
    <Collapse in>
      <Alert
        severity={message.type}
        icon={false}
        variant="filled"
        style={{ width: '100%', maxWidth: '380px' }}
        action={<Action />}
      >
        {/* eslint-disable-next-line */}
        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: `<b>${getType()}</b> ${message.title}` }} />
        {/* eslint-disable-next-line */}
        {message.description && <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: `${message.description}` }} />}
      </Alert>
    </Collapse>
  );
};

const ToastContainer: React.FC<{ messages: IToast[] }> = ({ messages }: { messages: IToast[] }) => {
  const classesLab = useStylesLab();

  const messagesWithTransitions = useTransition(messages, message => message.id, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '50%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  });

  return (
    <Container>
      <div className={classesLab.root}>
        {messagesWithTransitions.map(({ item, key, props }) => (
          <Toast className="toast-default" key={key} message={item} style={props} />
        ))}
      </div>
    </Container>
  );
};

export default ToastContainer;
