import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { init } from '@amplitude/analytics-browser';

import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import LogRocket from 'logrocket';
import App from './App';
import reportWebVitals from './reportWebVitals';

LogRocket.init('0ngeru/unimed');

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  release: process.env.REACT_APP_RELEASE,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

init(process.env.REACT_APP_AMPLITUDE_KEY ?? '');

setLogger({
  log: message => {
    Sentry.captureMessage(message);
  },
  warn: message => {
    Sentry.captureMessage(message);
  },
  error: error => {
    Sentry.captureException(error);
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
