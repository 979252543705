import { useCallback, createContext, useState, useContext } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

export interface INps {
  message: string;
  nps: number;
}

export interface NpsWeek {
  end: string;
  nps: number;
  start: string;
}
export interface NpsAmout {
  detractors: number;
  neutral: number;
  promoters: number;
}

export interface NpsAmountWeek {
  end: string;
  nps_amout: NpsAmout;
  start: string;
}

export interface NpsIndicators {
  answered: number;
  ignored: number;
  sent: number;
}

export interface NpsScore {
  nps_amount: {
    detractors: number;
    neutral: number;
    promoters: number;
  };
  nps_percentage: {
    detractors: number;
    neutral: number;
    promoters: number;
  };
  nps_rate: number;
}

export interface Company {
  name?: string;
  image?: string;
}
export interface Content {
  created_on: Date;
  id: number;
  message: string;
  nps: number;
  user_id: number;
  company: Company | null;
  name?: string | null;
  image?: string | null;
}

export interface Paginate {
  has_next: boolean;
  has_prev: boolean;
  next_page?: any;
  page: number;
  per_page: number;
  prev_page?: any;
  total: number;
}

export interface Answers {
  content: Content[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface IFilter {
  type?: 'PROMOTERS' | 'NEUTRAL' | 'DETRACTORS';
  order_by?: 'created_on' | 'nps';
  order?: 'ASC' | 'DESC';
  search?: string;
  startDate: Date;
  endDate: Date;
}

interface IContext {
  loading: boolean;
  filters: IFilter;
  isOpen: boolean;
  progression: NpsWeek[];
  amount: NpsAmountWeek[];
  indicators: NpsIndicators;
  score: NpsScore;
  answers: Answers;
  toggle: () => void;
  checkAndOpen: () => void;
  create: (nps: INps) => Promise<boolean>;
  getAnswers: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  getProgression: (date: Date) => Promise<void>;
  getAmount: (date: Date) => Promise<void>;
  getIndicators: (date: Date) => Promise<void>;
  getScore: (date: Date) => Promise<void>;
  applyFilter: (nps: Partial<IFilter>) => void;
  changePage: (page: number) => void;
  clearFilter: () => void;
}

const typeToCode = (type?: 'PROMOTERS' | 'NEUTRAL' | 'DETRACTORS'): number => {
  if (type === 'PROMOTERS') return 2;
  if (type === 'NEUTRAL') return 3;
  if (type === 'DETRACTORS') return 4;
  return 1;
};

const InvoiceContext = createContext<IContext>({} as IContext);

const NpsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [filters, setFilter] = useState<IFilter>({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  } as IFilter);
  const [progression, setProgression] = useState<NpsWeek[]>([]);
  const [indicators, setIndicators] = useState<NpsIndicators>({} as NpsIndicators);
  const [amount, setAmount] = useState<NpsAmountWeek[]>([]);
  const [score, setScore] = useState<NpsScore>({} as NpsScore);
  const [answers, setAnswers] = useState<Answers>({
    content: [] as Content[],
    paginate: {
      page: 0,
    },
  } as Answers);

  const { addToast } = useToast();

  const applyFilter = useCallback(
    (newFilters: Partial<IFilter>) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...newFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter(() => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }));
  }, []);

  const getProgression = useCallback(
    async (date: Date) => {
      try {
        setLoading(true);
        const response = await api.nps().progression(format(date, 'yyyy-MM-dd'));
        setProgression(response.data.content.weeks);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter progressão' });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setProgression],
  );

  const getAmount = useCallback(
    async (date: Date) => {
      try {
        setLoading(true);
        const response = await api.nps().amount(format(date, 'yyyy-MM-dd'));
        setAmount(response.data.content.weeks);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter totais' });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setAmount],
  );

  const changePage = useCallback(
    (page: number) => {
      setAnswers(oldAnswers => ({
        ...oldAnswers,
        paginate: { ...oldAnswers.paginate, page },
      }));
    },
    [setAnswers],
  );

  const getAnswers = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.nps().answers({
            start_date: filtering?.startDate ? format(filtering.startDate, 'yyyy-MM-dd') : undefined,
            end_date: filtering?.endDate ? format(filtering.endDate, 'yyyy-MM-dd') : undefined,
            order_by: filtering?.order_by,
            search: filtering?.search,
            order: filtering?.order,
            page: paginate?.page,
            per_page: paginate?.per_page,
            type: typeToCode(filtering?.type),
          })
        ).data as Answers;

        if (response.paginate.page === 1) {
          setAnswers(response);
        } else {
          setAnswers(oldAnswers => ({
            ...response,
            content: [...oldAnswers.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter respostas' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setAnswers],
  );

  const getScore = useCallback(
    async (date: Date) => {
      try {
        setLoading(true);
        const response = await api.nps().score(format(date, 'yyyy-MM-dd'));
        setScore(response.data.content);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter totais' });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setScore],
  );

  const getIndicators = useCallback(
    async (date: Date) => {
      try {
        setLoading(true);
        const response = await api.nps().indicators(format(date, 'yyyy-MM-dd'));
        setIndicators(response.data.content.nps);
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter indicadores' });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setIndicators],
  );

  const create = useCallback(
    async (nps: INps) => {
      try {
        setLoading(true);

        await api.nps().create(nps);
        addToast({ type: 'success', title: 'NPS salvo!' });
        setOpen(false);

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar nps' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const checkAndOpen = useCallback(async () => {
    try {
      await api.nps().check();
      setOpen(true);
    } catch {
      // EMPTY
    }
  }, []);

  const toggle = useCallback(() => setOpen(t => !t), []);

  return (
    <InvoiceContext.Provider
      value={{
        progression,
        isOpen,
        loading,
        filters,
        amount,
        indicators,
        score,
        answers,
        create,
        toggle,
        checkAndOpen,
        applyFilter,
        clearFilter,
        getProgression,
        getAmount,
        getIndicators,
        getScore,
        getAnswers,
        changePage,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

function useNps(): IContext {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error('useNps must be used within an NpsProvider');
  }
  return context;
}

export { NpsProvider, useNps };
