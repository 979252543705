import { createContext, useCallback, useContext, useState } from 'react';

import Toast from 'components/atoms/Toast';
import { v4 } from 'uuid';

interface IHook {
  addToast(data: { type: string; title: string; description?: string }): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<IHook>({} as IHook);

export interface IToast {
  id: string;
  type: 'error' | 'success' | 'info' | 'warning';
  title: string;
  description?: string;
}

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IToast[]>([]);

  const addToast = useCallback(
    ({ type, title, description }) => {
      const id = v4();
      const toast = {
        id,
        type,
        title,
        description,
      };
      setMessages(state => [...state, toast]);
    },
    [setMessages],
  );

  const removeToast = useCallback(id => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast(): IHook {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('O contexto Toast deve estar dentro de um Provider válido');
  }

  return context;
}
