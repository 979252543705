import { AxiosInstance, AxiosResponse } from 'axios';

export interface ICID {
  code: string;
  description: string;
}

interface IPaginateResponse {
  has_next: boolean;
  has_prev: boolean;
  next_page: number | null;
  page: number | null;
  per_page: number;
  prev_page: number | null;
  total: number;
}

interface ICidsResponse {
  content: ICID[];
  paginate: IPaginateResponse;
}

export class Cid {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(params?: any): Promise<AxiosResponse<ICidsResponse>> {
    return this.instance.get('/api/cids', { params, handlerEnabled: false });
  }
}

const cid = (instance: AxiosInstance): Cid => new Cid(instance);

export default cid;
