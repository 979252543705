import { useMemo, useState } from 'react';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { v4 } from 'uuid';
import { Arrow } from './styles';

interface PopoverProps {
  content: string;
  position?: 'center' | 'bottom' | 'bottomRight';
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  style?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      position: 'relative',
      overflow: 'initial',
      background: '#4E4B66',
      color: '#fff',
      boxShadow: 'none !important',
      padding: '12px 19px',
      marginTop: (props: PopoverProps) => (props.position === 'center' ? '-3px' : '12px'),
      marginLeft: (props: PopoverProps) => (props.position === 'center' ? '12px' : '0px'),
      borderRadius: (props: PopoverProps) => (props.position === 'center' ? '6px' : '16px'),
      maxWidth: (props: PopoverProps) => (props.position === 'center' ? '235px' : '336px'),
      fontSize: '16px',
    },
  }),
);

const bottom = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: -20,
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  } as PopoverOrigin,
};

const center = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right',
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left',
  } as PopoverOrigin,
};

const MouseOverPopover: React.FC<PopoverProps> = ({
  anchorOrigin,
  transformOrigin,
  children,
  content,
  position,
  style,
}) => {
  const classes = useStyles({ position } as PopoverProps);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const id = useMemo(() => `id-${v4()}`, []);

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose(): void {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <div className="popover" style={style || undefined}>
      <div
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id={id}
        className={`${classes.popover} position`}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin || position === 'center' ? center.anchorOrigin : bottom.anchorOrigin}
        transformOrigin={transformOrigin || position === 'center' ? center.transformOrigin : bottom.transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Arrow position={position} />
        {content}
      </Popover>
    </div>
  );
};

export default MouseOverPopover;
