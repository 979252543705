import { AxiosInstance, AxiosResponse } from 'axios';

import { Beneficiary, Person, IFilter } from 'hooks/recipients';
import { BeneficiariesSgu } from 'hooks/sgu';

import { IBeneficiaryNewData, IBeneficiaryUpdateData } from 'components/pages/HealthPlanBeneficiaryNew';

interface ConfirmTokenResponse {
  content: Beneficiary;
}

interface ConfirmTokenDependentResponse {
  content: Person;
}

interface DuplicateDocument {
  relationship_id: string;
  relationship_type: string;
  type: string;
}

export class Recipient {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(data: IBeneficiaryNewData): Promise<AxiosResponse> {
    return this.instance.post('/api/recipients', data);
  }

  createDependent(data: Partial<Person>): Promise<AxiosResponse> {
    return this.instance.post('/api/dependents', data);
  }

  getOne(id: number, type = 'recipients'): Promise<AxiosResponse> {
    return this.instance.get(`/api/${type}/${id}`);
  }

  delete(id: number, type = 'recipients'): Promise<AxiosResponse> {
    return this.instance.delete(`/api/${type}/${id}`);
  }

  update(data: Partial<IBeneficiaryUpdateData>): Promise<AxiosResponse> {
    const { token, ...body } = data;
    return this.instance.put(`/api/recipients/${token}`, { ...body });
  }

  updateDependent(data: IBeneficiaryUpdateData): Promise<AxiosResponse> {
    const { token, ...body } = data;
    return this.instance.put(`/api/dependents/${token}`, { ...body });
  }

  async getAll(filter?: IFilter): Promise<AxiosResponse> {
    const params = {
      plan: filter?.plan || undefined,
      name: filter?.name || undefined,
      validity: filter?.date || undefined,
      status: filter?.status?.length ? filter.status : undefined,
      date: undefined,
    };

    const recipients = await this.instance.get('/api/recipients', { params, handlerEnabled: false });
    const dependents = await this.instance.get('/api/dependents', { params, handlerEnabled: false });

    if (filter?.type === 'DEPENDENT') {
      return dependents;
    }

    if (filter?.type === 'RECIPIENT') {
      return recipients;
    }

    recipients.data.content = [...recipients.data.content, ...dependents.data.content];
    return recipients;
  }

  sgu(filter: any): Promise<AxiosResponse<BeneficiariesSgu>> {
    return this.instance.get(`/api/sgu/recipients`, {
      params: filter,
      handlerEnabled: false,
    });
  }

  sguExclude(exclude: any): Promise<AxiosResponse> {
    return this.instance.post(`/api/sgu/beneficiary/exclude`, exclude);
  }

  getSguExclude(excludeId: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/sgu/beneficiary/exclude/${excludeId}`);
  }

  confirmToken(token: string): Promise<AxiosResponse<ConfirmTokenResponse>> {
    return this.instance.post(`/api/recipients/confirm/${token}`, {});
  }

  confirmTokenDependent(token: string): Promise<AxiosResponse<ConfirmTokenDependentResponse>> {
    return this.instance.post(`/api/dependents/confirm/${token}`, {});
  }

  getHealthStatementById(id: number, type = 'recipient'): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/healthstatement/${type}/${id}`);
  }

  createDocument(data: FormData): Promise<AxiosResponse> {
    return this.instance.post('/api/documents', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  removeDocument(documentId: string): Promise<AxiosResponse> {
    return this.instance.delete(`/api/documents/${documentId}`);
  }

  duplicateDocument(documentId: string, data: DuplicateDocument): Promise<AxiosResponse> {
    return this.instance.post(`/api/documents/${documentId}/duplicate`, data);
  }

  getDocumentsByRecipient(beneficiaryId: string, type: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/documents/${type}/${beneficiaryId}`);
  }

  getDocumentsByRecipientId(beneficiaryId: string, type: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/documents/${type}/${beneficiaryId}`);
  }

  deactivate(token: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/recipients/deactivate/${token}`);
  }

  deactivateDependent(token: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/dependents/deactivate/${token}`);
  }

  plans(): Promise<AxiosResponse> {
    return this.instance.get(`/api/contracts/plans`);
  }

  minimumValidity(contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/contracts/minimum-validity/${contract}`);
  }

  initialValidity(contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/contracts/initial-validity/${contract}`);
  }

  hasInitialValidity(sgu_code?: string, contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/contracts/has-initial-validity/${contract}/${sgu_code}`);
  }

  minimumValidityBySguCode(sgu_code: string, contract: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/companies/${sgu_code}/contracts/${contract}/minimum-validity`);
  }

  workerTypes(contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/worker-types/${contract}`);
  }

  genders(): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/genders`);
  }

  countries(): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/countries`);
  }

  maritalStatuses(): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/maritalstatuses`);
  }

  socialGenders(): Promise<AxiosResponse> {
    return this.instance.get(`api/lookups/socialgenders`);
  }

  costCenters(contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/cost-center/${contract}`);
  }

  kinship(contract?: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/kinship-degrees/${contract}`);
  }
}

const recipient = (instance: AxiosInstance): Recipient => new Recipient(instance);

export default recipient;
