import { AxiosInstance, AxiosResponse } from 'axios';

export class Finantial {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAnalitics(): Promise<AxiosResponse> {
    return this.instance.get(`/api/v2/finance/analytics`);
  }

  getListInvoices(values?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/v2/finance/invoices/paid`, values);
  }

  getListOpen(values?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/v2/finance/invoices/pending`, values);
  }
}

const finantial = (instance: AxiosInstance): Finantial => new Finantial(instance);

export default finantial;
