import { AxiosInstance, AxiosResponse } from 'axios';
import { DismissalReason } from '../../hooks/reason';

export class Reason {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getDeathReasons(): Promise<AxiosResponse> {
    return this.instance.get('/api/lookups/death-reasons');
  }

  async getDismissalReasonsList(): Promise<any> {
    return this.instance.get('/api/lookups/dismissal-reasons');
  }

  getDismissalReasons(): DismissalReason[] {
    // return this.instance.get('/api/sgu/dismissal_reasons');
    return [
      {
        description: 'Demissão ou exoneração sem justa causa',
        id: '00000000-0000-0000-0000-000000000001',
        inactivation_date: null,
        restricted: true,
        sequence: 1,
      },
      {
        description: 'Aposentadoria',
        id: '00000000-0000-0000-0000-000000000002',
        inactivation_date: null,
        restricted: true,
        sequence: 2,
      },
      {
        description: 'Outros',
        id: '00000000-0000-0000-0000-000000000003',
        inactivation_date: null,
        restricted: true,
        sequence: 3,
      },
      {
        description: 'ROMPIMENTO DO CONTRATO POR INICIATIVA DO BENEFICI)',
        id: '97f77c95-9984-4b72-8630-d62894053f3c',
        inactivation_date: null,
        restricted: false,
        sequence: 4,
      },
    ];
  }
}

const reason = (instance: AxiosInstance): Reason => new Reason(instance);

export default reason;
