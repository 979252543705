import styled from 'styled-components';

import colors from 'styles/colors';
import { rgba } from 'polished';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: ${rgba(colors.background, 0.6)};

  svg {
    width: 30px;
    color: ${colors.unimedGreenStrong};
  }
`;
