import { Field } from 'formik';

import Input from 'components/atoms/Input';
import InputMask from 'components/atoms/InputMask';
import PreviewUpload from 'components/atoms/PreviewUpload';
import { FaPaperclip } from 'react-icons/fa';
import { useCallback, useRef, ChangeEvent } from 'react';
import colors from 'styles/colors';
import { GridContainer, ProofOfNameChange } from './style';
import { AttachmentDocumentContainer } from '../HealthPlanBeneficiaryNew/styles';

interface ModalCompanyProfileAddressProps {
  values: any;
  touched: any;
  dirty: boolean;
  isChangeable?: boolean;
  initialValues: any;
  fileAttachment: File | null;
  setFile: (file: File | null) => void;
}

const ModalCompanyProfileAddress: React.FC<ModalCompanyProfileAddressProps> = ({
  fileAttachment,
  setFile,
  touched,
  isChangeable,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleLabelClick = useCallback(() => {
    if (inputFileRef.current) inputFileRef.current.value = '';
  }, []);

  const handleChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileUpload = e.target.files ? e.target.files[0] : null;
      if (fileUpload) setFile(fileUpload);
    },
    [setFile],
  );

  const handleDeleteFile = useCallback(() => {
    setFile(null);
  }, [setFile]);

  const DocumentAttachment = useCallback(() => {
    return (
      <AttachmentDocumentContainer>
        {fileAttachment ? (
          <PreviewUpload file={fileAttachment} deleteFile={handleDeleteFile} />
        ) : (
          <label htmlFor="fileUpload">
            <FaPaperclip size={16} color={colors.white} />
            <span>Anexar documento</span>
            <input
              ref={inputFileRef}
              type="file"
              id="fileUpload"
              onClick={handleLabelClick}
              onChange={handleChangeFile}
              accept="image/*,application/pdf"
            />
          </label>
        )}
        {!fileAttachment && <span className="error">O comprovante é obrigatório</span>}
      </AttachmentDocumentContainer>
    );
  }, [handleChangeFile, handleLabelClick, fileAttachment, handleDeleteFile]);

  return (
    <>
      <GridContainer>
        <div className="one_third">
          <div className="splits">
            <Field
              component={InputMask}
              name={`address.zipcode`}
              label="CEP"
              placeholder="CEP"
              mask="99999-999"
              enableErrors={touched?.zipcode}
            />
          </div>
          <div className="splits">
            <Field component={Input} name={`address.address`} label="Endereço" placeholder="Endereço..." />
          </div>
        </div>
        <div className="one_third">
          <div className="splits">
            <Field component={Input} name={`address.number`} label="Número" placeholder="Número..." />
          </div>
          <div className="splits">
            <Field
              component={Input}
              name={`address.complement`}
              label="Complemento (Opcional)"
              placeholder="Complemento..."
            />
          </div>
        </div>
        <div className="half">
          <div className="splits">
            <Field component={Input} name={`address.district`} label="Bairro" placeholder="Bairro..." />
          </div>
          <div className="splits">
            <Field component={Input} name={`address.city`} label="Cidade" placeholder="Cidade..." />
          </div>
        </div>
        {isChangeable && (
          <ProofOfNameChange>
            <span>Comprovante de alteração do endereço</span>
            <p>
              Anexe aqui o comprovante de alteração do endereço. O documento deve estar no nome da empresa. Exemplos:
              Conta de internet, energia, aluguel...
            </p>
            <DocumentAttachment />
          </ProofOfNameChange>
        )}
      </GridContainer>
    </>
  );
};

export default ModalCompanyProfileAddress;
