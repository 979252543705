/* eslint-disable camelcase */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

declare module 'axios' {
  // eslint-disable-next-line no-shadow
  export interface AxiosRequestConfig {
    handlerEnabled: boolean;
  }
}

export interface IConfigProps {
  api_url: string;
  has_user_header: boolean;
}

export class Config {
  axios: AxiosInstance;

  constructor({ api_url, has_user_header = false }: IConfigProps) {
    if (!api_url) throw new Error('Missing api_url');
    this.axios = this.create(api_url, has_user_header);
  }

  create(apiUrl: string, has_user_header: boolean): AxiosInstance {
    return axios.create({
      baseURL: apiUrl,
      timeout: 1000 * 60 * 15,
      headers: {
        'Content-Type': 'application/json',
        'X-Sgu-Code': localStorage.getItem('@unimedEmpresa/sgu_code') || undefined,
      },
    } as AxiosRequestConfig);
  }

  instance(): AxiosInstance {
    return this.axios;
  }
}

const config = (props: IConfigProps): Config => new Config(props);

export default config;
