import { motion } from 'framer-motion';
import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled(motion.div)``;

export const Content = styled(motion.div)`
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: 0px 16px 40px 3px rgba(24, 28, 50, 0.25);

  overflow: auto;
  position: relative;

  z-index: 3;
`;
