import styled from 'styled-components';
import colors from 'styles/colors';

interface ContainerProps {
  $error?: boolean;
}

interface PlaceHolderContainerProps extends ContainerProps {}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  > span {
    color: ${props => (props.$error ? colors.unimedOrange : colors.title)};
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 8px;
  }

  &.dropzone__with_file {
    > span {
      margin-bottom: 0;
    }
  }
`;

export const PlaceholderContainer = styled.div<PlaceHolderContainerProps>`
  background-color: ${props => (props.$error ? colors.errorWhiter : colors.background)};
  border: 1px solid ${props => (props.$error ? colors.unimedOrange : '#e8ebf1')};
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-left: 24px;
  transition-duration: 0.3s;
  width: 100%;
  height: 48px;
  cursor: pointer;

  span {
    color: ${props => (props.$error ? colors.subtitle : colors.placeholder)};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    strong {
      color: ${colors.unimedGreenStrong};
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 4px;
    }
  }

  svg {
    fill: ${colors.unimedGreenStrong};
    font-size: 28px;
    margin-right: 18px;
  }
`;

export const ContainerUploadAvatar = styled.div`
  margin-bottom: 24px;

  .label {
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: ${colors.body};
  }

  input {
    display: none;
  }

  img {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #ccc;
  }

  .elements {
    display: flex;
    align-items: center;
  }

  button + button {
    &:before {
      position: absolute;
      border-radius: 50%;
      top: 9px;
      left: -16px;
      content: '';
      width: 6px;
      height: 6px;
      background-color: #d0c9d6;
      display: inline-block;
    }
  }

  button {
    position: relative;
    border: none;
    background: transparent;
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: ${colors.unimedOrange};
    margin-left: 25px;
  }
`;
