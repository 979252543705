import { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { Identify, identify } from '@amplitude/analytics-browser';
import StorageEnum from 'enum/Storage';
import { useToast } from 'hooks/toast';
import api from 'services';
import RoutesEnum from 'enum/Routes';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { useCookies } from 'react-cookie';
import LogRocket from 'logrocket';
import { history } from '../../App';

export interface ISignInProps {
  password: string;
  email: string;
  cnpj: string;
}

export interface IForgotProps {
  email: string;
  cnpj: string;
}

export interface IResetProps {
  confirm_password: string;
  password: string;
  request_token: string;
}

interface IAuthData {
  access_token: string | null;
  name: string;
  cnpj: string;
  admin?: boolean;
  permissions?: string[];
  sgu_code?: string;
  roles?: string[];
}
interface IContext {
  loading: boolean;
  authData: IAuthData;
  handleLogin(data: ISignInProps): Promise<void>;
  handleLogout(): Promise<void>;
  handleForgotPassword(data: IForgotProps): Promise<boolean>;
  handleConfirmTokenForgot(token: string): Promise<boolean>;
  handleResetPassword(data: IResetProps): Promise<boolean>;
  can(permission: string): boolean;
  has_role(role: string): boolean;
}

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider: React.FC = ({ children }) => {
  const growthbook = useGrowthBook();
  const [authData, setAuthData] = useState<IAuthData>({ access_token: 'initial' } as IAuthData);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookies, removeCookie] = useCookies(['access_token', 'sgu_code']);

  const { addToast } = useToast();

  const getPermissions = useCallback(async () => {
    const response = await api.auth().permissions();
    return response.data.content.map(c => c.name);
  }, []);

  useEffect(() => {
    if (authData) {
      const event = new Identify();
      event.set('cnpj', authData.cnpj);
      identify(event);

      LogRocket.identify(`${authData.name}-${authData.sgu_code}`, {
        name: authData?.name || '',
        sgu_code: authData?.sgu_code || '',
      });

      if (growthbook) {
        growthbook.setAttributes({
          sgu_code: authData.sgu_code,
          company_code: authData.sgu_code?.split('F')[0],
        });
      }
    }
  }, [authData, authData.cnpj, authData.sgu_code, growthbook]);

  useEffect(() => {
    const tempAccessJson = localStorage.getItem(StorageEnum.dataAuth);
    const tempAccess = tempAccessJson ? JSON.parse(tempAccessJson) : null;
    if (tempAccess && tempAccess.access_token) {
      setCookies('access_token', tempAccess.access_token);
      setAuthData(tempAccess);
    } else {
      setAuthData({
        access_token: null,
      } as IAuthData);
    }
  }, [setCookies]);

  useEffect(() => {
    if (authData.access_token && authData.access_token !== 'initial') {
      localStorage.setItem(StorageEnum.dataAuth, JSON.stringify(authData));
      let sgu_code = authData?.sgu_code || '';

      if (cookies.sgu_code) {
        sgu_code = cookies.sgu_code;
      }

      localStorage.setItem('@unimedEmpresa/sgu_code', sgu_code || '');
      api.instance.defaults.headers.Authorization = `Bearer ${authData.access_token}`;
      setCookies('access_token', authData.access_token);

      if (sgu_code) {
        api.instance.defaults.headers['X-Sgu-Code'] = sgu_code;
        setCookies('sgu_code', sgu_code);
      }

      api
        .auth()
        .validateToken()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setAuthData({
            access_token: null,
          } as IAuthData);
          localStorage.removeItem(StorageEnum.dataAuth);
          removeCookie('access_token');
          removeCookie('sgu_code');
        });
    }
  }, [authData, cookies.sgu_code, removeCookie, setCookies]);

  const handleLogin = useCallback(
    async (data: ISignInProps) => {
      setLoading(true);
      try {
        const response = await api.auth().signIn(data);
        api.instance.defaults.headers.Authorization = `Bearer ${response.data.content.access_token}`;
        setCookies('access_token', response.data.content.access_token);

        if (response.data.content.sgu_code) {
          api.instance.defaults.headers['X-Sgu-Code'] = response.data.content.sgu_code;
          setCookies('sgu_code', response.data.content.sgu_code);
          localStorage.setItem('@unimedEmpresa/sgu_code', authData?.sgu_code || '');
        }

        const permissions = await getPermissions();
        setAuthData({
          ...response.data.content,
          permissions,
        });
        addToast({ type: 'success', title: 'Seja bem-vindo!' });
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'CNPJ e/ou senha inválidos' });
        }
      }
      setLoading(false);
    },
    [addToast, authData?.sgu_code, getPermissions, setCookies],
  );

  const handleForgotPassword = useCallback(
    async (data: IForgotProps) => {
      setLoading(true);
      try {
        const response = await api.auth().forgot(data);
        setLoading(false);
        if (response.data.success) return true;
        return false;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'CNPJ e/ou e-mail inválidos' });
        }
        setLoading(false);
        return false;
      }
    },
    [addToast],
  );

  const handleResetPassword = useCallback(
    async (data: IResetProps) => {
      setLoading(true);
      try {
        const response = await api.auth().reset(data);
        setLoading(false);
        if (response.data.success) return true;
        throw new Error('Não foi possível criar uma nova senha');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possível criar uma nova senha, tente novamente mais tarde',
        });
        setLoading(false);
        return false;
      }
    },
    [addToast],
  );

  const handleConfirmTokenForgot = useCallback(
    async (token: string) => {
      setLoading(true);
      try {
        const response = await api.auth().forgotVerify(token);
        setLoading(false);
        if (response.data.success) return true;
        return false;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Token expirado!' });
        }
        setLoading(false);
        return false;
      }
    },
    [addToast],
  );

  const handleLogout = useCallback(async () => {
    setLoading(true);
    localStorage.removeItem(StorageEnum.dataAuth);
    localStorage.removeItem('@unimedEmpresa/sgu_code');
    removeCookie('access_token');
    removeCookie('sgu_code');
    setAuthData({ access_token: null } as IAuthData);
    window.location.reload();
    history.push(RoutesEnum.LOGIN_ROUTE);
    setLoading(false);
  }, [removeCookie]);

  const can = useCallback(
    (permission: string) => {
      if (authData.permissions) return authData.permissions.includes(permission);
      return false;
    },
    [authData.permissions],
  );

  const has_role = useCallback(
    (role: string) => {
      if (authData.roles) return authData.roles.includes(role);
      return false;
    },
    [authData.roles],
  );

  return (
    <AuthContext.Provider
      value={{
        loading,
        authData,
        handleLogin,
        handleLogout,
        handleForgotPassword,
        handleConfirmTokenForgot,
        handleResetPassword,
        can,
        has_role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IContext {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
