import { uniflex_grace_period, uniplan_grace_period } from 'components/pages/BeneficiariesAssessmentViewer/constants';
import { useBeneficiary } from 'hooks/beneficiary';
import { useToast } from 'hooks/toast';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from 'services';
import { IGraceInfo, IReviewPortability, IContext, PortabilityReviewStatuses } from './interfaces';

const PortabilityContext = createContext<IContext>({} as IContext);

const PortabilityProvider: React.FC = ({ children }) => {
  const { beneficiary } = useBeneficiary();
  const { addToast } = useToast();
  const [planType, setPlanType] = useState<string>(beneficiary.plan_type ?? '');
  const [graceInfo, setGraceInfo] = useState<IGraceInfo[]>(
    planType === 'uniflex' ? uniflex_grace_period : uniplan_grace_period,
  );
  const [changedAccommodation, setChangedAccommodation] = useState<boolean | null>(
    beneficiary.changed_accommodation ?? null,
  );
  const [approved, setApproved] = useState<boolean | undefined>(undefined);
  const [review, setReview] = useState<PortabilityReviewStatuses | undefined>(
    beneficiary.portability_review ?? undefined,
  );
  const [hasPreviousCPT, setHasPreviousCPT] = useState<boolean | null>(beneficiary.has_previous_cpt ?? null);

  const setHasPreviousCPTValue = useCallback((value: boolean): void => {
    setHasPreviousCPT(value);
  }, []);

  const reviewPortability = useCallback(
    async (data: IReviewPortability): Promise<boolean> => {
      try {
        await api.portability().reviewPortability(data);
        return true;
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao avaliar portabilidade',
          description: `Não foi possível avaliar portabilidade: ${String(err)}`,
        });
        return false;
      }
    },
    [addToast],
  );

  const handleSaveGraceInfo = useCallback(
    (data: IGraceInfo): void => {
      const copiedGraceInfo = [...graceInfo];
      const index = copiedGraceInfo.findIndex(info => info.code === data.code);
      copiedGraceInfo[index] = data;
      setGraceInfo(copiedGraceInfo);
    },
    [graceInfo],
  );

  const handleSavePlanType = useCallback((data: string): void => {
    setPlanType(data);
    setGraceInfo(data === 'uniflex' ? uniflex_grace_period : uniplan_grace_period);
  }, []);

  const setPortabilityReview = useCallback(
    (data: PortabilityReviewStatuses): void => {
      setReview(data);

      if (['com_carencias', 'isento'].includes(data)) {
        setApproved(true);
      } else {
        setApproved(false);
      }

      if (data === 'isento') {
        setGraceInfo(
          planType === 'uniflex'
            ? uniflex_grace_period.map(item => ({
                ...item,
                days: 0,
              }))
            : uniplan_grace_period.map(item => ({
                ...item,
                days: 0,
              })),
        );
      }
    },
    [planType],
  );

  useEffect(() => {
    let groups = uniflex_grace_period;
    if (beneficiary.plan_type && ['uniflex', 'uniplan'].includes(beneficiary.plan_type)) {
      setPlanType(beneficiary.plan_type);
      // @ts-ignore
      groups = beneficiary.plan_type === 'uniflex' ? uniflex_grace_period : uniplan_grace_period;
    }

    if (beneficiary.grace_info && beneficiary.grace_info.length > 0) {
      let newGraceInfo = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const beneficiaryGraceItem of beneficiary.grace_info) {
        const foundGroup = groups.find(group => group.codes.includes(Number(beneficiaryGraceItem.code)));

        if (foundGroup) {
          newGraceInfo.push({
            ...foundGroup,
            days: beneficiaryGraceItem.days,
          });
        } else {
          const foundByCode = groups.find(group => group.code === beneficiaryGraceItem.code);
          if (foundByCode) {
            newGraceInfo.push({
              ...foundByCode,
              days: beneficiaryGraceItem.days,
            });
          }
        }
      }

      newGraceInfo = newGraceInfo.filter((item, index, self) => index === self.findIndex(t => t.code === item.code));

      setGraceInfo(newGraceInfo);
    }

    if (beneficiary.portability_review) {
      setReview(beneficiary.portability_review);
    }
  }, [beneficiary]);

  useEffect(() => {
    if (beneficiary) {
      setHasPreviousCPT(beneficiary.has_previous_cpt ?? null);
      setChangedAccommodation(beneficiary.changed_accommodation ?? null);
    }
  }, [beneficiary]);

  return (
    <PortabilityContext.Provider
      value={{
        approved,
        review,
        graceInfo,
        hasPreviousCPT,
        planType,
        reviewPortability,
        handleSaveGraceInfo,
        setPortabilityReview,
        setHasPreviousCPTValue,
        handleSavePlanType,
        changedAccommodation,
        setChangedAccommodation,
      }}
    >
      {children}
    </PortabilityContext.Provider>
  );
};

function usePortability(): IContext {
  const context = useContext(PortabilityContext);
  if (!context) {
    throw new Error('usePortability must be used within a PortabilityProvider');
  }
  return context;
}

export { PortabilityProvider, usePortability };
