import { AxiosInstance } from 'axios';

export class TransferEvaluate {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async listTransferMovementsToValidate(params?: any): Promise<any> {
    return this.instance.get('/api/admin/assessment/transfer', {
      params,
      handlerEnabled: true,
    });
  }

  async sendTransfer(type: string, id: string, data: any): Promise<any> {
    return this.instance.post(`/api/admin/assessment/transfer/${id}/${type}`, data);
  }

  async userTransfer(data: string): Promise<any> {
    return this.instance.get(`/api/admin/movements/transfer/${data}`);
  }
}

const transferEvaluate = (instance: AxiosInstance): TransferEvaluate => new TransferEvaluate(instance);

export default transferEvaluate;
