import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;

  .attachment__pdf {
    background-color: #fff0ee;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 111px;
    height: 76px;
  }

  .attachment__image {
    border-radius: 6px;
    width: 111px;
    height: auto;
  }

  .attachment__name {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 24px;

    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 12px;
    }

    button {
      align-self: flex-start;
      background-color: transparent;
      border: none;
      color: ${colors.unimedOrange};
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .attachment__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      width: 154px;
    }
  }
`;
