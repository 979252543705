import { AxiosInstance, AxiosResponse } from 'axios';

import { IContract, IIndicators, IReadjustment } from 'hooks/contracts';

interface IPaginateResponse {
  has_next: boolean;
  has_prev: boolean;
  next_page: number | null;
  page: number | null;
  per_page: number;
  prev_page: number | null;
  total: number;
}

interface IContractsResponse {
  content: IContract[];
  paginate: IPaginateResponse;
}

interface IIndicatorsResponse {
  content: IIndicators;
}

interface IContractResponse {
  content: IContract;
}

export class Contracts {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  importFile(file: File): Promise<AxiosResponse<IContractsResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.instance.post('/api/contracts', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  get(params?: any): Promise<AxiosResponse<IContractsResponse>> {
    return this.instance.get('/api/contracts', { params, handlerEnabled: false });
  }

  getOne(id?: number): Promise<AxiosResponse<IContractResponse>> {
    return this.instance.get(`/api/contracts/${id}`);
  }

  readjust(readjustments: IReadjustment[]): Promise<AxiosResponse<IContractResponse>> {
    return this.instance.post(`/api/contracts/readjustment`, readjustments);
  }

  getIndicators(params?: any): Promise<AxiosResponse<IIndicatorsResponse>> {
    return this.instance.get(`/api/contracts/indicators`, { params, handlerEnabled: false });
  }

  getIntegerContract(sguCode: string, contract: string): any {
    return this.instance.get(`/api/admin/companies/${sguCode}/contracts/${contract}`);
  }

  postParamtersIntegerContract(sguCode: string, contract: string, params: any): any {
    return this.instance.post(`/api/admin/companies/${sguCode}/contracts/${contract}/parameters`, params);
  }
}

const contracts = (instance: AxiosInstance): Contracts => new Contracts(instance);

export default contracts;
