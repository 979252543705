import { AxiosInstance, AxiosResponse } from 'axios';

interface IResponseSignIn {
  content: {
    pending_correction: number;
  };
  success: boolean;
}

export class Analytics {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  menu(): Promise<AxiosResponse> {
    return this.instance.get<IResponseSignIn>(`/api/analytics/menu`);
  }
}

const analytics = (instance: AxiosInstance): Analytics => new Analytics(instance);

export default analytics;
