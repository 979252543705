import { useCallback, createContext, useState, useContext } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';

export interface IInvoice {
  competencia: string;
  vencimento: string;
  fatura: number;
  tipo?: string;
  status: 'A' | 'V' | 'P';
  valor: string;

  due_date: string;
  competence: string;
  value: string;
}

interface IHighlighted {
  highlighted: IInvoice[];
  nextInvoice: IInvoice;
  lastInvoice: IInvoice;
}

export interface IFilter {
  late: boolean;
  pending: boolean;
  paid: boolean;
  order?: 'ASC' | 'DESC';
}

interface IContext {
  loading: boolean;
  invoices: IInvoice[];
  invoiceHighlighted: IHighlighted;
  getInvoices: (filter?: IFilter, sort?: 'desc' | 'asc') => Promise<boolean>;
  getInvoiceHighlighted: () => Promise<boolean>;
}

const InvoiceContext = createContext<IContext>({} as IContext);

const InvoiceProvider: React.FC = ({ children }) => {
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [invoiceHighlighted, setInvoiceHighlighted] = useState<IHighlighted>({
    highlighted: [] as IInvoice[],
    nextInvoice: {},
    lastInvoice: {},
  } as IHighlighted);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const getInvoices = useCallback(
    async (filter, sort?: 'desc' | 'asc') => {
      try {
        setLoading(true);

        const response = await api.billing().getInvoices({
          ...filter,
          ...(sort && { order: sort.toUpperCase() }),
        });

        setInvoices(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar as faturas' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getInvoiceHighlighted = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.billing().getInvoiceHighlighted();

      setInvoiceHighlighted(response.data.content);

      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar a fatura em destaque' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  return (
    <InvoiceContext.Provider value={{ loading, invoices, invoiceHighlighted, getInvoices, getInvoiceHighlighted }}>
      {children}
    </InvoiceContext.Provider>
  );
};

function useInvoice(): IContext {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error('useInvoice must be used within an InvoiceProvider');
  }
  return context;
}

export { InvoiceProvider, useInvoice };
