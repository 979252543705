import { useState, useEffect } from 'react';

import { Select as SelectMUI, FormControl, FormHelperText, MenuItem } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import _ from 'lodash';
import colors from 'styles/colors';
import MouseOverPopover from 'components/atoms/Popover';
import { Container } from './styles';
import { InputLabel } from '../Input/styled';

interface Option {
  id: string | number;
  label: string | number;
}

type SelectProps = {
  label: string;
  placeholder: string;
  field?: any;
  form?: any;
  maxLength?: number;
  options: Option[];
  enableErrors?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  value?: string;
  defaultValueLabel?: string;
  noBreak?: boolean;
  informate?: boolean;
  informateText?: string;
  customLabel?: any;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    width: 'calc(100% - 20px)',
  },
  label: {
    color: '#14142B',
    fontSize: 18,
    fontWeight: 500,
  },
  container: {
    minHeight: 86,
  },
}));

const DefaultSelect = withStyles(() => ({
  root: {
    width: '100%',
    height: 40,
    background: (props: SelectProps) => (props.readOnly ? '#fff' : '#F7F9FE'),
    border: (props: SelectProps) => (props.readOnly ? '1px solid #fff !important' : '1px solid #F7F9FE !important'),
    borderRadius: '6px !important',
    marginTop: 10,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  focused: {
    border: `1px solid ${colors.unimedGreenStrong} !important`,
    backgroundColor: '#FFFFFF',
  },
  error: {
    border: `1px solid ${colors.unimedOrange} !important`,
    backgroundColor: '#FFEFE3',
  },
  input: {
    color: '#14142B',
    fontSize: 16,
    width: '100%',
    padding: '14px 24px',
    borderRadius: '6px !important',
    '&:focus': {
      background: '#fff',
    },
  },
}))(SelectMUI);

const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  field = {},
  maxLength,
  options,
  form: { errors },
  disabled,
  readOnly,
  enableErrors = true,
  value,
  informate = false,
  informateText,
  customLabel,
  onChange = false,
}) => {
  const classes = useStyles();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (enableErrors) {
      const tmpError = _.get(errors, field.name, null);
      setError(tmpError);
    }
  }, [errors, field, enableErrors]);

  return (
    <Container>
      <FormControl className={`${classes.margin} ${classes.container} select__container`}>
        <InputLabel className={classes.label} style={customLabel || null}>
          {label}{' '}
          {informate && (
            <MouseOverPopover position="bottom" content={informateText || ''} style={{ height: '20px !important' }}>
              <HelpOutlineOutlinedIcon style={{ fontSize: 20, marginLeft: 10, color: '#A0A3BD' }} />
            </MouseOverPopover>
          )}
        </InputLabel>
        {!onChange ? (
          <DefaultSelect
            value={value}
            disabled={disabled}
            {...field}
            displayEmpty
            placeholder={placeholder}
            error={!!error}
            readOnly={readOnly}
            SelectProps={{ maxLength }}
          >
            {options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <span>{option.label}</span>
              </MenuItem>
            ))}
          </DefaultSelect>
        ) : (
          <DefaultSelect
            value={value}
            disabled={disabled}
            {...field}
            displayEmpty
            placeholder={placeholder}
            error={!!error}
            readOnly={readOnly}
            SelectProps={{ maxLength }}
            onChange={onChange}
          >
            {options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <span>{option.label}</span>
              </MenuItem>
            ))}
          </DefaultSelect>
        )}

        {enableErrors ? (
          <FormHelperText error>{error}</FormHelperText>
        ) : (
          <ErrorMessage name={field.name}>{msg => <FormHelperText error>{msg}</FormHelperText>}</ErrorMessage>
        )}
      </FormControl>
    </Container>
  );
};

export default Select;
