import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { format } from 'cnpj';
import { useToast } from 'hooks/toast';
import useDebounce from 'utils/debounce';
import api from 'services';

import { isEmpty } from 'lodash';

export interface IUser {
  cnpj: string;
  cnpjFormatted?: string;
  created_on: Date;
  email: string;
  id: number;
  name: string;
  password: string;
  updated_on?: Date;
  active?: boolean;
  search?: string;
}

export interface IOrderAndPagination {
  page?: number;
  per_page?: number;
  order?: 'ASC' | 'DESC';
  order_by?: string;
  has_next?: boolean;
  next_page?: number | null;
}

interface IContext {
  loading: boolean;
  users: IUser[];
  filters: Partial<IUser>;
  orderAndPagination: IOrderAndPagination;
  getOne(id: number): Promise<IUser | undefined>;
  get(filter?: Partial<IUser>, orderPagination?: IOrderAndPagination): Promise<void>;
  update(user: IUser): Promise<boolean>;
  clearFilter(): void;
  applyFilter(filter: Partial<IUser>): void;
  setOrderAndPagination(data: IOrderAndPagination): void;
  create(user: Partial<IUser>): Promise<boolean>;
}

const UserContext = createContext<IContext>({} as IContext);

const UserProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [orderAndPagination, setOrderAndPaginationInternal] = useState<IOrderAndPagination>({
    page: 1,
    per_page: 20,
  } as IOrderAndPagination);
  const [filters, setFilter] = useState<Partial<IUser>>({} as IUser);
  const [loading, setLoading] = useState(false);

  const filtersDebounced = useDebounce(filters, 200);

  const { addToast } = useToast();

  const setOrderAndPagination = useCallback(
    (orderAndPaginationContent: IOrderAndPagination) => {
      setOrderAndPaginationInternal(state => ({ ...state, ...orderAndPaginationContent }));
    },
    [setOrderAndPaginationInternal],
  );

  const get = useCallback(
    async (filter?: Partial<IUser>, orderPagination?: IOrderAndPagination) => {
      setLoading(true);
      try {
        const response = await api.users().get({ ...filter, ...orderPagination });

        if (orderPagination?.page && orderPagination?.page > 1) {
          setUsers(data => [
            ...data,
            ...response.data.content.map(user => ({
              ...user,
              cnpjFormatted: format(user.cnpj) || user.cnpj,
            })),
          ]);
        } else {
          setUsers(
            response.data.content.map(user => ({
              ...user,
              cnpjFormatted: format(user.cnpj) || user.cnpj,
            })),
          );
        }

        setOrderAndPagination({
          has_next: response.data.paginate.has_next,
          next_page: response.data.paginate.next_page,
        });
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os usuários' });
      }
      setLoading(false);
    },
    [addToast, setUsers, setOrderAndPagination, setLoading],
  );

  const update = useCallback(
    async (user: IUser) => {
      setLoading(true);
      try {
        const { active, ...restParams } = user;
        setUsers(listUsers => {
          return listUsers.map(userFilter => {
            if (userFilter.id === user.id) {
              return { ...userFilter, ...user };
            }

            return userFilter;
          });
        });
        await api.users().update({ ...restParams, active });
        get();
        if (user.active) {
          addToast({ type: 'success', title: 'Usuário ativado com sucesso!' });
        } else if (user.active === false) {
          addToast({ type: 'success', title: 'Usuário inativado com sucesso!' });
        }
        setLoading(false);
        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possivel alterar o usuário' });
        }
        setLoading(false);
        return false;
      }
    },
    [addToast, get, setLoading],
  );

  const create = useCallback(
    async (user: Partial<IUser>) => {
      setLoading(true);
      try {
        await api.users().create(user);
        get();
        addToast({ type: 'success', title: 'Usuário criado com sucesso!' });
        setLoading(false);
        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possivel criar o usuário.' });
        }
        setLoading(false);
        return false;
      }
    },
    [addToast, get, setLoading],
  );

  const getOne = useCallback(
    async (id: number): Promise<IUser | undefined> => {
      setLoading(true);
      try {
        const response = await api.users().getOne(id);
        return response.data.content;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar o usuário' });
      } finally {
        setLoading(false);
      }

      return undefined;
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  const applyFilter = useCallback(
    (applyingFilters: Partial<IUser>) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...applyingFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (isEmpty(filtersDebounced) === false) {
      get(filtersDebounced);
    }
  }, [filtersDebounced, get]);

  return (
    <UserContext.Provider
      value={{
        loading,
        users,
        filters,
        orderAndPagination,
        setOrderAndPagination,
        create,
        get,
        getOne,
        update,
        clearFilter,
        applyFilter,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): IContext {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
