import { useEffect } from 'react';

import { Container } from './styles';

interface IBackdropProps {}

const Backdrop: React.FC<IBackdropProps> = ({ children }) => {
  useEffect(() => {
    document.body.style.height = '80%';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.height = 'initial';
      document.body.style.overflow = 'initial';
    };
  }, []);

  return <Container>{children}</Container>;
};

export default Backdrop;
