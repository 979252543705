import { useCallback, createContext, useState, useContext, useEffect } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';
import { IOrderAndPagination } from 'hooks/user';
import useDebounce from 'utils/debounce';
import { format } from 'cnpj';
import { isEmpty } from 'lodash';

export interface IUserCompany {
  company_name: string;
  proof: File;
}

export interface IUserCompanyAddress {
  id?: number;
  zipcode: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  proof: File;
}

export interface Address {
  address: string;
  city: string;
  complement: string;
  created_on: Date;
  district: string;
  id: number;
  number: string;
  relationship_id: number;
  relationship_type: string;
  status: string;
  updated_on?: any;
  zipcode: string;
}

export interface Contact {
  type: string;
  name: string;
  email: string;
  whatsapp: string;
  phone: string;
  branch: string;
  main?: boolean;
  created_on?: Date;
  id?: number;
  relationship_id?: number;
  relationship_type?: string;
  status?: string;
  updated_on?: any;
}

export interface Company {
  adresses: Address[];
  cnpj: string;
  company_name: string;
  contacts: Contact[];
  fantasy: string;
  id: number;
  image?: string;
  status: string;
}

export interface IFilter {
  search?: string;
}
interface IContext {
  loading: boolean;
  company: Company;
  companies: Company[];
  filters: IFilter;
  orderAndPagination: IOrderAndPagination;
  get(filter?: IFilter, orderPagination?: IOrderAndPagination): Promise<void>;
  getOne(id: number): Promise<boolean>;
  applyFilter(filter?: IFilter): void;
  getUserCompany: () => Promise<boolean>;
  updateUserCompany: (company: IUserCompany) => Promise<boolean>;
  updateUserCompanyAddress: (companyAddress: IUserCompanyAddress) => Promise<boolean>;
  updateContacts: (contact: Contact[]) => Promise<boolean>;
  deleteContact: (contactId: number[]) => Promise<boolean>;
  updateProfile: (file: File) => Promise<boolean>;
  removeProfile: () => Promise<boolean>;
  clearFilter(): void;
  setOrderAndPagination(data: IOrderAndPagination): void;
}

const CompanyContext = createContext<IContext>({} as IContext);

const CompanyProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({} as Company);
  const [filters, setFilter] = useState<IFilter>({} as IFilter);
  const [companies, setCompanies] = useState<Company[]>([]);

  const filtersDebounced = useDebounce(filters, 200);

  const [orderAndPagination, setOrderAndPaginationInternal] = useState<IOrderAndPagination>({
    page: 1,
    per_page: 20,
  } as IOrderAndPagination);

  const { addToast } = useToast();

  const setOrderAndPagination = useCallback(
    (orderAndPaginationContent: IOrderAndPagination) => {
      setOrderAndPaginationInternal(state => ({ ...state, ...orderAndPaginationContent }));
    },
    [setOrderAndPaginationInternal],
  );

  const get = useCallback(
    async (filter?: IFilter, orderPagination?: IOrderAndPagination) => {
      setLoading(true);
      try {
        const response = await api.company().get({ ...filter, ...orderPagination });

        if (orderPagination?.page && orderPagination?.page > 1) {
          setCompanies(data => [
            ...data,
            ...response.data.content.map((companyNew: Company) => ({
              ...companyNew,
              cnpjFormatted: format(companyNew.cnpj) || companyNew.cnpj,
            })),
          ]);
        } else {
          setCompanies(
            response.data.content.map((companyNew: Company) => ({
              ...companyNew,
              cnpjFormatted: format(companyNew.cnpj) || companyNew.cnpj,
            })),
          );
        }

        setOrderAndPagination({
          has_next: response.data.paginate.has_next,
          next_page: response.data.paginate.next_page,
        });
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possivel recuperar os usuários' });
      }
      setLoading(false);
    },
    [addToast, setCompanies, setOrderAndPagination, setLoading],
  );

  const getUserCompany = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.company().getUserCompany();
      setCompany(data.content as Company);

      return true;
    } catch (error) {
      const error_message = error?.response?.data?.error_message;

      if (error_message) {
        addToast({ type: 'error', title: error_message });
      } else {
        addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
      }
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  const getOne = useCallback(
    async (id: number) => {
      try {
        setLoading(true);

        const { data } = await api.company().getOne(id);
        setCompany(data.content as Company);
        setLoading(false);

        return true;
      } catch (error) {
        setLoading(false);
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
        }
        return false;
      }
    },
    [addToast],
  );

  const updateUserCompany = useCallback(
    async (companyUpdate: IUserCompany) => {
      try {
        setLoading(true);

        const formData = new FormData();

        Object.entries(companyUpdate).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await api.company().updateUserCompany(formData);
        addToast({ type: 'success', title: 'Perfil Atualizado' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const updateProfile = useCallback(
    async (file: File) => {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append('image', file);

        await api.company().uploadProfile(formData);
        addToast({ type: 'success', title: 'Foto do perfil atualizada' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar a foto do perfil' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const removeProfile = useCallback(async () => {
    try {
      setLoading(true);
      await api.company().removeProfile();
      addToast({ type: 'success', title: 'Foto do perfil removida' });

      return true;
    } catch (error) {
      const error_message = error?.response?.data?.error_message;

      if (error_message) {
        addToast({ type: 'error', title: error_message });
      } else {
        addToast({ type: 'error', title: 'Não foi possível remover a foto do perfil' });
      }
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  const updateContacts = useCallback(
    async (contacts: Contact[]) => {
      try {
        setLoading(true);

        await Promise.all(
          contacts.map(async contact => {
            const formData = new FormData();

            Object.entries(contact).forEach(([key, value]) => {
              formData.append(key, value);
            });

            if (contact.id) {
              return api.company().updateContact(contact.id, formData);
            }

            return api.company().saveContacts(formData);
          }),
        );

        addToast({ type: 'success', title: 'Contatos Atualizados' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const deleteContact = useCallback(
    async (contactIds: number[]) => {
      try {
        setLoading(true);

        await Promise.all(
          contactIds.map(async contactId => {
            return api.company().removeContact(contactId);
          }),
        );

        // addToast({ type: 'success', title: 'Contatos Atualizados' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const updateUserCompanyAddress = useCallback(
    async (companyUpdate: IUserCompanyAddress) => {
      try {
        setLoading(true);

        const formData = new FormData();

        Object.entries(companyUpdate).forEach(([key, value]) => {
          formData.append(key, value);
        });

        if (companyUpdate.id) {
          await api.company().updateUserCompanyAddress(companyUpdate.id, formData);
        } else {
          await api.company().createUserCompanyAddress(formData);
        }

        addToast({ type: 'success', title: 'Endereço Atualizado' });

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o perfil' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const applyFilter = useCallback(
    (applyingFilters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...applyingFilters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  useEffect(() => {
    if (isEmpty(filtersDebounced) === false) {
      get(filtersDebounced);
    }
  }, [filtersDebounced, get]);

  return (
    <CompanyContext.Provider
      value={{
        loading,
        company,
        companies,
        orderAndPagination,
        filters,
        setOrderAndPagination,
        get,
        getOne,
        applyFilter,
        clearFilter,
        removeProfile,
        updateProfile,
        updateContacts,
        deleteContact,
        updateUserCompany,
        getUserCompany,
        updateUserCompanyAddress,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

function useCompany(): IContext {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('useNps must be used within an NpsProvider');
  }
  return context;
}

export { CompanyProvider, useCompany };
