import { AxiosInstance, AxiosResponse } from 'axios';

import { Notification } from 'hooks/notifications';

interface INotificationsResponse {
  content: Notification[];
}

interface INotificationResponse {
  content: Notification;
}

export class Notifications {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(): Promise<AxiosResponse<INotificationsResponse>> {
    return this.instance.get('/api/notifications');
  }

  markAsViewed(id?: number): Promise<AxiosResponse<INotificationResponse>> {
    return this.instance.put(`/api/notifications/view/${id}`);
  }
}

const notification = (instance: AxiosInstance): Notifications => new Notifications(instance);

export default notification;
