import { AxiosInstance } from 'axios';
import { INewUser } from 'hooks/usersInCompanies/interfaces';

export class Company {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getUserCompany(): Promise<any> {
    return this.instance.get('/api/company');
  }

  updateUserCompany(data: any): Promise<any> {
    return this.instance.put('/api/company', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  get(params: any): Promise<any> {
    return this.instance.get('/api/companies', {
      params,
      handlerEnabled: false,
    });
  }

  getOne(id: number): Promise<any> {
    return this.instance.get(`/api/companies/${id}`);
  }

  saveContacts(data: any): Promise<any> {
    return this.instance.post('/api/company/contact', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  updateContact(id: number, data: any): Promise<any> {
    return this.instance.put(`/api/contacts/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  removeContact(id: number): Promise<any> {
    return this.instance.delete(`/api/contacts/${id}`);
  }

  createUserCompanyAddress(data: any): Promise<any> {
    return this.instance.post('/api/company/address', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  updateUserCompanyAddress(id: number, data: any): Promise<any> {
    return this.instance.put(`/api/address/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  uploadProfile(data: any): Promise<any> {
    return this.instance.post('/api/company/profile', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      handlerEnabled: false,
    });
  }

  removeProfile(): Promise<any> {
    return this.instance.delete('/api/company/profile');
  }

  getUsersInCompany(id: string, page?: string): Promise<any> {
    const params = { per_page: 10, page: page || 1 };
    return this.instance.get(`api/admin/companies/${id}/users`, { params, handlerEnabled: false });
  }

  getRoles(): Promise<any> {
    return this.instance.get(`api/admin/roles`);
  }

  sendRole(id: string, data: any): Promise<any> {
    return this.instance.put(`/api/admin/users/${id}/role`, data);
  }

  getCompany(sguCode: string): Promise<any> {
    return this.instance.get(`/api/admin/companies/${sguCode}`);
  }

  patchCompany(sguCode: string, userId: string, user: any): Promise<any> {
    return this.instance.patch(`/api/admin/companies/${sguCode}/users/${userId}`, user);
  }

  addNewUserInCompany(sguCode: string, data: INewUser): Promise<any> {
    return this.instance.post(`/api/admin/companies/${sguCode}/users`, data);
  }

  getContractsInCompany(sguCode: string): Promise<any> {
    return this.instance.get(`/api/admin/companies/${sguCode}/contracts `);
  }

  getListCompanies(search?: string): Promise<any> {
    const params = { search: search || undefined };
    return this.instance.get('/api/users/companies', { params, handlerEnabled: false });
  }

  setFavorite(sgu_code: string): Promise<any> {
    return this.instance.post('/api/me/companies/favorite', { sgu_code });
  }

  saveParameters(company_id: string, data: any): Promise<any> {
    return this.instance.post(`/api/admin/companies/${company_id}/parameters`, data);
  }

  getParameters(company_id: string): Promise<any> {
    return this.instance.get(`/api/admin/companies/${company_id}/parameters`);
  }
}

const company = (instance: AxiosInstance): Company => new Company(instance);

export default company;
