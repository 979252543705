import { useCallback, createContext, useState, useContext } from 'react';

interface IContext {
  menuOpen: boolean;
  menuDisabled: boolean;
  companyProfileOpen: boolean;
  shouldShowBackButton: boolean;
  title: string;
  backButtonURL: string | undefined;
  setTitle: (title: string) => void;
  setBackURL: (url: string) => void;
  showMenu: () => void;
  hideMenu: () => void;
  disableMenu: () => void;
  enableMenu: () => void;
  showCompanyProfile: () => void;
  hideCompanyProfile: () => void;
  toggleMenu: () => void;
  showBackButton: () => void;
  hideBackButton: () => void;
}

const GlobalContext = createContext<IContext>({} as IContext);

const GlobalProvider: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuDisabled, setDisableMenu] = useState(false);
  const [backButtonURL, setBackButtonURL] = useState<string | undefined>(undefined);
  const [shouldShowBackButton, setShouldShowBackButton] = useState(false);
  const [companyProfileOpen, setCompanyProfileOpen] = useState(false);
  const [title, setTitleState] = useState('');

  const showMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const hideMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const disableMenu = useCallback(() => {
    setDisableMenu(true);
  }, []);

  const enableMenu = useCallback(() => {
    setDisableMenu(false);
  }, []);

  const showCompanyProfile = useCallback(() => {
    setCompanyProfileOpen(true);
  }, []);

  const hideCompanyProfile = useCallback(() => {
    setCompanyProfileOpen(false);
  }, []);

  const setTitle = useCallback(
    (newTitle: string) => {
      setTitleState(newTitle);
    },
    [setTitleState],
  );

  const setBackURL = useCallback((url: string) => {
    setBackButtonURL(url);
  }, []);

  const showBackButton = useCallback(() => {
    setShouldShowBackButton(true);
  }, []);

  const hideBackButton = useCallback(() => {
    setShouldShowBackButton(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(old => !old);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        menuOpen,
        menuDisabled,
        shouldShowBackButton,
        companyProfileOpen,
        title,
        backButtonURL,
        showCompanyProfile,
        hideCompanyProfile,
        showMenu,
        hideMenu,
        disableMenu,
        enableMenu,
        toggleMenu,
        setTitle,
        setBackURL,
        showBackButton,
        hideBackButton,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

function useGlobal(): IContext {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal must be used within an GlobalProvider');
  }
  return context;
}

export { GlobalProvider, useGlobal };
