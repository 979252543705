import { Form } from 'formik';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';

interface FooterProps {
  $menuOpen: boolean;
  $menuHidden?: boolean;
}

export const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 64px;
  width: 100%;
  max-width: 1179px;
  height: 100%;

  &:after {
    content: '';
    display: block;
    height: 64px;
  }

  button {
    &.loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const Subtitle = styled.div`
  h3 {
    color: ${colors.subtitle};
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  hr {
    background-color: ${colors.grayLight};
    border: none;
    margin-top: 16px;
    margin-bottom: 24px;
    width: 100%;
    height: 1px;
  }
`;

export const FormStyled = styled(Form)`
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 42px;
  padding: 24px;

  .c5 {
    width: 21%;
  }

  input {
    font-size: 14px;
    padding: 6px 24px;
  }

  .title__small {
    font-size: 14px;
    color: ${colors.title};
    padding-left: 4px;
    font-weight: 500;
    padding-bottom: 24px;
    display: block;
  }

  .spacer {
    margin: 24px 0px;
  }
`;

export const HolderFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .warn {
    color: ${colors.subtitle};
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin: 10px;
    margin-top: -26px;
  }

  @media (max-width: 800px) {
    display: block;
  }
`;

export const ProofOfEmploymentContainer = styled.div`
  margin: 24px 0;

  > span {
    color: ${colors.title};
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;
  }

  > p {
    color: ${colors.subtitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const PlanInformationFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  margin-bottom: 24px;

  @media (max-width: 800px) {
    display: block;
  }

  .checkbox__container {
    margin-left: 24px;
    margin-bottom: 32px;
  }

  > div {
    max-width: 500px;
  }

  @media (max-width: 1024px) {
    > div {
      max-width: 325px;
    }
  }
`;

export const Icon = styled.div`
  position: relative;
  display: inline-block;
  top: 3px;
  left: -10px;
  svg {
    margin-left: 18px;
  }
`;

export const DependentsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .button__add_dependent {
    background-color: ${colors.unimedGreenStrong};
    border-radius: 6px;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 40px;

    &.with_dependents {
      margin-top: 24px;
    }

    > svg {
      margin-right: 8px;
    }
  }
`;

export const DependentsFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .dependent__fields {
    align-items: flex-end;
    display: grid;
    grid-template-columns: 6fr 1fr 1fr min-content;
    grid-gap: 24px;

    & + .dependent__fields {
      margin-top: 24px;
      border-top: 1px solid #f5f5f5;
      padding-top: 24px;
    }

    .immediate-checkbox {
      display: flex;
      align-items: center;
      margin-top: -25px;
      margin-left: 10px;

      .info {
        margin-left: 5px;
        margin-top: 3px;
      }

      .checkbox__container {
        align-self: flex-start;
        flex: 0;
        white-space: nowrap;
      }
    }

    @media (max-width: 800px) {
      display: block;
    }

    .input__container,
    .select__container {
      margin: 0;
      width: auto;
      min-height: auto;
    }

    .button__trash {
      background-color: ${colors.placeholder};
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 68px;
      height: 38px;
    }
  }
`;

export const Footer = styled.footer<FooterProps>`
  background-color: ${colors.white};
  border-top: 1px solid ${colors.grayLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition-duration: 0.3s;

  position: fixed;
  bottom: 0;
  right: 0;
  left: ${props => (props.$menuOpen ? '240px' : '65px')};

  width: ${props => (props.$menuOpen ? 'calc(100vw - 240px)' : 'calc(100vw - 65px)')};
  height: 64px;

  ${props =>
    props.$menuHidden &&
    css`
      left: 0;
      width: 100%;
    `}

  @media (max-width: 700px) {
    left: ${props => (props.$menuOpen ? 'calc(100vw - 24px)' : '0px')};
    width: 100%;
  }

  > button,
  > a {
    color: ${colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 6px;
    width: 120px;
    height: 40px;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.button__cancel {
      background-color: ${colors.placeholder};
      margin-left: 38px;
    }

    &.button__submit,
    &.button__danger {
      background-color: ${colors.unimedOrange};
      margin-right: 38px;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &.button__danger {
      background-color: ${colors.error};
    }
  }
`;

export const AttachmentDocumentContainer = styled.div`
  margin-top: 16px;

  .error {
    color: #f44336;
    font-size: 0.75rem;
  }

  label {
    cursor: pointer;
    background-color: ${colors.unimedGreenStrong};
    border-radius: 6px;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 250px;
    height: 40px;

    > svg {
      margin-right: 8px;
    }

    input {
      display: none;
    }
  }
`;
