import { useAuth } from 'hooks/auth';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RoutesEnum from 'enum/Routes';
import Button from 'components/atoms/Button';
import { Container } from './styles';

import deniedAccess from '../../../assets/imgs/denied-access.svg';

const DeniedAccess: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { authData, handleLogout } = useAuth();

  useEffect(() => {
    if (location.pathname === '/' && authData.permissions?.includes('admin.gestao.empresas'))
      history.push(RoutesEnum.ADMIN);
    if (location.pathname === '/admin' && authData.permissions?.includes('usuario_cliente'))
      history.push(RoutesEnum.INDEX_ROUTE);
  }, [location.pathname, authData.permissions, history]);

  return (
    <Container>
      <div className="center">
        <img alt="acesso negado" src={deniedAccess} />
        <h2>Acesso negado!</h2>
        <p>Desculpe, você não tem permissão para acessar esta página!</p>
        <p>Entre em contato com administrador para obter acesso ou volte para encontrar outras páginas!</p>
        <Button type="button" size="md" buttonText="Voltar para onde estava" onClick={() => window.history.go(-1)} />
        <Button type="button" variant="link-secondary" size="md" buttonText="Sair do portal" onClick={handleLogout} />
      </div>
    </Container>
  );
};

export default DeniedAccess;
