import { createContext, useCallback, useContext, useState } from 'react';

import { track } from '@amplitude/analytics-browser';

import { useToast } from 'hooks/toast';
import api from 'services';
import { Paginate } from 'hooks/nps';
import { debounce } from 'lodash';
import axios, { AxiosError } from 'axios';

export interface IFilter {
  type?: '' | 'RECIPIENT' | 'DEPENDENT';
  action?: '' | 'I' | 'E' | 'T';
  search?: string;
  status?: string[];
  startDate?: Date;
  endDate?: Date;
  worker_registration?: string;
  contract?: string;
  recipient_code?: string;
  order_by?: 'action' | 'cnpj' | 'created_on' | 'id' | 'name' | 'type';
  order?: 'ASC' | 'DESC';
}

export interface GrauDependencia {
  contratoId: string;
  descricao: string;
  grauDependenciaId: string;
  id: string;
}
export interface SguBeneficiary {
  ativo: boolean;
  codigoBeneficiario: string;
  codigoContrato: string;
  codigo_beneficiario: string;
  codigo_carteirinha: string;
  cpf: string;
  grauDependencia: GrauDependencia;
  kinship: string;
  matriculaColaborador: string;
  name: string;
  nomeBeneficiario: string;
  worker_registration: string;
  plan_code: string;
  plan_description: string;
  email: string;
}

export interface ExcludingBeneficiaryDisapproval {
  created_on: string;
  description: string;
  exclusion_id: string;
  field: string;
  id: number;
  resolved: boolean;
  updated_on: string;
  user_id: number;
}

export interface ExcludingBeneficiary {
  beneficiary_id: string;
  beneficiary_type: 'recipient' | 'dependent';
  cnpj: string;
  code_beneficiary: string;
  company_name: string;
  company_reason: string | null;
  contract: string;
  cpf: string;
  created_on: string;
  date: string;
  death_date: string | null;
  death_reason: string | null;
  death_reason_details: string | null;
  disapprovals: ExcludingBeneficiaryDisapproval[];
  email: string;
  exclusion_type: number;
  id: number;
  informed: string | null;
  informed_date: string | null;
  keep_plan: string;
  name: string;
  phone: string;
  plan_description: string;
  reason: string;
  retired_working: string | null;
  send_communication_type: string;
  sgu_code: string;
  status: string;
  taxpayer: string;
  taxpayer_month: string;
  updated_on: string;
  user_id: number;
  validated: boolean;
  validated_at: string;
}

export interface ErrorHistoryItem {
  id: number;
  message: string;
  movement_payload?: string;
  movement_type: string;
  relationship_id: number;
  relationship_type: string;
  resolved: boolean;
  created_on: Date;
  updated_on: Date;
}

export interface BeneficiariesSgu {
  content: SguBeneficiary[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface ExportErrorsSgu {
  content: ErrorHistoryItem[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface ISguRemove {
  cpf: string;
  cnpj: string;
  email: string;
  phone: string;
  exclusion_type: string;
  code_beneficiary: string;
  contract: string;
  reason: string;
  date: Date | string;
  taxpayer?: null | string;
  taxpayer_month?: null | number;
  death_reason?: string;
  death_date?: Date | string;
  company_reason?: string;
  keep_plan?: string;
  send_communication_type?: string;
  retired_working?: string;
  informed_date?: Date | string;
  informed?: string;
  validated?: boolean | null;
  name: string;
  sgu_code?: string;
  beneficiary_type: string;
}
interface RemoveFiles {
  type: string;
  file: File;
}
interface IContext {
  loading: boolean;
  filters: IFilter;
  excludingBeneficiary: ExcludingBeneficiary;
  beneficiaries: BeneficiariesSgu;
  qtyBeneficiariesPages: number;
  edit: BeneficiariesSgu;
  get: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  getEdit: (filter?: IFilter, paginate?: Partial<Paginate>, append?: boolean) => Promise<boolean>;
  remove: (remove: ISguRemove, files: RemoveFiles[]) => Promise<boolean>;
  getExclude: (excludeId: string) => Promise<boolean>;
  applyFilter: (filter: IFilter) => boolean;
  clearFilter: () => void;
  clear: () => void;
  clearEdit: () => void;
  changePage: (page: number) => void;
}

const emptySguBeneficiaries = {
  content: [] as SguBeneficiary[],
  paginate: {
    page: 0,
  },
} as BeneficiariesSgu;

const SguContext = createContext<IContext>({} as IContext);

const SguProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [excludingBeneficiary, setExcludingBeneficiary] = useState<ExcludingBeneficiary>({
    name: '',
  } as ExcludingBeneficiary);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiariesSgu>({ ...emptySguBeneficiaries });
  const [edit, setEdit] = useState<BeneficiariesSgu>({ ...emptySguBeneficiaries });
  const [qtyBeneficiariesPages, setQtyBeneficiariesPages] = useState<number>(1);

  const { addToast } = useToast();

  const clear = useCallback(() => {
    setBeneficiaries({ ...emptySguBeneficiaries });
  }, []);

  const clearEdit = useCallback(() => {
    setEdit({ ...emptySguBeneficiaries });
  }, []);

  const calculateTotalPages = (total: number, perPage: number): number => {
    const totalPages = Math.floor(total / perPage);
    if (total % perPage === 0) return totalPages;
    return totalPages + 1;
  };

  const get = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.recipient().sgu({
            order_by: filtering?.order_by,
            name: filtering?.search || undefined,
            worker_registration: filtering?.worker_registration || undefined,
            order: filtering?.order,
            page: paginate?.page,
            per_page: paginate?.per_page,
          })
        ).data;

        setBeneficiaries(response);
        setQtyBeneficiariesPages(calculateTotalPages(response.paginate.total, response.paginate.per_page));

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter os dados do SGU' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setBeneficiaries],
  );

  const getEdit = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>, append?: boolean): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.recipient().sgu({
            order_by: filtering?.order_by,
            name: filtering?.search || undefined,
            worker_registration: filtering?.worker_registration || undefined,
            recipient_code: filtering?.recipient_code || undefined,
            contract: filtering?.contract || undefined,
            order: filtering?.order,
            page: paginate?.page,
            per_page: paginate?.per_page,
          })
        ).data;

        if (response.paginate.page === 1 && !append) {
          setEdit(response);
        } else {
          setEdit(oldHistories => ({
            ...response,
            content: [...oldHistories.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter os dados do SGU' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setEdit],
  );

  const remove = useCallback(
    async (removeData: ISguRemove, files: RemoveFiles[]): Promise<boolean> => {
      try {
        setLoading(true);

        const response = await api.recipient().sguExclude({
          ...removeData,
          status: undefined,
        });

        await Promise.all(
          files.map(file => {
            const formData = new FormData();
            formData.append('type', file.type);
            formData.append('relationship_type', 'exclude_beneficiary');
            formData.append('relationship_id', response.data.content.id);
            formData.append('file', file.file);

            return api.recipient().createDocument(formData);
          }),
        );

        track('Beneficiário enviado para exclusão');
        addToast({ type: 'success', title: 'Beneficiário enviado para exclusão com sucesso!' });

        setLoading(false);
        return true;
      } catch (error) {
        let message = 'Não foi possivel o envio de exclusão do SGU';

        if (axios.isAxiosError(error)) {
          const { response } = error as AxiosError;
          if (response && response.data.error_message) {
            message = response.data.error_message;
          }
        }

        addToast({ type: 'error', title: message });
        setLoading(false);
        return false;
      }
    },
    [addToast],
  );

  const getExclude = useCallback(
    async (excludeId: string): Promise<boolean> => {
      try {
        setLoading(true);
        const response = await api.recipient().getSguExclude(excludeId);
        setExcludingBeneficiary(response.data.content);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter as informações do beneficiário.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyFilter = useCallback(
    (debounce((filters: IFilter) => {
      try {
        // setLoading(true);
        setBeneficiaries(response => ({
          ...response,
          paginate: {
            ...response.paginate,
            page: 1,
          },
        }));
        setFilter(old => ({ ...old, ...filters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        // setLoading(false);
      }
    }, 300) as unknown) as (filters: IFilter) => boolean,
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter(() => ({}));
  }, []);

  const changePage = useCallback(
    (page: number) => {
      setBeneficiaries(oldHistories => ({
        ...oldHistories,
        paginate: { ...oldHistories.paginate, page },
      }));
    },
    [setBeneficiaries],
  );

  return (
    <SguContext.Provider
      value={{
        loading,
        excludingBeneficiary,
        beneficiaries,
        qtyBeneficiariesPages,
        edit,
        filters: filter,
        get,
        getEdit,
        applyFilter,
        clearFilter,
        changePage,
        clear,
        clearEdit,
        getExclude,
        remove,
      }}
    >
      {children}
    </SguContext.Provider>
  );
};

function useSgu(): IContext {
  const context = useContext(SguContext);
  if (!context) {
    throw new Error('useSgu must be used within an SguProvider');
  }
  return context;
}

export { SguProvider, useSgu };
