import { rgba } from 'polished';
import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  max-width: 882px;
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  margin: 16px 0;
  padding: 16px 0;
  max-height: 80vh;
  overflow: auto;

  &.content_2 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.75px;
    color: #4e4b66;
    padding: 25px;
  }

  .error__no_pending {
    text-align: center;
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;

  [class^='MuiButtonBase-root'] {
    width: 166px;
  }

  &.resizable {
    [class^='MuiButtonBase-root'] {
      width: auto;
    }
  }

  [class^='MuiButtonBase-root'][variant='secondary'] {
    margin-left: 24px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;

  & + .error__container {
    margin-top: 32px;
  }
`;

export const ErrorTitle = styled.p`
  background-color: ${rgba(colors.unimedOrange, 0.1)};
  color: ${colors.unimedOrange};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 16px;
  padding-left: 24px;
  text-transform: uppercase;
  width: 100%;
  height: 32px;
`;

export const ErrorMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;

  & + .error__message {
    margin-top: 16px;
  }

  span {
    color: ${colors.title};
    font-size: 14px;
    font-weight: 500;
    margin-left: 16px;
  }

  svg {
    fill: ${colors.unimedOrange};
    font-size: 24px;
  }
`;
