import { AxiosInstance } from 'axios';

export class Inclusion {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  patch(beneficiary_type: 'recipient' | 'dependent', beneficiary_id: string, body: any): Promise<any> {
    return this.instance.patch(`/api/admin/movements/inclusion/${beneficiary_type}/${beneficiary_id}`, body, {
      handlerEnabled: false,
    });
  }
}

const inclusion = (instance: AxiosInstance): Inclusion => new Inclusion(instance);

export default inclusion;
