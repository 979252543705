/* eslint-disable react/no-array-index-key */
import { Field, FieldArray, ArrayHelpers } from 'formik';

import Input from 'components/atoms/Input';
import InputMask from 'components/atoms/InputMask';
import Select from 'components/atoms/Select';
import { GridContainer, Scrollable } from './style';

import Button from '../../atoms/Button';

interface AccountableProps {
  index: number;
  arrayHelpers: ArrayHelpers;
}

export const OPTIONS = [
  { id: 'tudo', label: 'Tudo' },
  { id: 'movimentacao', label: 'Movimentação' },
  { id: 'comunicado', label: 'Comunicado' },
  { id: 'financeiro', label: 'Financeiro' },
];

const Accountable: React.FC<AccountableProps> = ({ index, arrayHelpers }) => {
  return (
    <GridContainer>
      <div className="half">
        <div className="splits">
          <Field
            component={Select}
            name={`accountables.${index}.type`}
            label="Responsável por"
            placeholder="Responsável por..."
            options={OPTIONS}
          />
        </div>
        <div className="splits">
          <Field
            component={Input}
            name={`accountables.${index}.name`}
            label="Nome do responsável"
            placeholder="Nome do responsável..."
          />
        </div>
      </div>
      <Field
        component={Input}
        name={`accountables.${index}.email`}
        label="E-mail do responsável"
        type="email"
        placeholder="E-mail do responsável..."
      />
      <div className="half">
        <div className="splits">
          <Field
            component={InputMask}
            mask="(99) 99999-9999"
            name={`accountables.${index}.whatsapp`}
            label="Whatsapp (Opcional)"
            placeholder="(00) 00000-0000"
          />
        </div>
        <div className="splits">
          <Field
            component={InputMask}
            mask="(99) 99999-9999"
            name={`accountables.${index}.phone`}
            label="Telefone"
            placeholder="(00) 00000-0000"
          />
        </div>
        <div className="splits">
          <Field
            component={Input}
            name={`accountables.${index}.branch`}
            label="Ramal (Opcional)"
            placeholder="Ramal..."
          />
        </div>
      </div>
      {index !== 0 && (
        <Button
          onClick={() => arrayHelpers.remove(index)}
          variant="link-danger"
          resizable
          size="md"
          buttonText="Excluir responsável"
        />
      )}
      <hr />
    </GridContainer>
  );
};

interface ModalCompanyProfileAccountablesProps {
  values: any;
  initialValues: any;
}

const ModalCompanyProfileAccountables: React.FC<ModalCompanyProfileAccountablesProps> = ({ values }) => {
  return (
    <Scrollable>
      <FieldArray
        name="accountables"
        render={arrayHelpers => {
          return (
            <>
              {values.accountables.map((_: any, index: number) => (
                <Accountable key={`accountable-${index}`} index={index} arrayHelpers={arrayHelpers} />
              ))}
              <Button
                onClick={() => arrayHelpers.push({})}
                variant="link-secondary"
                resizable
                size="md"
                buttonText="Add outro responsável"
              />
            </>
          );
        }}
      />
    </Scrollable>
  );
};

export default ModalCompanyProfileAccountables;
