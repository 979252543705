import { createContext, useCallback, useContext, useState } from 'react';
import { useToast } from '../toast';
import api from '../../services';

export interface DeathReason {
  value: number;
  description: string;
}

export interface DismissalReason {
  id: string;
  description: string;
  inactivation_date: string | null;
  restricted: boolean;
  sequence: number;
}

interface IDimissalReasons {
  convertion: string;
  created_on: string;
  description: string;
  id: string;
  updated_on: any;
  value: string;
}

interface IContext {
  loading: boolean;
  deathReasons: DeathReason[];
  dismissalReasons: DismissalReason[];
  dismissalReasonsList: IDimissalReasons[];
  getDeathReasons: () => Promise<boolean>;
  getDismissalReasons: () => Promise<boolean>;
  getDismissalReasonsList: () => Promise<any>;
}

const ReasonContext = createContext<IContext>({} as IContext);

const ReasonProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [deathReasons, setDeathReasons] = useState<DeathReason[]>([]);
  const [dismissalReasons, setDismissalReasons] = useState<DismissalReason[]>([]);
  const [dismissalReasonsList, setDismissalReasonsList] = useState<IDimissalReasons[]>([]);

  const { addToast } = useToast();

  const getDeathReasons = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.reason().getDeathReasons();
      setDeathReasons(response.data.content);
      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os motivos das mortes.' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setDeathReasons]);

  const getDismissalReasons = useCallback(async () => {
    try {
      setLoading(true);
      const response = api.reason().getDismissalReasons();
      setDismissalReasons(response);
      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os motivos de demissão' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setDismissalReasons]);

  const getDismissalReasonsList = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.reason().getDismissalReasonsList();
      setDismissalReasonsList(data?.content);
    } catch (error) {
      throw addToast({ type: 'error', title: 'Não foi possível buscar os motivos de demissão' });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  return (
    <ReasonContext.Provider
      value={{
        loading,
        deathReasons,
        dismissalReasons,
        getDeathReasons,
        getDismissalReasons,
        getDismissalReasonsList,
        dismissalReasonsList,
      }}
    >
      {children}
    </ReasonContext.Provider>
  );
};

function useReasons(): IContext {
  const context = useContext(ReasonContext);
  if (!context) {
    throw new Error('useReasons must be used within an ReasonProvider');
  }
  return context;
}

export { ReasonProvider, useReasons };
