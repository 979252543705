import { AxiosInstance, AxiosResponse } from 'axios';

export class Sales {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getPendingSales(): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/sales/pending`);
  }

  finishSale(id: string, data: any): Promise<AxiosResponse> {
    return this.instance.post(`/api/admin/sales/${id}/finish`, data);
  }
}

const sales = (instance: AxiosInstance): Sales => new Sales(instance);

export default sales;
