import { AxiosInstance, AxiosResponse } from 'axios';

export type Contract = {
  contract_id: string;
  plan_code: string;
  plan_description: string;
  validity: string;
};

type ContractsResponse = {
  content: Contract[];
};

export class Transfer {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async listFiliaisPlan(code: string): Promise<AxiosResponse<ContractsResponse>> {
    return this.instance.get<ContractsResponse>(`/api/companies/${code}/contracts`);
  }

  async listFiliais(code: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/companies/code/${code}`);
  }

  async user(code: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/beneficiaries/integration/${code}`);
  }

  async submitTransfer(data: any): Promise<AxiosResponse> {
    return this.instance.post(`/api/movement/transfer`, data, { handlerEnabled: false });
  }

  async submitCorrection(movement_id: string): Promise<AxiosResponse> {
    return this.instance.post(`/api/movement/transfer/${movement_id}/correct`, {}, { handlerEnabled: false });
  }

  async getCostCenter(data: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/lookups/cost-center/${data}`);
  }

  async getCorrectionTransfer(data: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/movement/transfer/${data}`);
  }

  async getApprovedTransfer(transfer_id: string): Promise<AxiosResponse> {
    return this.instance.get(`/api/assessments/transfer/${transfer_id}/disapprovals`);
  }
}

const transfer = (instance: AxiosInstance): Transfer => new Transfer(instance);

export default transfer;
