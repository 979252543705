import { Field } from 'formik';

import { useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import PreviewUpload from 'components/atoms/PreviewUpload';
import DropzoneAvatar from 'components/atoms/Dropzone/avatar';
import Input from 'components/atoms/Input';
import colors from 'styles/colors';
import { useCompany } from 'hooks/company';
import { ProofOfNameChange } from './style';
import { AttachmentDocumentContainer } from '../HealthPlanBeneficiaryNew/styles';

interface ModalCompanyProfileIdentificationProps {
  fileAttachment: File | null;
  setFile: (file: File | null) => void;
  values: any;
  initialValues: any;
}

const ModalCompanyProfileIdentification: React.FC<ModalCompanyProfileIdentificationProps> = ({
  fileAttachment,
  setFile,
  initialValues,
  values,
}) => {
  const { getUserCompany, updateProfile, removeProfile } = useCompany();

  useEffect(() => {
    getUserCompany();
  }, [getUserCompany]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleLabelClick = useCallback(() => {
    if (inputFileRef.current) inputFileRef.current.value = '';
  }, []);

  const handleChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileUpload = e.target.files ? e.target.files[0] : null;
      if (fileUpload) setFile(fileUpload);
    },
    [setFile],
  );

  const handleDeleteFile = useCallback(() => {
    setFile(null);
  }, [setFile]);

  const DocumentAttachment = useCallback(() => {
    return (
      <AttachmentDocumentContainer>
        {fileAttachment ? (
          <PreviewUpload file={fileAttachment} deleteFile={handleDeleteFile} />
        ) : (
          <label htmlFor="fileUpload">
            <FaPaperclip size={16} color={colors.white} />
            <span>Anexar documento</span>
            <input
              ref={inputFileRef}
              type="file"
              id="fileUpload"
              onClick={handleLabelClick}
              onChange={handleChangeFile}
              accept="image/*,application/pdf"
            />
          </label>
        )}
        {!fileAttachment && <span className="error">O comprovante é obrigatório</span>}
      </AttachmentDocumentContainer>
    );
  }, [handleChangeFile, handleLabelClick, fileAttachment, handleDeleteFile]);

  return (
    <>
      <Field
        component={DropzoneAvatar}
        enableErrors={false}
        accept="image/*"
        label="Sua logo"
        name="image"
        onRemoveFile={() => {
          removeProfile().then(() => getUserCompany());
        }}
        onDropFile={async (file: File) => {
          updateProfile(file).then(() => getUserCompany());
        }}
      />
      <Field component={Input} name="company_name" label="Razão Social" placeholder="Razão Social" />
      {initialValues.company_name !== values.company_name && (
        <ProofOfNameChange>
          <span>Comprovante de alteração de Razão Social</span>
          <p>
            Anexe aqui o comprovante de alteração do CNPJ ou Razão Social. Exemplos: Contrato Social ou Alteração
            Social.
          </p>
          <DocumentAttachment />
        </ProofOfNameChange>
      )}
    </>
  );
};

export default ModalCompanyProfileIdentification;
