import { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useToast } from 'hooks/toast';
import api from 'services';
import { useCookies } from 'react-cookie';

export interface ICompany {
  cnpj: string;
  company_code: string;
  company_name: string;
  created_on: string;
  fantasy: string;
  id: number;
  is_branch_company: boolean;
  sgu_code: string;
  status: string;
  updated_on: string;
  is_favorite: boolean;
  current_lives: number;
  billing_type: 'grouping' | 'cost_center';
}

interface IContext {
  sguCode: string;
  handleSelectCompany: (_: any, value: ICompany) => void;
  getListCompanies: (search?: string) => Promise<void>;
  listCompanies: ICompany[];
  setSguCode: (sguCode: string) => void;
  loading: boolean;
  setCompanyFavorite: (sguCode: string) => void;
  selectedCompany: ICompany | undefined;
}

const SelectCompaniesContext = createContext<IContext>({} as IContext);

const SelectCompaniesProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookies, removeCookie] = useCookies(['sgu_code']);
  const [sguCode, setSguCode] = useState<string>(() => {
    const loaded_sgu_code = localStorage.getItem('@unimedEmpresa/sgu_code');
    if (loaded_sgu_code) {
      return loaded_sgu_code;
    }
    return '';
  });
  const [listCompanies, setListCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { addToast } = useToast();

  const handleSelectCompany = useCallback(
    (_: any, value: ICompany): void => {
      if (value && !!value.sgu_code) {
        localStorage.setItem('@unimedEmpresa/sgu_code', value.sgu_code);
        api.instance.defaults.headers['X-Sgu-Code'] = value.sgu_code;
        setSguCode(value.sgu_code);
        setCookies('sgu_code', value.sgu_code);
      }
      // else {
      //   delete api.instance.defaults.headers['X-Sgu-Code'];
      //   setSguCode('');
      //   localStorage.removeItem('@unimedEmpresa/sgu_code');
      // }
      // window.location.reload(false);
    },
    [setCookies],
  );

  const getListCompanies = useCallback(
    async (search?: string): Promise<void> => {
      try {
        setLoading(true);
        const response = await api.company().getListCompanies(search);
        setListCompanies(response.data.content);
      } catch {
        addToast({ type: 'error', title: 'Erro ao carregar a lista de empresas' });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const setCompanyFavorite = useCallback(
    // eslint-disable-next-line no-shadow
    async (sguCode: string): Promise<void> => {
      try {
        await api.company().setFavorite(sguCode);
        await getListCompanies();
        addToast({ type: 'success', title: 'Empresa favotirada com sucesso' });
      } catch {
        addToast({ type: 'error', title: 'Erro ao favoritar empresa' });
      }
    },
    [addToast, getListCompanies],
  );

  const selectedCompany = useMemo(() => listCompanies.find(company => company.sgu_code === sguCode), [
    listCompanies,
    sguCode,
  ]);

  useEffect(() => {
    const loaded_sgu_code = localStorage.getItem('@unimedEmpresa/sgu_code');
    if (loaded_sgu_code) {
      api.instance.defaults.headers['X-Sgu-Code'] = loaded_sgu_code;
      setSguCode(loaded_sgu_code);
    }
  }, []);

  return (
    <SelectCompaniesContext.Provider
      value={{
        loading,
        sguCode,
        handleSelectCompany,
        getListCompanies,
        listCompanies,
        setSguCode,
        setCompanyFavorite,
        selectedCompany,
      }}
    >
      {children}
    </SelectCompaniesContext.Provider>
  );
};

function useSelectCompanies(): IContext {
  const context = useContext(SelectCompaniesContext);
  if (!context) {
    throw new Error('useSelectCompanies must be used within an UserProvider');
  }
  return context;
}

export { SelectCompaniesProvider, useSelectCompanies };
