import { useCallback, useRef } from 'react';
import { DropzoneOptions } from 'react-dropzone';

import { isArray } from 'lodash';
import { ContainerUploadAvatar } from './styles';

import user from '../../../assets/imgs/user.svg';

interface Props extends DropzoneOptions {
  label: string;
  field?: any;
  form?: any;
  onDropFile?: (file: File) => Promise<void>;
  onRemoveFile?: (documentId: string) => Promise<void>;
  enableErrors: boolean;
  view?: boolean;
}

const DropzoneAvatar: React.FC<Props> = ({ label, field, form, onDropFile, onRemoveFile, accept }) => {
  const uploadRef = useRef(null);

  const onDrop = useCallback(
    async (file: File[]) => {
      form.setFieldValue(field.name, { id: null, file: file[0] });

      if (onDropFile) await onDropFile(file[0]);
    },
    [form, field.name, onDropFile],
  );

  const handleRemoveFile = useCallback(async () => {
    form.setFieldValue(field.name, null);

    if (onRemoveFile) await onRemoveFile(field.value.fileId);
  }, [form, field, onRemoveFile]);

  const selectFile = (): void => {
    if (uploadRef && uploadRef.current) {
      // @ts-ignore: Object is possibly 'null'.
      uploadRef.current.click();
    }
  };

  return (
    <ContainerUploadAvatar>
      <div className="label">{label}</div>
      <input
        onChange={(e): void => {
          if (e.target.files) onDrop([e.target.files[0]]);
        }}
        type="file"
        accept={isArray(accept) ? accept.join(',') : accept}
        ref={uploadRef}
      />
      <div className="elements">
        <img src={field?.value?.url || user} alt="logo" />
        <div className="upload_actions">
          {field?.value?.url && (
            <button type="button" onClick={selectFile}>
              Enviar outra logo
            </button>
          )}
          {field?.value?.url && (
            <button type="button" onClick={handleRemoveFile}>
              Excluir Logo
            </button>
          )}
          {!field?.value?.url && (
            <button type="button" onClick={selectFile}>
              Enviar logo
            </button>
          )}
        </div>
      </div>
    </ContainerUploadAvatar>
  );
};

export default DropzoneAvatar;
