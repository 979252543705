import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class TrackMoviments {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getInclusion(params?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/movements/inclusion`, { params } as AxiosRequestConfig);
  }

  async getInclusionAdmin(params?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/movements/inclusion`, { params } as AxiosRequestConfig);
  }

  async CSVInclusion(): Promise<AxiosResponse> {
    return this.instance.post(`/api/movements/inclusion/export`, { responseType: 'blob' });
  }

  async getExclusion(params?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/movements/exclusion`, { params } as AxiosRequestConfig);
  }

  async getExclusionAdmin(params?: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/admin/movements/exclusion`, { params } as AxiosRequestConfig);
  }

  async CSVExclusion(): Promise<AxiosResponse> {
    return this.instance.post(`/api/movements/exclusion/export`, { responseType: 'blob' });
  }

  async getHistoric(params: any): Promise<AxiosResponse> {
    return this.instance.get(`/api/movements/history`, { params } as AxiosRequestConfig);
  }

  async CSVHistoric(): Promise<AxiosResponse> {
    return this.instance.post(`/api/movements/history/export`, { responseType: 'blob' });
  }

  async getTransfer(params: any): Promise<AxiosResponse> {
    return this.instance.get(`api/movements/transfer`, { params } as AxiosRequestConfig);
  }

  async getTransferAdmin(params: any): Promise<AxiosResponse> {
    return this.instance.get(`api/admin/movements/transfer`, { params } as AxiosRequestConfig);
  }

  async CSVTransfer(): Promise<AxiosResponse> {
    return this.instance.post(`/api/movements/transfer/export`, { responseType: 'blob' });
  }

  async getSecondCopy(params: any): Promise<AxiosResponse> {
    return this.instance.get(`api/movements/second-copy`, { params } as AxiosRequestConfig);
  }

  async getUpdateBeneficiary(params: any): Promise<AxiosResponse> {
    return this.instance.get(`api/movements/update-beneficiary`, { params } as AxiosRequestConfig);
  }

  async cancelMoviment(protocol: string, movement_type?: 'second_copy' | 'update_beneficiary'): Promise<AxiosResponse> {
    if (movement_type === 'second_copy') {
      return this.instance.post(`/api/movements/second-copy/${protocol}/cancel`);
    }
    if (movement_type === 'update_beneficiary') {
      return this.instance.post(`/api/movements/update-beneficiary/${protocol}/cancel`);
    }
    return this.instance.post(`/api/movement/${protocol}/cancel`);
  }
}

const trackMoviments = (instance: AxiosInstance): TrackMoviments => new TrackMoviments(instance);

export default trackMoviments;
