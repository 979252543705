import { AxiosInstance, AxiosResponse } from 'axios';
import { IIndicatorCreate } from 'hooks/indicators';

interface IndicatorsResponse {
  content: null | string;
}

export class Indicators {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(data: IIndicatorCreate): Promise<AxiosResponse> {
    return this.instance.post('/api/indicators', data);
  }

  get(description: string): Promise<AxiosResponse<IndicatorsResponse>> {
    return this.instance.get(`/api/indicators`, {
      params: { description },
      handlerEnabled: false,
    });
  }
}

const indicators = (instance: AxiosInstance): Indicators => new Indicators(instance);

export default indicators;
