import { AxiosInstance, AxiosResponse } from 'axios';

export class PreviousCPT {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async createPreviousCPTCid(data: any): Promise<AxiosResponse> {
    return this.instance.post('/api/admin/previous-cpt/cid', data);
  }

  async deletePreviousCPTCid(id: string): Promise<AxiosResponse> {
    return this.instance.delete(`/api/admin/previous-cpt/cid/${id}`);
  }
}

const previousCPT = (instance: AxiosInstance): PreviousCPT => new PreviousCPT(instance);

export default previousCPT;
