import { API_URL } from 'config';

import apiFactory from './api';

const api = apiFactory({
  api_url: API_URL, // 'https://unimed.api.mvdev.org',
  has_user_header: true,
});

export default api;
