import { CSSProperties } from 'react';

import { AnimatePresence } from 'framer-motion';

import Backdrop from '../Backdrop';
import { Container, Content } from './styles';

interface IProps {
  visible: boolean;
  styleContent?: CSSProperties;
}

const ModalDefault: React.FC<IProps> = ({ visible, styleContent, children }) => {
  return (
    <AnimatePresence>
      {visible && (
        <Container initial="visible" animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Backdrop>
            <Content
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ type: 'spring' }}
              style={{
                height: '40',
                ...styleContent,
              }}
            >
              {children}
            </Content>
          </Backdrop>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default ModalDefault;
