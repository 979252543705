import { uniflex_grace_period, uniplan_grace_period } from 'components/pages/BeneficiariesAssessmentViewer/constants';
import { useBeneficiary } from 'hooks/beneficiary';
import { useToast } from 'hooks/toast';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from 'services';
import { IGraceInfo, IReviewGraceInfo, IContext, GraceInfoReviewStatuses } from './interfaces';

const GraceInfoContext = createContext<IContext>({} as IContext);

const GraceInfoProvider: React.FC = ({ children }) => {
  const { beneficiary } = useBeneficiary();
  const { addToast } = useToast();
  const [planType, setPlanType] = useState<string>(beneficiary?.plan_type || '');
  const [graceInfo, setGraceInfo] = useState<IGraceInfo[]>(
    planType === 'uniplan' ? uniplan_grace_period : uniflex_grace_period,
  );
  const [approved, setApproved] = useState<boolean | undefined>(undefined);
  const [review, setReview] = useState<GraceInfoReviewStatuses | undefined>(
    (beneficiary.grace_info_review as GraceInfoReviewStatuses) ?? undefined,
  );
  const [hasPreviousCPT, setHasPreviousCPT] = useState<boolean | null>(beneficiary.has_previous_cpt ?? null);
  const [changedAccommodation, setChangedAccommodation] = useState<boolean | null>(
    beneficiary.changed_accommodation ?? null,
  );
  const [takePreviousUGFPlanGraceInfo, setTakePreviousUGFPlanGraceInfo] = useState<boolean | null>(
    beneficiary.take_previous_plan_grace_info ?? null,
  );
  const [graceLetterStatus, setGraceLetterStatus] = useState<string | null>(beneficiary.grace_letter_status ?? null);
  const [lastExclusionDateStatus, setLastExclusionDateStatus] = useState<string | null>(
    beneficiary.last_exclusion_date_status ?? null,
  );
  const [previousPlanRegulatedByAns, setPreviousPlanRegulatedByAns] = useState<boolean | null>(
    beneficiary.previous_plan_regulated_by_ans ?? null,
  );
  const [previousPlanDuration, setPreviousPlanDuration] = useState<string | null>(
    beneficiary.previous_plan_duration ?? null,
  );

  const [exemptionGraceInfoCoverage, setExemptionGraceInfoCoverage] = useState<boolean | null>();

  const setHasPreviousCPTValue = useCallback((value: boolean): void => {
    setHasPreviousCPT(value);
  }, []);

  const reviewGraceInfo = useCallback(
    async (data: IReviewGraceInfo): Promise<boolean> => {
      try {
        await api.grace_info().reviewGraceInfo(data);
        return true;
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao avaliar o aproveitamento de carências',
          description: `Não foi possível avaliar o aproveitamento de carências: ${String(err)}`,
        });
        return false;
      }
    },
    [addToast],
  );

  const handleSaveGraceInfo = useCallback(
    (data: IGraceInfo): void => {
      const copiedGraceInfo = [...graceInfo];
      const index = copiedGraceInfo.findIndex(info => info.code === data.code);
      copiedGraceInfo[index] = data;
      setGraceInfo(copiedGraceInfo);
    },
    [graceInfo],
  );

  const handleSavePlanType = useCallback(
    (data: string): void => {
      const groups = data === 'uniflex' ? uniflex_grace_period : uniplan_grace_period;
      if (beneficiary.grace_info?.length === 0 || !beneficiary.grace_info) {
        setGraceInfo(groups);
      } else if (beneficiary.grace_info && beneficiary.grace_info.length > 0) {
        let newGraceInfo = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const beneficiaryGraceItem of beneficiary.grace_info) {
          const foundGroup = groups.find(group => group.codes.includes(Number(beneficiaryGraceItem.code)));

          if (foundGroup) {
            newGraceInfo.push({
              ...foundGroup,
              days: beneficiaryGraceItem.days,
            });
          } else {
            const foundByCode = groups.find(group => group.code === beneficiaryGraceItem.code);
            if (foundByCode) {
              newGraceInfo.push({
                ...foundByCode,
                days: beneficiaryGraceItem.days,
              });
            }
          }
        }

        newGraceInfo = newGraceInfo.filter((item, index, self) => index === self.findIndex(t => t.code === item.code));

        setGraceInfo(newGraceInfo.length > 0 ? newGraceInfo : groups);
      }

      setPlanType(data);
    },
    [beneficiary.grace_info],
  );

  const setGraceInfoReview = useCallback(
    (data: GraceInfoReviewStatuses): void => {
      setReview(data);

      if (['com_carencias', 'isento'].includes(data)) {
        setApproved(true);
      } else {
        setApproved(false);
      }

      if (data === 'isento') {
        setGraceInfo(
          planType === 'uniflex'
            ? uniflex_grace_period.map(item => ({
                ...item,
                days: 0,
              }))
            : uniplan_grace_period.map(item => ({
                ...item,
                days: 0,
              })),
        );
      } else if (data === 'negado') {
        setGraceInfo(
          planType === 'uniflex'
            ? uniflex_grace_period.map(item => ({
                ...item,
                days: 0,
              }))
            : uniplan_grace_period.map(item => ({
                ...item,
                days: 0,
              })),
        );
      } else {
        setGraceInfo(
          planType === 'uniflex'
            ? uniflex_grace_period.map(item => ({
                ...item,
                days: undefined,
              }))
            : uniplan_grace_period.map(item => ({
                ...item,
                days: undefined,
              })),
        );
      }
    },
    [planType],
  );

  useEffect(() => {
    let groups = uniflex_grace_period;
    if (beneficiary.special_inclusion) {
      setPlanType('uniflex');
      groups = uniflex_grace_period;
    } else if (beneficiary.plan_type) {
      setPlanType(beneficiary.plan_type);
      groups = beneficiary.plan_type === 'uniflex' ? uniflex_grace_period : uniplan_grace_period;
    }
    if (beneficiary.grace_info && beneficiary.grace_info.length > 0) {
      let newGraceInfo = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const beneficiaryGraceItem of beneficiary.grace_info) {
        const foundGroup = groups.find(group => group.codes.includes(Number(beneficiaryGraceItem.code)));

        if (foundGroup) {
          newGraceInfo.push({
            ...foundGroup,
            days: beneficiaryGraceItem.days,
          });
        } else {
          const foundByCode = groups.find(group => group.code === beneficiaryGraceItem.code);
          if (foundByCode) {
            newGraceInfo.push({
              ...foundByCode,
              days: beneficiaryGraceItem.days,
            });
          }
        }
      }

      newGraceInfo = newGraceInfo.filter((item, index, self) => index === self.findIndex(t => t.code === item.code));

      setGraceInfo(newGraceInfo);
    }

    if (beneficiary.grace_info_review) {
      setReview(beneficiary.grace_info_review as GraceInfoReviewStatuses);
    }
  }, [beneficiary]);

  useEffect(() => {
    if (beneficiary) {
      setHasPreviousCPT(beneficiary.has_previous_cpt ?? null);
      setChangedAccommodation(beneficiary.changed_accommodation ?? null);
      setTakePreviousUGFPlanGraceInfo(beneficiary.take_previous_plan_grace_info ?? null);
      setGraceLetterStatus(beneficiary.grace_letter_status ?? null);
      setLastExclusionDateStatus(beneficiary.last_exclusion_date_status ?? null);
      setPreviousPlanRegulatedByAns(beneficiary.previous_plan_regulated_by_ans ?? null);
      setPreviousPlanDuration(beneficiary.previous_plan_duration ?? null);
    }
  }, [beneficiary]);

  return (
    <GraceInfoContext.Provider
      value={{
        approved,
        review,
        graceInfo,
        hasPreviousCPT,
        planType,
        reviewGraceInfo,
        handleSaveGraceInfo,
        setGraceInfoReview,
        setHasPreviousCPTValue,
        handleSavePlanType,
        takePreviousUGFPlanGraceInfo,
        setTakePreviousUGFPlanGraceInfo,
        graceLetterStatus,
        setGraceLetterStatus,
        lastExclusionDateStatus,
        setLastExclusionDateStatus,
        previousPlanRegulatedByAns,
        setPreviousPlanRegulatedByAns,
        previousPlanDuration,
        setPreviousPlanDuration,
        changedAccommodation,
        setChangedAccommodation,
        exemptionGraceInfoCoverage,
        setExemptionGraceInfoCoverage,
      }}
    >
      {children}
    </GraceInfoContext.Provider>
  );
};

function useGraceInfo(): IContext {
  const context = useContext(GraceInfoContext);
  if (!context) {
    throw new Error('useGraceInfo must be used within a GraceInfoProvider');
  }
  return context;
}

export { GraceInfoProvider, useGraceInfo };
