import { AxiosInstance, AxiosResponse } from 'axios';

export class Assessments {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRegistrationAssessments(params: any): Promise<AxiosResponse> {
    return this.instance.get('/api/assessment/registration', {
      params,
      handlerEnabled: false,
    });
  }

  getHealthStatementAssessments(params: any): Promise<AxiosResponse> {
    return this.instance.get('/api/healthstatement/list', {
      params,
      handlerEnabled: false,
    });
  }

  createDisapproval(beneficiary_type: 'recipient' | 'dependent', beneficiary_id: string, body: any): Promise<any> {
    return this.instance.post(
      `/api/admin/movements/inclusion/${beneficiary_type}/${beneficiary_id}/disapprovals`,
      body,
      {
        handlerEnabled: false,
      },
    );
  }
}

const assessment = (instance: AxiosInstance): Assessments => new Assessments(instance);

export default assessment;
